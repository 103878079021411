// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .bgColor1 {
  background-image: url(../assets/images/bgpic.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
} */

.login-page-disign{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

}

.login-container {
  display: flex;
  justify-content: center !important;
  font-size: 14px;
  padding: 50px 50px 30px 50px;
  max-width: 400px;
  margin: auto;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(235, 235, 235, 1);
}

.loginCard {
  padding: 0% !important;
  margin: auto;
}

.forgot-pass {
  font-size: 12px;
  margin-top: -3%;
  margin-bottom: 3%;
  text-decoration: underline;
}

.forgot-pass:hover {
  color: blue;
  cursor: pointer;
}

.login-btn {
  display: flex;
  justify-content: center;
  background: linear-gradient(275.61deg, #04b96e 2.3%, #10d382 80.65%);
  border-color: #0bd580;
  padding: 8px 10px;
  font-size: 14px;
  font-weight: 600;
  margin: auto;
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/auth/login.css"],"names":[],"mappings":"AAAA;;;;;;;;GAQG;;AAEH;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;;AAEpB;;AAEA;EACE,aAAa;EACb,kCAAkC;EAClC,eAAe;EACf,4BAA4B;EAC5B,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;EACnB,kDAAkD;AACpD;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,0BAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,oEAAoE;EACpE,qBAAqB;EACrB,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":["/* .bgColor1 {\n  background-image: url(../assets/images/bgpic.jpg);\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  min-height: 100vh;\n  display: flex;\n  align-items: center;\n} */\n\n.login-page-disign{\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: start;\n\n}\n\n.login-container {\n  display: flex;\n  justify-content: center !important;\n  font-size: 14px;\n  padding: 50px 50px 30px 50px;\n  max-width: 400px;\n  margin: auto;\n  background: white;\n  border-radius: 10px;\n  box-shadow: 0px 0px 5px 0px rgba(235, 235, 235, 1);\n}\n\n.loginCard {\n  padding: 0% !important;\n  margin: auto;\n}\n\n.forgot-pass {\n  font-size: 12px;\n  margin-top: -3%;\n  margin-bottom: 3%;\n  text-decoration: underline;\n}\n\n.forgot-pass:hover {\n  color: blue;\n  cursor: pointer;\n}\n\n.login-btn {\n  display: flex;\n  justify-content: center;\n  background: linear-gradient(275.61deg, #04b96e 2.3%, #10d382 80.65%);\n  border-color: #0bd580;\n  padding: 8px 10px;\n  font-size: 14px;\n  font-weight: 600;\n  margin: auto;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
