import React, { useEffect, useContext } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { FaQuoteLeft } from "react-icons/fa";
import pic from "../../assets/images/Group 1000002457.png";
import "../../assets/css/home/Testimonials.css";
import { useNavigate } from "react-router-dom";
import { useTestimonialStore } from "../../store";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from '../LanguageContext';
const Testimonials = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { testimonials, fetchTestimonial } = useTestimonialStore();

  const [showTestimonials, setShowTestimonials] = useState("fade-testimonials");
  const [testimonialsHeight, setTestimonialsHeight] =
    useState("row-compressed");

  const handleButtonClick = () => {
    if (showTestimonials === "fade-testimonials") {
      setShowTestimonials("show-testimonials");
    } else {
      setShowTestimonials("fade-testimonials");
    }
    /* showTestimonials === 'fade-testimonials'
    ? setShowTestimonials('show-testimonials')
    :setShowTestimonials('fade-testimonials'); */

    if (testimonialsHeight === "row-compressed") {
      setTestimonialsHeight("row-expanded");
    } else {
      setTestimonialsHeight("row-compressed");
    }
  };

  useEffect(() => {
    async function fetchData() {
      await fetchTestimonial();
    }
    fetchData();
  }, []);

  const languageData = useContext(LanguageContext)
  // console.log("testimonials___", testimonials);

  return (
    <>
      <div className="container " style={{ paddingTop: "50px" }}>
        <h6
          style={{
            color: "#39128C",
            fontFamily: 'Metropolis',
            fontSize: '16px',
            lineHeight: '24px',
            // fontWeight: 700,
            letterSpacing: '5px',
            marginTop: "30px"
          }}
        >
          {t("Testimonials")}
        </h6>
        <div className="mb-3 ">
          <p className=" subtitale">
            {t("Here’s what our client are saying")}
          </p>
        </div>

        <div>
          <Row className={testimonialsHeight}>
            <Col xs={12} md={4}>
              {testimonials[0] && (
                <Card
                  style={{
                    boxShadow: "6px 15px 40px -16px #1B172026",
                    marginBottom: "25px",
                    borderRadius: "16px",
                    border: "1px solid #F4F4F4",
                    background: "linear-gradient(0deg, #FFFFFF, #FFFFFF),linear-gradient(0deg, #F4F4F4, #F4F4F4)",
                  }}
                >
                  <Card.Body>
                    <Row>
                      <Col xs={3} sm={3} md={3}>
                        <div className="mb-3">
                          <img
                            src={pic}
                            alt="pic"
                            style={{
                              width: "70px",
                              height: "70px",
                              borderRadius: "50%",
                              background: "gray",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={7} sm={7}>
                        <h5 className="testimonials-headings ">
                          {testimonials[0]?.name}
                        </h5>
                        <h6 style={{ color: "#09b970" }}>
                          {testimonials[0]?.sub_title}
                        </h6>
                      </Col>
                      <Col xs={2} sm={2}>
                        <h4 style={{ color: "#09b970" }}>
                          <FaQuoteLeft />
                        </h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="testimonials-des">{testimonials[0]?.testinomial}</p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              )}

              {testimonials[1] && (
                <Card
                  style={{
                    boxShadow: "6px 15px 40px -16px #1B172026",
                    marginBottom: "25px",
                    borderRadius: "16px",
                    border: "1px solid #F4F4F4",
                    background: "linear-gradient(0deg, #FFFFFF, #FFFFFF),linear-gradient(0deg, #F4F4F4, #F4F4F4)",
                  }}
                >
                  <Card.Body>
                    <Row>
                      <Col xs={3} sm={3} md={3}>
                        <div className="mb-3">
                          <img
                            src={pic}
                            alt="pic"
                            style={{
                              width: "70px",
                              height: "70px",
                              borderRadius: "50%",
                              background: "gray",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={7} sm={7}>
                        <h5 className="testimonials-headings ">
                          {testimonials[1]?.name}
                        </h5>
                        <h6 style={{ color: "#09b970" }}>
                          {testimonials[1]?.sub_title}
                        </h6>
                      </Col>
                      <Col xs={2} sm={2}>
                        <h4 style={{ color: "#09b970" }}>
                          <FaQuoteLeft />
                        </h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="testimonials-des">{testimonials[1]?.testinomial}</p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              )}
            </Col>
            <Col xs={12} md={4}>
              {testimonials[2] && (
                <Card
                  style={{
                    boxShadow: "6px 15px 40px -16px #1B172026",
                    marginBottom: "25px",
                    borderRadius: "16px",
                    border: "1px solid #F4F4F4",
                    background: "linear-gradient(0deg, #FFFFFF, #FFFFFF),linear-gradient(0deg, #F4F4F4, #F4F4F4)",
                  }}
                >
                  <Card.Body>
                    <Row>
                      <Col xs={3} sm={3} md={3}>
                        <div className="mb-3">
                          <img
                            src={pic}
                            alt="pic"
                            style={{
                              width: "70px",
                              height: "70px",
                              borderRadius: "50%",
                              background: "gray",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={7} sm={7}>
                        <h5 className="testimonials-headings ">
                          {testimonials[2]?.name}
                        </h5>
                        <h6 style={{ color: "#09b970" }}>
                          {testimonials[2]?.sub_title}
                        </h6>
                      </Col>
                      <Col xs={2} sm={2}>
                        <h4 style={{ color: "#09b970" }}>
                          <FaQuoteLeft />
                        </h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="testimonials-des">{testimonials[2]?.testinomial}</p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              )}

              {testimonials[3] && (
                <Card
                  style={{
                    boxShadow: "6px 15px 40px -16px #1B172026",
                    marginBottom: "25px",
                    borderRadius: "16px",
                    border: "1px solid #F4F4F4",
                    background: "linear-gradient(0deg, #FFFFFF, #FFFFFF),linear-gradient(0deg, #F4F4F4, #F4F4F4)",
                  }}
                >
                  <Card.Body>
                    <Row>
                      <Col xs={3} sm={3} md={3}>
                        <div className="mb-3">
                          <img
                            src={pic}
                            alt="pic"
                            style={{
                              width: "70px",
                              height: "70px",
                              borderRadius: "50%",
                              background: "gray",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={7} sm={7}>
                        <h5 className="testimonials-headings ">
                          {testimonials[3]?.name}
                        </h5>
                        <h6 style={{ color: "#09b970" }}>
                          {testimonials[3]?.sub_title}
                        </h6>
                      </Col>
                      <Col xs={2} sm={2}>
                        <h4 style={{ color: "#09b970" }}>
                          <FaQuoteLeft />
                        </h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="testimonials-des">{testimonials[3]?.testinomial}</p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              )}
            </Col>
            <Col xs={12} md={4}>
              {testimonials[4] && (
                <Card
                  style={{
                    boxShadow: "6px 15px 40px -16px #1B172026",
                    marginBottom: "25px",
                    borderRadius: "16px",
                    border: "1px solid #F4F4F4",
                    background: "linear-gradient(0deg, #FFFFFF, #FFFFFF),linear-gradient(0deg, #F4F4F4, #F4F4F4)",
                  }}
                >
                  <Card.Body>
                    <Row>
                      <Col xs={3} sm={3} md={3}>
                        <div className="mb-3">
                          <img
                            src={pic}
                            alt="pic"
                            style={{
                              width: "70px",
                              height: "70px",
                              borderRadius: "50%",
                              background: "gray",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={7} sm={7} md={7}>
                        <h5 className="testimonials-headings ">
                          {testimonials[4]?.name}
                        </h5>
                        <h6 style={{ color: "#09b970" }}>
                          {testimonials[4]?.sub_title}
                        </h6>
                      </Col>
                      <Col xs={2} sm={2} md={2}>
                        <h4 style={{ color: "#09b970" }}>
                          <FaQuoteLeft />
                        </h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="testimonials-des">{testimonials[4]?.testinomial}</p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              )}

              {testimonials[5] && (
                <Card
                  style={{
                    boxShadow: "6px 15px 40px -16px #1B172026",
                    marginBottom: "25px",
                    borderRadius: "16px",
                    border: "1px solid #F4F4F4",
                    background: "linear-gradient(0deg, #FFFFFF, #FFFFFF),linear-gradient(0deg, #F4F4F4, #F4F4F4)",
                  }}
                >
                  <Card.Body>
                    <Row>
                      <Col xs={3} sm={3} md={3}>
                        <div className="mb-3">
                          <img
                            src={pic}
                            alt="pic"
                            style={{
                              width: "70px",
                              height: "70px",
                              borderRadius: "50%",
                              background: "gray",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={7} sm={7} md={7}>
                        <h5 className="testimonials-headings ">
                          {testimonials[5]?.name}
                        </h5>
                        <h6 style={{ color: "#09b970" }}>
                          {testimonials[5]?.sub_title}
                        </h6>
                      </Col>
                      <Col xs={2} sm={2} md={2}>
                        <h4 style={{ color: "#09b970" }}>
                          <FaQuoteLeft />
                        </h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="testimonials-des">{testimonials[5]?.testinomial}</p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              )}
            </Col>
            <div className={showTestimonials}></div>
          </Row>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3 mb-5">
          <button
            className=""
            style={{
              background: "linear-gradient(275.61deg, #04B96E 2.3%, #10D382 80.65%)",
              boxShadow: "0px 15px 30px -10px #07BE7226",
              height: "64px",
              padding: "10px 20px",
              fontSize: "16px",
              // fontWeight: "700",
              color: "white",
              borderRadius: "16px",
              border: "none",
              marginTop: "20px",
              fontFamily: "Metropolis",
              lineHeight: "14px",
              letterSpacing: "0em",
              textAlign: "center"

            }}
            onClick={handleButtonClick}
          >
            {testimonialsHeight === "row-compressed" ? t("SHOW") : t("HIDE")}
            {t("   ALL TESTIMONIALS")}
          </button>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
