import React, { useEffect, useState, useContext } from "react";
import "../../assets/css/home/Category.css";
import firstImg from "../../assets/images/students-walk-downstairsdd-with-books-library 1 1.png";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Card, Row, Col, Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useCategoryStore } from "../../store";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../LanguageContext";
const Category = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const languageData = useContext(LanguageContext);
  /*   const handleMouseEnter = (e) => {
    e.target.style.opacity = '1';
  };

  const handleMouseLeave = (e) => {
    e.target.style.opacity = '0';
  }; */
  /* 
  const [showOverlay, setShowOverlay] = useState(new Array(currentItems.length).fill(false)); // State variable to control overlay visibility

  const handleMouseEnter = (index) => {
    const updatedOverlay=[...showOverlay];
    updatedOverlay[index] = true;
    setShowOverlay(updatedOverlay);
  };

  const handleMouseLeave = (index) => {
    const updatedOverlay = [...showOverlay];
    updatedOverlay[index] = true;
    setShowOverlay(updatedOverlay);
  };
 */
  const { category, fetchCategory } = useCategoryStore();
  useEffect(() => {
    async function fetchData() {
      await fetchCategory();
    }
    fetchData();
  }, []);

  const itemsPerPage = 5;
  const totalItems = category.length; // Replace with your actual total number of courses
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [currentPage, setCurrentPage] = React.useState(1);

  // Generate dummy course data
  const course = Array.from({ length: totalItems }, (_, index) => ({
    id: index + 1,
    title: `Course ${index + 1}`,
    image: `https://example.com/course${index + 1}.jpg`, // Replace with your course image URL
  }));

  // Pagination helper functions
  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const getNextPage = (currentPage, totalPages) => {
    return currentPage === totalPages ? 1 : currentPage + 1;
  };

  const getPrevPage = (currentPage, totalPages) => {
    return currentPage === 1 ? totalPages : currentPage - 1;
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => getNextPage(prevPage, totalPages));
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => getPrevPage(prevPage, totalPages));
  };

  const getCurrentPageItems = () => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    if (end > totalItems) {
      // Wrap around if reaching the end
      return [...category.slice(start), ...category.slice(0, end - totalItems)];
    }
    return category.slice(start, end);
  };

  /* const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  }; */

  // Calculate current page items
  const categorys = category?.map((item) => item.course_category);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    getCurrentPageItems(); /* category.slice(indexOfFirstItem, indexOfLastItem); */
  // console.log('CategoryData_______', currentItems);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleButtonClick = (category) => {
    navigate(`/course-list/`, {
      state: {
        category_id: category,
      },
    });
    const url = new URL(window.location.href);
    url.searchParams.set("categories", category);
    window.history.pushState({}, "", url.toString());
    window.scrollTo(0, 0);
  };

  const handleButtonClick2 = () => {
    navigate(`/course-list/`);
    // window.location.reload();
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <Container className="category-container categorybox">
        <h6
          style={{
            fontFamily: "Metropolis",
            fontSize: "16px",
            // fontWeight: "700",
            lineHeight: "24px",
            letterSpacing: "5px",
            textAlign: "left",
            color: "#39128C",
            width:"100%"
          }}
        >
          {t("Top Categories")}
        </h6>
        <p
          style={{
            color: "black",
            fontFamily: "Metropolis",
            fontSize: "32px",
            fontStyle: "normal",
            // fontWeight: 700,
            letterSpacing: "5px",
            paddingBottom: "10px",
            lineHeight: "48px",
            letterSpacing: "0px",
            textAlign: "left",
          }}
        >
          {t("Browse by popular Courses Categories")}
        </p>
        <div className="mt-5">
          <Row>
            {currentItems?.map((course, index) => (
              <Col key={index} xs={6} md={4} lg={2} className="mb-3 cat-col">
                <div
                  className="card card-shadow course-cart-res"
                  style={{
                    borderRadius: "24px",
                    // height: '100%',
                    // width: "214px",
                    height: "248px",
                    boxShadow: "0px 20px 40px -17px rgba(27, 23, 32, 0.2)",
                    overflow: "hidden",
                  }}
                  /* onMouseEnter={()=>handleMouseEnter(index)}
                  onMouseLeave={()=>handleMouseLeave(index)} */
                  onClick={() => handleButtonClick(course.id)}
                >
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={course?.image}
                      className="card-img-top"
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "250px",
                      }}
                    />
                    <div
                      className="card-img-overlay d-flex flex-column justify-content-end "
                      style={{
                        // background: 'rgba(0, 0, 0, 0.7)',
                        padding: "10px",
                        bottom: "0",
                        left: "0",
                        right: "0",
                        position: "absolute",
                        /* opacity: showOverlay ? '1' : '0', */
                        transition: "opacity 0.3s",
                      }}
                    >
                      <h5
                        className="card-title text-center"
                        style={{
                          fontFamily: "Metropolis",
                          fontSize: "16px",
                          fontStyle: "normal",
                          color: "white",
                          // fontWeight: "700",
                          zIndex: "99999",
                        }}
                      >
                        {languageData?.currentLanguage === "en" &&
                          course?.lang_en}
                        {languageData?.currentLanguage === "pl" &&
                          course?.lang_pl}
                        {languageData?.currentLanguage === "ro" &&
                          course?.lang_roa}
                      </h5>
                    </div>
                    <div
                      className="card-img-overlay d-flex flex-column justify-content-center card-overlay card-overlay-hover"
                      style={{
                        padding: "10px",
                        backgroundColor: "rgba(4, 185, 110, 0.8)",
                        /* opacity: showOverlay ? '1' : '0', */
                        transition: "opacity 0.3s",
                        borderRadius: "20px",
                      }}
                    >
                      <p
                        className="card-title text-white text-center font-weight-bold"
                        style={{
                          fontFamily: "Metropolis",
                          fontSize: "20px",
                          // fontWeight: "700",
                          lineHeight: "28px",
                          letterSpacing: "0px",
                          textAlign: "center",
                          color: "#FFFFFF",
                        }}
                      >
                        CHOOSE THE COURSE
                        {/* {course.lang_roa} */}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <button
              className="cat-btn "
              style={{
                border: "1px solid #04C977",
                borderColor: "#04C977",
                borderRadius: "12px",
                backgroundColor: "transparent",
                padding: "15px 20px",
                width: "223px",
                height: "64px",
                fontFamily: "Metropolis",
                fontSize: "16px",
                // fontWeight: "700",
                lineHeight: "14px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
              onClick={handleButtonClick2}
            >
              {t("SHOW ALL COURSES")}
            </button>
            <div className="frame">
              <div className="Ellipse"></div>
            </div>
            <nav className="d-flex justify-content-center">
              <ul className="pagination">
                <li
                  className="page-item" /* {`page-item ${currentPage === 1 ? 'disabled' : ''}`} */
                >
                  <button
                    className="next-btn"
                    onClick={goToPrevPage}
                    /* disabled={currentPage === 1} */
                  >
                    <FaChevronLeft />
                  </button>
                </li>

                <li
                  className="page-item" /* {`page-item ${
                    currentPage === totalPages ? 'disabled' : ''
                  }`} */
                >
                  <button
                    className="next-btn"
                    onClick={goToNextPage}
                    /* disabled={currentPage === totalPages} */
                  >
                    <FaChevronRight />
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Category;
