import React, { useEffect } from 'react';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../assets/css/about.css";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import about1 from "../assets/images/about-11.webp";
import about2 from "../assets/images/about-12.webp";
import about3 from "../assets/images/shape-13.png";
import about4 from "../assets/images/shape-21.png";
import about5 from "../assets/images/shape-40.png";
import mission from "../assets/images/about-14.webp";
import vision from "../assets/images/blog-02.jpg";
import whyicon1 from "../assets/images/online-learning.png";
import whyicon2 from "../assets/images/web-security.png";
import whyicon3 from "../assets/images/instructor.png";
import { Link } from "react-router-dom";
const Partners = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0)
  })
  return (
    <>
      <Navbar></Navbar>
      <div className="about-top">
        <Container style={{ height: "100%" }}>
          <div className="about-top-text">
            <h1>{t("Partner With Skillmo")}</h1>
          </div>
        </Container>
      </div>

      <div className="about-body">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={6}>
              <div className="about-us-text" style={{ marginTop: "50px" }}>
                <p style={{ fontSize: "20px", fontWeight: "600" }}>
                  {t("Partner With Us")}
                </p>
                <h2>{t("Become a ")}</h2>
                <h2 style={{ color: "#04c977", lineHeight: "27px" }}>
                  {t("Skillmo Partner")}
                </h2>
                <p className="about-p">
                  {t(
                    "At Skillmo, we believe that collaboration is the key to unlocking even greater opportunities for education and skill development. We are open to partnering with like-minded organizations, institutions, and individuals who share our passion for learning and our commitment to making education accessible to all."
                  )}
                </p>
              </div>
            </Col>

            <Col xs={12} sm={12} md={6}>
              <div className="about-img">
                <img className="img1" src={mission} alt="pic" style={{}} />
                <img className="img2" src={about3} alt="pic" style={{}} />
                {/* <img className='img3' src={about2} alt="pic" style={{}} /> */}
                <img className="img4" src={about4} alt="pic" style={{}} />
                <img className="img5" src={about5} alt="pic" style={{}} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="about-why-choose">
        <Container>
          <div className="why-tittle">
            <p style={{ fontSize: "20px", fontWeight: "600" }}>
              {t("Why Us ?")}
            </p>
            <h2>{t("Why Partner with")}</h2>
            <h2 style={{ color: "#04c977", lineHeight: "27px" }}>
              {t("Skillmo ?")}
            </h2>
          </div>

          <div className="why-section">
            <Row>
              <Col xs={12} sm={12} md={4}>
                <div className="why-box">
                  <div className="why-icon">
                    <img
                      className="why-icon-img"
                      src={whyicon1}
                      alt="pic"
                      style={{}}
                    />
                  </div>
                  <h3>{t("Broaden Your Reach")}</h3>
                  <p>
                    {t(
                      "Partnering with Skillmo allows you to tap into our extensive network of learners from diverse backgrounds and industries. Expand your audience and share your expertise with a global community."
                    )}
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="why-box">
                  <div className="why-icon">
                    <img
                      className="why-icon-img"
                      src={whyicon2}
                      alt="pic"
                      style={{}}
                    />
                  </div>
                  <h3>{t("Enhance Your Content")}</h3>
                  <p>
                    {t(
                      "Collaborate with us to develop and deliver high-quality courses that meet the needs and expectations of today's learners. Benefit from our expertise in online education and course design."
                    )}
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="why-box">
                  <div className="why-icon">
                    <img
                      className="why-icon-img"
                      src={whyicon3}
                      alt="pic"
                      style={{}}
                    />
                  </div>
                  <h3>{t("Access to Resources")}</h3>
                  <p>
                    {t(
                      "Gain access to our state-of-the-art e-learning platform, cutting-edge technology, and a team of experienced professionals dedicated to helping you succeed."
                    )}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <div className="mission">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={6}>
              <div className="mission-text">
                <p style={{ fontSize: "20px", fontWeight: "600" }}>
                  {t("Types of Partnerships")}
                </p>
                <h2>{t("Types of Partnerships")}</h2>
                <p className="partner-p">
                  <span>{t("Course Content Partnerships:")}</span>{" "}
                  {
                    "Co-create and publish courses on Skillmo's platform, reaching a wider audience and sharing knowledge in your area of expertise."
                  }
                </p>
                <p className="partner-p">
                  <span>{t("Institutional Partnerships:")}</span>{" "}
                  {t(
                    "Collaborate with Skillmo to offer customized learning solutions for your institution or organization. Whether you're a school, university, or corporation, we can tailor our offerings to meet your specific needs."
                  )}
                </p>
                <p className="partner-p">
                  <span>{t("Promotional Partnerships:")}</span>{" "}
                  {t(
                    "Join forces for co-marketing and promotional campaigns to maximize visibility and impact."
                  )}
                </p>
                <p className="partner-p">
                  <span>{t("Affiliate Partnerships:")}</span>{" "}
                  {t(
                    "Earn commissions by referring students to Skillmo's courses through our affiliate program"
                  )}
                </p>
              </div>
            </Col>

            <Col xs={12} sm={12} md={6}>
              <div className="mission-img">
                <img className="m-img" src={mission} alt="pic" style={{}} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="get-started-botton">
        <Container>
          <div className="get-main">
            <h2>{t("Get Your Quality Skills Certificate")}</h2>
            <h2 style={{ color: "#04c977", lineHeight: "27px" }}>
              {t("Through skillmo")}
            </h2>
          </div>
          <Link to="/register">
            <button
              style={{ marginTop: "30px" }}
              className="btn rounded reg-bttn"
            >
              {t("Get started now ")}
            </button>
          </Link>
        </Container>
      </div>

      <Footer></Footer>
    </>
  );
};

export default Partners;
