// src/store/index.js
import useUserStore from './userStore'; // Import additional stores as needed
import useCourseStore from './courseStore';
import useCategoryStore from './categoryStore';
import useLocationStore from './locationStore';
import useCourseByLocationStore from './courseByLocationStore';
import useTestimonialStore from './testimonialStore';
import useTradesStore from './tradesStore';
import useAccreditationsStore from './accreditationsStore';
import useFilterDataStore from './filterDataStore';
import useCompanyStore from './companyStore';
import useLocalStore from './localStore';
import useCartStore from './cartStore';
import useCartStoreApi from './cart';
import useTokenStore from './tokenStore';
export {
  useUserStore,
  useCourseStore,
  useCategoryStore,
  useLocationStore,
  useCourseByLocationStore,
  useTestimonialStore,
  useTradesStore,
  useAccreditationsStore,
  useFilterDataStore,
  useCompanyStore,
  useLocalStore,
  useCartStore,
  useCartStoreApi,
  useTokenStore
}; // Export all the stores
