// PaymentStatusPage.js

// import React from 'react';
// import { Container, Typography, Box, Button } from '@mui/material';
// import { Link } from 'react-router-dom';

// const PaymentStatusPage = ({ isSuccess }) => {
//   return (
//     <Container
//       maxWidth="sm"
//       style={{
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         justifyContent: 'center',
//         minHeight: '100vh',
//       }}
//     >
//       {isSuccess ? (
//         <>
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             viewBox="0 0 24 24"
//             width="100"
//             height="100"
//             fill="#4caf50"
//             style={{ marginBottom: '16px' }}
//           >
//             <path d="M0 0h24v24H0z" fill="none" />
//             <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
//           </svg>
//           <Typography variant="h4" align="center" gutterBottom>
//             Payment Successful
//           </Typography>
//           <Typography variant="body1" align="center">
//             Thank you for your payment. Your transaction was successful.
//           </Typography>
//         </>
//       ) : (
//         <>
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             viewBox="0 0 24 24"
//             width="100"
//             height="100"
//             fill="#f44336"
//             style={{ marginBottom: '16px' }}
//           >
//             <path d="M0 0h24v24H0z" fill="none" />
//             <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
//           </svg>
//           <Typography variant="h4" align="center" gutterBottom>
//             Payment Failed
//           </Typography>
//           <Typography variant="body1" align="center">
//             We're sorry, but there was an error processing your payment. Please try again later.
//           </Typography>
//         </>
//       )}
//       <Box mt={4}>
//         <Button component={Link} to="/" variant="contained" color="primary">
//           Back to Home
//         </Button>
//       </Box>
//     </Container>
//   );
// };

// export default PaymentStatusPage;



import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import BlockIcon from '@mui/icons-material/Block';

const PaymentStatusPage = ({ status }) => {
    let icon, message, color;
    switch (status) {
        case 'paid':
            icon =
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="100"
                    height="100"
                    fill="#4caf50"
                    style={{ marginBottom: '16px' }}
                >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
                </svg>;
            message = 'Payment Successful';
            color = '#4caf50';
            break;
        case 'fail':
            icon = <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="100"
                height="100"
                fill="#f44336"
                style={{ marginBottom: '16px' }}
            >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
            </svg>;
            message = 'Payment Failed';
            //   color = '#f44336';
            break;
        case 'unpaid':
            icon = <ReportProblemIcon sx={{ fontSize: 200 }} />;
            message = 'Payment Return Data Accepted';
            color = '#2196f3';
            break;
        case 'abort':
            icon = <BlockIcon sx={{ fontSize: 200 }} />;
            message = 'Payment Aborted';
            color = '#ff9800';
            break;
        default:
            icon = null;
            message = 'Payment Status Unknown';
            color = '#000';
    }

    return (
        <Container
            maxWidth="sm"
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
            }}
        >
            {icon && React.cloneElement(icon, { sx: { fill: color, marginBottom: '16px' } })}
            <Typography variant="h4" align="center" gutterBottom style={{ color }}>
                {message}
            </Typography>
            <Typography variant="body1" align="center">
                {status === 'success'
                    ? 'Thank you for your payment. Your transaction was successful.'
                    : status === 'fail'
                        ? "We're sorry, but there was an error processing your payment. Please try again later."
                        : ''}
            </Typography>
            <Box mt={4}>
                <Button component={Link} to="/" variant="contained" color="primary">
                    Back to Home
                </Button>
            </Box>
        </Container>
    );
};

export default PaymentStatusPage;


