// src/store/userStore.js
import { create } from 'zustand';
import {  fetchCompany } from '../api/companyApi';

const useCompanyStore = create((set) => ({
  companys:[],
  fetchCompany: async () => {
    try {
      const company = await fetchCompany();
      set({ company });
    } catch (error) {
      console.error('Error fetching company:', error);
    }
  },
}));

export default useCompanyStore;