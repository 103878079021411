// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Vollkorn:ital,wght@0,400..900;1,400..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-py-heading {
    font-size: 18px;
    color: #29292B;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    padding: 0px;
    margin-bottom: 5px;
    margin-top: 10px;
}

.text-pyment {
    font-size: 16px;
    color: #29292B;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    padding: 0px;
    margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/home/FlexiblePayment.css"],"names":[],"mappings":"AAGA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,qCAAqC;IACrC,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,qCAAqC;IACrC,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Vollkorn:ital,wght@0,400..900;1,400..900&display=swap');\r\n\r\n\r\n.sub-py-heading {\r\n    font-size: 18px;\r\n    color: #29292B;\r\n    font-weight: 600;\r\n    font-family: \"Montserrat\", sans-serif;\r\n    padding: 0px;\r\n    margin-bottom: 5px;\r\n    margin-top: 10px;\r\n}\r\n\r\n.text-pyment {\r\n    font-size: 16px;\r\n    color: #29292B;\r\n    font-weight: 500;\r\n    font-family: \"Montserrat\", sans-serif;\r\n    padding: 0px;\r\n    margin-bottom: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
