import { baseUrl } from "../config";


export const fetchUsers = async (bearerToken) => {
  try {
    const response = await fetch(
      `${baseUrl}/user/me`,
      {
        headers: {
          Authorization: `Bearer ${"eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InVzZXJfYjg1MjFlN2VjM2ViIiwidXNlcl9pZCI6InVzZXJfYjg1MjFlN2VjM2ViIiwibG9naW5UaW1lIjoxNjk1MTgxOTM1MTUzLCJnZW5lcmF0ZWRfYXQiOjE2OTUxODE5MzUxNTMsImlhdCI6MTY5NTE4MTkzNSwiZXhwIjoxNjk1MTg1NTM1LCJpc3MiOiJSZWNydWl0In0.FB3tz75aqW9xvy4LF5FkbUQx_K9bBNLH6KNsPQOqRrHl9izBU_bwY8Jhz1B_-StmRzdYVqdRAJEu8zctE1cxVA"}`,
        },
      }
    );
    if (!response.ok) {
      // Handle non-200 status codes if needed
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error; // Rethrow the error to propagate it up the call stack
  }
};

export const createUser = async (postData) => {
  const response = await fetch(
    `${baseUrl}/user/register`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    }
  );
  const data = await response.json();
  return data;
};

export const loginUser = async (postData) => {
  const response = await fetch(
    `${baseUrl}/user/login`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true"
      },
      withCredentials: true,
      body: JSON.stringify(postData),
    }
  );
  const data = await response.json();
  return data;
};

export const forgotPassword = async (postData) => {
  const response = await fetch(
    `${baseUrl}/user/forgot_password`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    }
  );
  const data = await response.json();
  return data;
};
export const resetPassword = async (postData) => {
  const response = await fetch(
    `${baseUrl}/user/reset_password`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    }
  );
  const data = await response.json();
  return data;
};
export const newPassword = async (postData) => {
  const response = await fetch(
    `${baseUrl}/user/resetPassword/:token`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    }
  );
  const data = await response.json();
  return data;
};

export const courseRegister = async (postData, authToken) => {
  try {
    const response = await fetch(
      `${baseUrl}/course/user`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`, 
        },
        body: JSON.stringify(postData),
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error Registering Course to User:", error);
    throw error; 
  }
};

export const userDataUpdate = async (id, postData)=>{
  const response = await fetch(
    `${baseUrl}/user/account/${id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    }
  );
  const data = await response.json();
  return data;
}


