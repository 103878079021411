// src/store/accreditationsStore.js
import { create } from 'zustand';
import {  fetchAccreditations } from '../api/accreditationsApi';

const useAccreditationsStore = create((set) => ({
  category:[],
  fetchAccreditations: async () => {
    try {
      const accredits = await fetchAccreditations();
      set({ accredits });
    } catch (error) {
      console.error('Error fetching accreditations:', error);
    }
  },
}));

export default useAccreditationsStore;
