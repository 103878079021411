import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LanguageContext } from "./LanguageContext";
import useLocalStorage from "../hooks/LocalStotrage";
import PageNotFound from "./PageNotFound"; // Import the PageNotFound component

const LanguageSwitcher = () => {
  const navigate = useNavigate();
  const { changeLanguage } = useContext(LanguageContext);
  const location = useLocation();

  const [language, setLanguage] = useLocalStorage("language", "en");

  const supportedLanguages = ["en", "ro", "pl"]; // Define supported languages

  const handleLanguageChange = (newLanguage) => {
    if (supportedLanguages.includes(newLanguage)) {
      setLanguage(newLanguage);
      changeLanguage(newLanguage);

      // Update the URL with a query parameter for the language
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("lang", newLanguage);
      navigate(location.pathname + "?" + searchParams.toString());
    } else {
      // Handle unsupported languages
      console.log("Unsupported language");
    }
  };

  // Extract the language from the query parameters on initial load
  const queryParams = new URLSearchParams(location.search);
  const langParam = queryParams.get("lang");
  if (langParam && langParam !== language) {
    if (supportedLanguages.includes(langParam)) {
      setLanguage(langParam);
      changeLanguage(langParam);
    } else {
      // Handle unsupported languages
      console.log("Unsupported language");
    }
  }


  return (
    <div>
      <ul className="dropdown-menu nav-lang-menu cursor-pointer">
        <li>
          <a
            className="dropdown-item"
            onClick={() => handleLanguageChange("en")}
          >
            English
          </a>
        </li>
        <li>
          <a
            className="dropdown-item"
            onClick={() => handleLanguageChange("ro")}
          >
            Romanian
          </a>
        </li>
        <li>
          <a
            className="dropdown-item"
            onClick={() => handleLanguageChange("pl")}
          >
            Polish
          </a>
        </li>
      </ul>
    </div>
  );
};

export default LanguageSwitcher;
