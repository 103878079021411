import { baseUrl } from "../config";

export const fetchCourse = async () => {
  const response = await fetch(
    `${baseUrl}/course`
  );
  const data = await response.json();
  return data.data.data;
};
export const fetchCategory = async () => {
  const response = await fetch(
    `${baseUrl}/master/course_category`
  );
  const data = await response.json();
  return data.data.data;
};

export const fetchCourseById = async (id) => {
  const response = await fetch(
    `${baseUrl}/course/${id}`
  );
  const data = await response.json();
  return data.data.data;
};