import Cookies from 'js-cookie';
export const fetchTokenStorage = async () => {
  const nameCookieValue = Cookies.get('UUID');
  // if (nameCookieValue) {
  //   console.log('Value of NAME cookie:', nameCookieValue);
  // } else {
  //   console.log('NAME cookie not found.');
  // }
    return nameCookieValue;
  };
  