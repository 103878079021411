// LanguageContext.js
import React, { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [currentLanguage, setLanguage] = useState('en');
  const { i18n } = useTranslation();

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ currentLanguage, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageProvider, LanguageContext };
