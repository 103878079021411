import React, { useState, useEffect } from 'react';
import Header from '../components/home/Header';
import Category from '../components/home/Category';
import Courses from '../components/home/Courses';
import Platform from '../components/home/Platform';
import Testimonials from '../components/home/Testimonials';
import Footer from '../components/Footer';
import { Navbar } from '../components/Navbar';
import { GlobalStyle } from '../assets/customcss/CustomCss';
import firstImg from '../assets/images/students-walk-downstairsdd-with-books-library 1 1.png';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import WhyUs from '../components/home/WhyUs';
import FlexiblePayments from '../components/home/FlexiblePayment';
import FAQs from '../components/home/FAQs';
import AboutUsStrip from '../components/home/AboutUsStrip';
import Register from '../components/home/Register';
import '../assets/css/home/Home.css';
import Payl8rCalculator from '../components/Payl8rCalculator';
const Home = () => {


  useEffect(() => {
    window.scrollTo(0, 0)
  })
  return (
    <>
      <GlobalStyle />
      <div style={{ overflow: 'hidden' }}>
        <Navbar ></Navbar>
        <div>
          <Header></Header>
          {/* {products.map(product => (
        <Payl8rCalculator key={product.id} initialPrice={product.price} calculatorId={`calculator${product.id}`} />
      ))} */}
          {/* <Payl8rCalculator price={200}/>
      <Payl8rCalculator price={400}/>
      <Payl8rCalculator price={600}/> */}
          {/* <Category></Category> */}
          <div
            style={{ background: 'rgba(246, 245, 251, 1)', marginTop: '0px', }}
          >
            <WhyUs></WhyUs>
            <FlexiblePayments></FlexiblePayments>
            <FAQs></FAQs>
            {/* <Courses></Courses> */}
          </div>
          {/* <AboutUsStrip></AboutUsStrip> */}
          {/* <Platform></Platform> */}
          {/* <Testimonials></Testimonials> */}
          <Register></Register>
        </div>
        {/* <AboutUsStrip></AboutUsStrip>
        <Platform></Platform>
        <Testimonials></Testimonials>
        <Register></Register> */}
      </div>
      <Footer></Footer>
    </>
  );
};

export default Home;
