// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Main wrapper
 */
 body {
    -webkit-font-smoothing: antialiased; /* for Chrome */
    -moz-osx-font-smoothing: grayscale; /* for Firefox */
}

.sub-text {
  color: #777779;
}

`, "",{"version":3,"sources":["webpack://./src/assets/css/home/Home.css"],"names":[],"mappings":"AAEA;;EAEE;CACD;IACG,mCAAmC,EAAE,eAAe;IACpD,kCAAkC,EAAE,gBAAgB;AACxD;;AAEA;EACE,cAAc;AAChB","sourcesContent":["@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css');\n\n/**\n * Main wrapper\n */\n body {\n    -webkit-font-smoothing: antialiased; /* for Chrome */\n    -moz-osx-font-smoothing: grayscale; /* for Firefox */\n}\n\n.sub-text {\n  color: #777779;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
