import React, { useState, useEffect, useContext } from "react";
import "../assets/css/Navbar.css";
import Image from "../assets/images/Layer 1 (1).png";
import {
  FaAngleDown,
  FaAngleRight,
  FaFlag,
  FaHeart,
  FaShoppingCart,
  FaUser,
} from "react-icons/fa";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  useCourseStore,
  useCategoryStore,
  useTradesStore,
  useAccreditationsStore,
} from "../store/index.js";
import LanguageSwitcher from "./LanguageSwitcher";
import { LanguageContext } from "./LanguageContext";
import { useTranslation } from "react-i18next";
import { useLocalStore, useCartStore } from "../store";
import Cookies from "js-cookie";
import Cart from "./courselist/Cart";
import { useLocation } from "react-router-dom";
import ukFlag from "../assets/images/united-kingdom-flag-icon.png";
import roFlag from "../assets/images/romania-flag-icon.png";
import plFlag from "../assets/images/poland-flag-icon.png";

export const Navbar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentLanguage } = useContext(LanguageContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dynamicLanguage = queryParams.get("language");
  // console.log("currentLanguage", currentLanguage);
  const { items } = useCartStore();
  const loginPage = () => {
    navigate("/login");
    // window.location.reload();
  };
  const loginPage1 = () => {
    navigate("/login");
    // window.location.reload();
  };
  const loginPage2 = () => {
    navigate("/login");
    // window.location.reload();
  };
  const registerPage = () => {
    navigate("/register");
    // window.location.reload();
  };
  const courseListPage = (id) => {
    navigate(`/course-list/`, {
      state: {
        subTrads_id: id,
      },
    });
    window.scrollTo(0, 0);
  };
  const courseListPage2 = (id) => {
    localStorage.setItem("accredits_id", id);
    navigate(`/course-list/`, {
      state: {
        accredits_id: id,
      },
    });
    const url = new URL(window.location.href);
    url.searchParams.set("accredition", id);
    window.history.pushState({}, "", url.toString());
    window.scrollTo(0, 0);
  };
  const categoryList = (id) => {
    localStorage.setItem("category_id", id);
    navigate(`/course-list/`, {
      state: {
        category_id: id,
      },
    });
    const url = new URL(window.location.href);
    url.searchParams.set("categories", id);
    window.history.pushState({}, "", url.toString());
    window.scrollTo(0, 0);
  };
  const studentHome = () => {
    navigate("/");
  };
  const aboutPage = () => {
    navigate(`/about/`);
  };
  const partnersPage = () => {
    navigate(`/partners/`);
  };

  // console.log('use', userData);
  const [isNavOpen, setIsNavOpen] = useState(false);
  /* const [language1, setLanguage] = useState("en"); */
  const [userData, setUserData] = useState(true);
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  const { courses, fetchCourses } = useCourseStore();
  const { category, fetchCategory } = useCategoryStore();
  const { trades, fetchTrades } = useTradesStore();
  const { accredits, fetchAccreditations } = useAccreditationsStore();
  const { storeData, fetchLocalStorage } = useLocalStore();
  useEffect(() => {
    async function fetchCoursesData() {
      await fetchCourses();
      await fetchCategory();
      await fetchTrades();
      await fetchAccreditations();
      await fetchLocalStorage();
    }
    fetchCoursesData();
  }, []);

  const clearLocalStorage = async () => {
    Cookies.remove("NAME", { domain: ".skillmo.co.uk" });
    Cookies.remove("ACCOUNT", { domain: ".skillmo.co.uk" });
    Cookies.remove("TOKEN", { domain: ".skillmo.co.uk" });
    Cookies.remove("UUID", { domain: ".skillmo.co.uk" });
    await fetchLocalStorage();
  };
  const userType = Cookies.get("ACCOUNT");

  const goToDAsboard = () => {
    if (userType === "user") {
      window.location.href =
        "https://test-admin.skillmo.co.uk/home/user-accounts";
    } else {
      window.location.href =
        "https://test-admin.skillmo.co.uk/home/course-users";
    }
  };

  const capitalizeFirstLetter = (text) => {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={Image} alt="logo" className="img-logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNav}
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="menu-icons-mobile">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="none"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 12h16M4 6h16M4 18h16"
                />
              </svg>
            </span>
          </button>
          <div
            className={`collapse_menu main-menu navbar-collapse ${
              isNavOpen ? "show" : ""
            }`}
          >
            <ul className="navbar-nav">
              <li class="nav-item dropdown">
                <a
                  class="nav-link active"
                  // id="navbarDropdown"
                  role="button"
                  // data-bs-toggle="dropdown"
                  // aria-expanded="false"
                  cursor="pointer"
                >
                  {/* <b>{t("courses")}</b> */}
                  {/* <FaAngleDown className="dropdown" /> */}
                </a>
                {/* <ul class="dropdown-menu ">
                  {courses?.map((course, index) => (
                    <li
                      className="cursor-pointer"
                      key={index}
                      value={course.id}
                      onClick={() => {
                        navigate("/course-details", {
                          state: {
                            coursesData: course,
                          },
                        });
                      }}
                    >
                      <a class="dropdown-item">
                        {currentLanguage === "en" && course?.title_en}
                        {currentLanguage === "pl" && course?.title_pl}
                        {currentLanguage === "ro" && course?.title_roa}
                      </a>
                    </li>
                  ))}
                  
                </ul> */}
              </li>

              <li class="nav-item dropdown">
                <a
                  class="nav-link active"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  cursor="pointer"
                >
                  {/* <b className="navbar-link">{t("categories")}</b> */}
                  <b className="navbar-link">Course</b>
                  <FaAngleDown className="dropdown" />
                </a>
                <ul class="dropdown-menu">
                  {category?.map((categorise) => (
                    <li
                      className="cursor-pointer"
                      key={categorise.lang_en}
                      value={categorise.id}
                    >
                      <a
                        class="dropdown-item"
                        cursor="pointer"
                        onClick={() => categoryList(categorise.id)}
                      >
                        {currentLanguage === "en" &&
                          capitalizeFirstLetter(categorise?.lang_en)}
                        {currentLanguage === "pl" &&
                          capitalizeFirstLetter(categorise?.lang_pl)}
                        {currentLanguage === "ro" &&
                          capitalizeFirstLetter(categorise?.lang_roa)}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
              {/* <li class="nav-item dropdown">
                <a
                  class="nav-link active"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  cursor="pointer"
                >
                  <b>{t("trades")} </b>
                  <FaAngleDown className="dropdown" />
                </a>
                <ul class="dropdown-menu">
                  {trades?.map((trade) =>
                    trade?.trades_sub_categories?.map((subTrade, index) => (
                      <li className="dropdown cursor-pointer" key={index}>
                        <a
                          className="dropdown-item"
                          onClick={() => courseListPage(subTrade.id)}
                        >
                          {currentLanguage === "en" && subTrade?.lang_en}
                          {currentLanguage === "pl" && subTrade?.lang_pl}
                          {currentLanguage === "ro" && subTrade?.lang_roa}
                        </a>
                      </li>
                    ))
                  )}
                </ul>
              </li> */}
              <li class="nav-item dropdown">
                <a
                  class="nav-link active"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  cursor="pointer"
                >
                  {/* <b className="navbar-link">{t("accreditations")} </b> */}
                  <b className="navbar-link">NVQs </b>
                  <FaAngleDown className="dropdown" />
                </a>
                <ul class="dropdown-menu">
                  {accredits?.map((accredits, index) => (
                    <li
                      className="cursor-pointer"
                      key={accredits.lang_en}
                      value={accredits.id}
                    >
                      <a
                        class="dropdown-item"
                        onClick={() => courseListPage2(accredits.id)}
                      >
                        {currentLanguage === "en" &&
                          capitalizeFirstLetter(accredits?.lang_en)}
                        {currentLanguage === "pl" &&
                          capitalizeFirstLetter(accredits?.lang_pl)}
                        {currentLanguage === "ro" &&
                          capitalizeFirstLetter(accredits?.lang_roa)}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link active"
                  id="navbarDropdownTop" // Unique ID for the top-level dropdown
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <b>Accreditations</b>
                  <FaAngleDown className="dropdown" />
                </a>
                <ul className="dropdown-menu">
                  {accredits?.map((accredit, index) => (
                    <li key={accredit.id} value={accredit.id}>
                      <a
                        className="dropdown-item"
                        // onClick={() => courseListPage()}
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {accredit.lang_en}
                        <FaAngleDown className="dropdown" />
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="/">
                            Action
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/">
                            Another action
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/">
                            Something else here
                          </a>
                        </li>
                      </ul>
                    </li>
                  ))}
                </ul>
              </li> */}
            </ul>

            <div className="collapse_submenu navbar-collapse mx-3">
              <ul className="navbar-nav ">
                <li className="nav-item ">
                  <a
                    className="nav-link active cursor-pointer "
                    onClick={() => studentHome()}
                  >
                    <b className="navbar-text">{t("home")}</b>
                  </a>
                </li>
                <li className="nav-item ">
                  <a
                    className="nav-link active cursor-pointer "
                    onClick={() => partnersPage()}
                  >
                    <b className="navbar-text">{t("partners")}</b>
                  </a>
                </li>
                <li className="nav-item ">
                  <a
                    className="nav-link active cursor-pointer"
                    onClick={() => aboutPage()}
                  >
                    <b className="navbar-text">{t("aboutUs")}</b>
                  </a>
                </li>
              </ul>
            </div>

            <div className="collapse_submenu navbar-collapse nav-icon-list">
              <ul>
                {/* this is use for the language useTranslation  */}
                {/* <li className="nav-item dropdown icon-flag">
                  <a
                    class="nav-link active"
                    href="/"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                   {currentLanguage==="en"&& <img src={ukFlag} alt="UK Flag" className="flag-icon" />}
                   {currentLanguage==="ro"&& <img src={roFlag} alt="RO Flag" className="flag-icon" />}
                   {currentLanguage==="pl"&& <img src={plFlag} alt="PL Flag" className="flag-icon" />}
                     {currentLanguage==="en" && <b className="navbar-lang">EN</b> }{" "}
                     {currentLanguage==="ro" && <b className="navbar-lang">RO</b> }{" "}
                     {currentLanguage==="pl" && <b className="navbar-lang">PL</b> }{" "}
                    <FaAngleDown className="dropdown" />
                  </a>
                  <LanguageSwitcher setLanguage={currentLanguage} />
                  </li> */}
                {/* this is commented code faltu  */}
                {/* <ul class="dropdown-menu nav-lang-menu cursor-pointer" cursor="pointer">
                    <li className='cursor-pointer'>
                      <a class="dropdown-item" onClick={()=>setLanguage("en")} >
                        English
                      </a>
                      <a class="dropdown-item" onClick={()=>setLanguage("ro")} >
                        Roman
                      </a>
                      <a class="dropdown-item" onClick={()=>setLanguage("pl")} >
                        Polish
                      </a>
                    </li>
                  </ul> */}

                {/* <li className="nav-item d-flex icon-heart mx-2">
                  <a className="icon nav-link active">
                    <FaHeart className="heart m-2" cursor="pointer" />
                  </a>
                </li>
                <li className="nav-item d-flex icon-heart">
                  <a className="icon nav-link active">
                    <FaShoppingCart
                      className="cart m-2"
                      cursor="pointer"
                      onClick={() => {
                        navigate(`/cart/`);
                      }}
                    />
                  </a>
                  <div className="cart-no">
                    {" "}
                    {storeData ? items.length : "0"}
                  </div>
                </li> */}
              </ul>
            </div>

            <div className="collapse_submenu navbar-collapse sub-login-menu">
              {" "}
              {storeData ? (
                <div className="btn d-flex mx-1 border-none" role="search">
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link active"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      cursor="pointer"
                    >
                      <h5>
                        {t("Hi,")} {storeData && storeData}{" "}
                        <FaAngleDown className="dropdown" />
                      </h5>
                    </a>
                    <ul class="dropdown-menu">
                      <li className="dropdown cursor-pointer">
                        <a className="dropdown-item" onClick={goToDAsboard}>
                          {t("Dashboard")}
                        </a>
                      </li>
                      <li className="dropdown cursor-pointer">
                        <a
                          className="dropdown-item"
                          onClick={clearLocalStorage}
                        >
                          {t("Logout")}
                        </a>
                      </li>
                    </ul>
                  </li>
                </div>
              ) : (
                <div className="btn d-flex log-btn border-none" role="search">
                  <button
                    class="btn px-2 border-none"
                    style={{ width: "115px" }}
                    onClick={() => loginPage()}
                  >
                    <b className="navlogin">{t("login").toUpperCase()}</b>
                  </button>
                  {/* <button
                    class="btn px-2"
                    type="submit"
                    style={{
                      background: " rgba(4, 201, 119, 1)",
                      color: "#fafafa",
                      width: "140px",
                    }}
                    onClick={() => registerPage()}
                  >
                    <b className="navregister">{t("register").toUpperCase()}</b>
                  </button> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
