// import React, { useEffect, useRef } from "react";

// const Payl8rCalculator = ({ price ,index}) => {
//   const calculatorRef = useRef(null);
//   const priceRef = useRef(null);


//   useEffect(() => {
//     const scriptId = "pl-calculator-light-app-script";

//     const initializeCalculator = () => {
//       const priceElement = priceRef.current;
//       const calculatorElement = calculatorRef.current;
//       console.log("priceRef.current:", priceRef.current);
//       console.log("calculatorRef.current:", calculatorRef.current);
//       console.log("document.plCalcSelector:", document.plCalcSelector);
//       if (!priceElement || !calculatorElement) {
//         console.error("Price or calculator elements are not available");
//         return;
//       }

//       document.plCalcPrice = {
//         Price: price,
//         rerender: (newPrice) => {
//           console.log("Rerender with new price:", newPrice);
//         },
//       };
//       document.plCalcSelector = price;

//       const loadScript = () => {
//         if (!document.getElementById(scriptId)) {
//           const script = document.createElement("script");
//           script.src =
//             "https://assets.payl8r.com/js/pl-calculator-light-app.js";
//           script.id = scriptId;
//           script.dataset.theme = "spread";
//           script.onload = () => {
//             payl8rCalcPrice();
//           };
//           script.onerror = (e) => {
//             console.error(`Error loading script: ${e}`);
//           };
//           document.body.appendChild(script);
//         } else {
//           payl8rCalcPrice();
//         }
//       };

//       const payl8rCalcPrice = () => {
//         const get_price_span = () =>
//           document.querySelector(document.plCalcSelector);

//         const get_price = () => {
//           const priceSpan = get_price_span();
//           if (!priceSpan) {
//             console.error("Price span element not found.");
//             return;
//           }
//           return parseFloat(priceSpan.innerHTML.replace(/[^0-9.]/g, ""));
//         };

//         const newPrice = get_price();
//         if (newPrice !== undefined) {
//           document.plCalcPrice.Price = newPrice;
//           document.plCalcPrice.rerender(newPrice);
//         }

//         const MutationObserver =
//           window.MutationObserver ||
//           window.WebKitMutationObserver ||
//           window.MozMutationObserver;

//         const docPrice = document.querySelector(document.plCalcSelector);
//         if (!docPrice) {
//           console.error("Price span element not found for mutation observer.");
//           return;
//         }

//         const observer = new MutationObserver((mutations) => {
//           mutations.forEach(() => {
//             payl8rCalcPrice();
//           });
//         });

//         observer.observe(docPrice, {
//           attributes: true,
//           childList: true,
//           characterData: true,
//           subtree: true,
//         });
//       };

//       const payl8rTimed = () => {
//         if (document.readyState === "complete") {
//           payl8rCalcPrice();
//         } else {
//           setTimeout(payl8rTimed, 10);
//         }
//       };

//       setTimeout(payl8rTimed, 10);

//       loadScript();
//     };

//     if (priceRef.current && calculatorRef.current) {
//       initializeCalculator();
//     }

//     return () => {
//       const scriptElement = document.getElementById(scriptId);
//       if (scriptElement) {
//         scriptElement.remove();
//       }
//     };
//   }, [price]);

//   return (
//     <>
//     <div style={{marginTop: "-78px"}}>
//       <div ref={calculatorRef} id="pl-calculator-light-app"></div>
//       </div>
//     </>
//   );
// };

// export default Payl8rCalculator;

import React, { useEffect, useRef, useState } from "react";

const Payl8rCalculator = ({ price, index }) => {
  const itemsRef = useRef(new Map());
  const scriptLoadedRef = useRef(false);
  const [calculatedPrices, setCalculatedPrices] = useState(new Map());
  useEffect(() => {
    const scriptId = "pl-calculator-light-app-script";

    const initializeCalculator = () => {
      const itemRefs = itemsRef.current.get(index);
      if (!itemRefs) {
        console.error(`Refs for index ${index} not found`);
        return;
      }

      const priceElement = itemRefs.priceRef;
      const calculatorElement = itemRefs.calculatorRef;

      if (!priceElement || !calculatorElement) {
        console.error("Price or calculator elements are not available");
        return;
      }

      document.plCalcPrice = {
        Price: price,
        rerender: (newPrice) => {
          console.log("Rerender with new price:", newPrice);
          setCalculatedPrices((prevPrices) => new Map(prevPrices).set(index, newPrice));
        },
      };
      document.plCalcSelector = `.price-${index}`;

      const loadScript = () => {
        if (!document.getElementById(scriptId)) {
          const script = document.createElement("script");
          script.src = "https://assets.payl8r.com/js/pl-calculator-light-app.js";
          script.id = scriptId;
          script.dataset.theme = "spread";
          script.onload = () => {
            scriptLoadedRef.current = true;
            payl8rCalcPrice();
          };
          script.onerror = (e) => {
            console.error(`Error loading script: ${e}`);
          };
          document.body.appendChild(script);
        } else {
          payl8rCalcPrice();
        }
      };

      const payl8rCalcPrice = () => {
        const priceElement = document.querySelector(document.plCalcSelector);
        if (!priceElement) {
          console.error("Price span element not found.");
          return;
        }

        const getPrice = () => parseFloat(priceElement.innerHTML.replace(/[^0-9.]/g, ""));
        const newPrice = getPrice();
        if (newPrice !== undefined) {
          document.plCalcPrice.Price = newPrice;
          document.plCalcPrice.rerender(newPrice);
        }

        const observer = new MutationObserver(() => {
          payl8rCalcPrice();
        });

        observer.observe(priceElement, {
          attributes: true,
          childList: true,
          characterData: true,
          subtree: true,
        });
      };

      if (!scriptLoadedRef.current) {
        loadScript();
      } else {
        payl8rCalcPrice();
      }
    };

    initializeCalculator();

    return () => {
      const scriptElement = document.getElementById(scriptId);
      if (scriptElement) {
        scriptElement.remove();
      }
    };
  }, [price, index]);

  return (
    <div key={index} className="paymentMargin">
      <div className="d-flex align-items-center mt-4 cl-course-payment">
        <h4
          ref={(node) => {
            const map = new Map(itemsRef.current);
            if (node) {
              map.set(index, { ...map.get(index), priceRef: node });
            } else {
              const entry = map.get(index);
              if (entry) {
                delete entry.priceRef;
                if (!entry.calculatorRef) map.delete(index);
              }
            }
            itemsRef.current = map;
          }}
          className={`fw-bold price-${index}`}
          style={{ color: "#09B970", display:"none"}}
        >
         £{price}
        </h4>
      </div>
      <div
        ref={(node) => {
          const map = new Map(itemsRef.current);
          if (node) {
            map.set(index, { ...map.get(index), calculatorRef: node });
          } else {
            const entry = map.get(index);
            if (entry) {
              delete entry.calculatorRef;
              if (!entry.priceRef) map.delete(index);
            }
          }
          itemsRef.current = map;
        }}
        id="pl-calculator-light-app"
      ></div>
    </div>
  );
};

export default Payl8rCalculator;

