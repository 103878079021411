import React, { useState, useRef, useContext } from "react";
import { Row, Col, Button, Card } from "react-bootstrap";
import image from "../../assets/images/students-walk-downstairsdd-with-books-library.png";
import {
  FiCheckCircle,
  FiStar,
  FiShoppingCart,
  FiCalendar,
  FiInfo,
  FiClock,
} from "react-icons/fi";
import { FaFire } from "react-icons/fa";
import "./Cart.css";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";
import { useLocalStore, useCartStore } from "../../store";
import PaymentsInfo from "../courselist/PaymentsInfo";
import useClickOutside from "../courselist/useClickOutside";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../LanguageContext";
import CartImg from "../../assets/images/cart.png";
import { IoIosArrowRoundBack } from "react-icons/io";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

const CartItems = () => {
  const { t } = useTranslation();
  const { items, removeFromCart } = useCartStore();
  const navigate = useNavigate();
  const removeItms = (course) => {
    removeFromCart(course.id);
  };

  const [togglePayInfo, setTogglePayInfo] = useState([]);
  const paymentsInfoRef = useRef(null); //creating ref for the PaymentsInfo
  const handleTogglePayInfo = (index) => {
    const updatedToggles = [...togglePayInfo];
    updatedToggles[index] = !updatedToggles[index];
    setTogglePayInfo(updatedToggles);
  };
  useClickOutside(paymentsInfoRef, () => {
    setTogglePayInfo([]);
  });
  const languageData = useContext(LanguageContext);
  if (items.length === 0) {
    return (
      <>
        <Navbar />
        <div className="card1">
          <img src={CartImg} alt="Card" className="card-image" />
          <div className="card-content">
            <h3 className="card-title">Your Cart is empty</h3>
            <p className="card-description">
              Looks like you have not added anything to you cart. Go ahead &
              explore our courses.
            </p>

            <div className="card-button-container">
              <Link to="/">
                <button className="card-button">
                  <IoIosArrowRoundBack size="30" />
                  Explore Our courses
                </button>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <Navbar></Navbar>
      <div className="cardSize">
        {items?.map((course, index) => (
          <Card
            key={index}
            className="mt-4 cl-course-card"
            style={{
              borderColor: "white",
              marginBottom: "30px",
            }}
          >
            <Row className="g-1">
              <Col xs={12} md={4}>
                <div className="position-relative ">
                  <Card.Img
                    className="cl-card-image"
                    src={course?.course_image}
                    alt="Card image"
                    style={{ backgroundColor: "gray", height: "35vh" }}
                  />
                  <div>
                    <h5
                      className="position-absolute p-3 mb-5 cl-titleTag"
                      variant="danger"
                      /* style={titleTag} */
                    >
                      {languageData?.currentLanguage === "en" &&
                        course?.title_en}
                      {languageData?.currentLanguage === "pl" &&
                        course?.title_pl}
                      {languageData?.currentLanguage === "ro" &&
                        course?.title_roa}
                    </h5>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={8} className="my-0">
                <Card.Body className="cl-card-body">
                  <div className=" d-flex pb-3">
                    <span
                    //   className="category-tag cl-categoryTag" /* style={categoryTag} */
                    >
                      {/* {course?.course_category?.lang_en} */}
                      {/* jhjkfhk kbsjkcbuius */}
                    </span>
                    <span
                    //   className="category-tag cl-categoryTag" /* style={categoryTag} */
                    >
                      {/* {course?.trades_category?.lang_en} */}
                      {/* jkbsjkf uhioahdo */}
                    </span>
                    <span
                    //   className="category-tag cl-categoryTag" /* style={categoryTag} */
                    >
                      {/* {course?.keyword} */}
                      {/* abc */}
                      {/* <FiInfo /> */}
                    </span>
                  </div>
                  <div>
                    <h4 className="cl-card-short-desc ">
                      {" "}
                      {languageData?.currentLanguage === "en" &&
                        course?.short_desc_en.slice(0, 53) + "..."}
                      {languageData?.currentLanguage === "pl" &&
                        course?.short_desc_pl.slice(0, 53) + "..."}
                      {languageData?.currentLanguage === "ro" &&
                        course?.short_desc_roa.slice(0, 53) + "..."}
                    </h4>
                  </div>
                  <div className="d-flex align-items-center mt-3 cl-c-schedule row">
                    <div className="cl-course-schedule col-lg-4 col-md-6 col-xs-12">
                      <span className="me-2">
                        <FiCalendar style={{ color: "#09B970" }} />
                      </span>
                      {t("Starting:")}&nbsp;&nbsp;&nbsp;&nbsp;
                      <strong>{course?.courseStartsAt}</strong>
                    </div>
                    <div className=" cl-course-schedule col-lg-4 col-md-6 col-xs-12">
                      <span className="me-2">
                        <FiClock style={{ color: "#09B970" }} />
                      </span>
                      {t("Schedule:")}&nbsp;&nbsp;&nbsp;&nbsp;
                      <strong>
                        {course?.schedule.charAt(0).toUpperCase() +
                          course?.schedule.slice(1)}
                      </strong>
                    </div>
                  </div>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="d-flex align-items-center mt-4 cl-course-payment">
                        <h4 style={{ color: "#09B970" }}>
                        £{course?.total_price}
                        </h4>
                        <span className="ms-3">
                          × {course?.payment_options} {t("Payments")}
                        </span>
                        <span className="ms-2">
                          <button
                            className="btn"
                            onClick={() => handleTogglePayInfo(index)}
                          >
                            <FiInfo />
                          </button>
                          {togglePayInfo[index] && (
                            <div
                              className="payments-info"
                              ref={paymentsInfoRef}
                            >
                              <PaymentsInfo props={course} />
                            </div>
                          )}
                        </span>
                      </div>
                    </Col>
                    <Col xs={12} md={6} className="text-end ">
                      <div className="d-flex align-items-center justify-content-md-end mt-4">
                        <div className="me-2 w-100">
                          <Button
                            className="w-100 btn cl-more-danger"
                            variant="danger"
                            // onClick={() => {
                            //   navigate("/course-details", {
                            //     state: {
                            //       coursesData: course,
                            //     },
                            //   });
                            //   window.scrollTo(0, 0);
                            // }}
                            onClick={() => removeItms(course)}
                          >
                            {/* <FiInfo className="me-2" /> */}
                            {/* {t("More Info")} */}
                            {t("Remove")}
                          </Button>
                        </div>
                        <div className="w-100">
                          <Button
                            className="w-100 btn cl-add-cart"
                            variant="success"
                            onClick={() => {
                              navigate("/payment/", {
                                state: {
                                  courseDetail: course,
                                },
                              });
                            }}

                            // onClick={() => navigate("/payment")}
                          >
                            <FiShoppingCart className="me-2" />
                            {/* {t("Buy Now")} */}
                            {t("Buy Now")}
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        ))}
      </div>
    </>
  );
};

export default CartItems;
