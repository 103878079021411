import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik"; // Import only the necessary parts from Formik
import {
  Container,
  Row,
  Form,
  Col,
  // Button,
  Modal,
  Image,
} from "react-bootstrap";
import "./FirmForm.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../store";
import Cookies from "js-cookie";
import contryCode from "../contry";
import { FaAngleDown, FaPlus } from "react-icons/fa";
import {
  Button,
  styled,
  Table,
  TableBody,
  TableCell,
  IconButton,
  TableRow,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box } from "@mui/system";
import { S3 } from "aws-sdk";
import { countries } from "../contry";
import SelectSearch from "react-select-search";
import "react-select-search/style.css";
import { baseUrl } from "../config";
import debounce from "lodash.debounce";
import Select from "react-select";
import axios from "axios";
const FirmForm = () => {
  const navigate = useNavigate();
  const { addUser } = useUserStore();
  const logoRef = useRef(null);
  const bannerRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileBanner, setSelectedFileBanner] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [fileUrl2, setFileUrl2] = useState("");
  const [imageType, setImageType] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  // console.log("fist image",fileUrl,"second image",fileUrl2)
  const [dialCode, setDialCode] = useState("");

  const countryOptions = countries.map((country) => ({
    name: `${country.name} ${country.dial_code} ${country.emoji}`, // You can include emoji if desired
    value: country.code, // Use a unique identifier as the value
  }));
  const handleChange = (selectedValue) => {
    function findDialCodeByCode(codeToFind) {
      const foundObject = countries.find((item) => item.code === codeToFind);
      if (foundObject) {
        return foundObject.dial_code;
      } else {
        return null; // Return null if no matching object is found
      }
    }
    setDialCode(findDialCodeByCode(selectedValue));
    // You can update your state or perform any other action here
  };
  const formik = useFormik({
    initialValues: {
      firmName: "",
      account_type: "firm",
      first_name: "Firm user",
      email_id: "",
      country_code: "+44",
      mobile: "",
      firm_logo: null, // Change initial value to null
      firm_logoName: "", // Add a field for the file name
      about_firm: "",
      banner: null, // Change initial value to null
      bannerName: "", // Add a field for the file name
      password: "",
      address1: "",
      address2: "",
      postal_code: "",
      city: "",
    },
    onSubmit: async (values) => {
      setIsLoadingSubmit(true);
      const formData = {
        address: address,
        firmName: values.firmName,
        email_id: values.email_id,
        mobile: values.mobile,
        country_code: "+44",
        account_type: "firm",
        password: values.password,
        about_firm: values.about_firm,
        firm_logo: fileUrl,
        banner: fileUrl2,
        status: false,
      };

      // console.log("formData", formData)
      const data = await addUser(formData);
      // console.log("data",data)
      try {
        try {
          if (data.httpCode == 200) {
            Cookies.set("UUID", data?.data?.id, {
              domain: ".skillmo.co.uk",
              // domain: "localhost",
            });
            Cookies.set("TOKEN", data?.data?.token, {
              domain: ".skillmo.co.uk",
              // domain: "localhost",
            });
            Cookies.set("NAME", data?.data?.firmName, {
              domain: ".skillmo.co.uk",
              // domain: "localhost",
            });
            Cookies.set("ACCOUNT", data?.data?.acc_type, {
              domain: ".skillmo.co.uk",
              // domain: "localhost",
            });
            if (values.account_type == "user") {
              setTimeout(() => {
                navigate(-1);
                setIsLoadingSubmit(false);
              }, 2000);
            } else {
              toast.success(
                "You have successfully registered. Please wait for the approval",
                {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                }
              );
              setTimeout(() => {
                navigate(-1);
                setIsLoadingSubmit(false);
              }, 2000);
            }
          } else {
            toast.error("Email OR Phone Allready Registered !", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setTimeout(() => {
              setIsLoadingSubmit(false);
            }, 2000);
          }
        } catch (error) {
          console.error("Error during login:", error);
        }
      } catch (error) {
        console.error("Error during login:", error);
      }
    },
  });

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setImageType("logo");
    formik.setFieldValue("firm_logo", file);
    formik.setFieldValue("firm_logoName", file ? file.name : ""); // Set the file name or an empty string if() no file is selected
  };

  const handleBannerChange = (event) => {
    const file = event.target.files[0];
    setSelectedFileBanner(file);
    setImageType("banner");
    formik.setFieldValue("banner", file);
    formik.setFieldValue("bannerName", file ? file.name : ""); // Set the file name or an empty string if no file is selected
  };

  useEffect(() => {
    const handleUpload = async () => {
      if (imageType) {
        try {
          const s3 = new S3({
            accessKeyId: "AKIA4HADCZTKRFKZGAG7",
            secretAccessKey: "OinbuVB+/oEOwb1viDN1/fTAuOm+3wjpJkNWA4p7",
            region: "ap-south-1",
          });

          // Generate a unique file name (e.g., timestamp + file name)
          const fileName = `${Date.now()}-${
            imageType === "logo" ? selectedFile?.name : selectedFileBanner?.name
          }`;

          const params = {
            Bucket: "mybucket-aws-skillmo-1",
            Key: fileName,
            Body: imageType === "logo" ? selectedFile : selectedFileBanner,
            ACL: "public-read",
            ContentType:
              imageType === "logo"
                ? selectedFile?.type
                : selectedFileBanner?.type,
          };
          // Upload the file to S3
          const uploadResponse = await s3.upload(params).promise();
          // Set the success message and get the URL of the uploaded file
          const fileUrl = uploadResponse.Location;
          imageType === "logo" ? setFileUrl(fileUrl) : setFileUrl2(fileUrl);
        } catch (error) {
          console.error("Error uploading file:", error);
          setUploadMessage("Error uploading file. Please try again.");
        } finally {
          setUploading(false);
        }
      } else {
        console.log("Please select a file to upload.");
      }
    };
    handleUpload();
  }, [selectedFile, selectedFileBanner]);

  const [address, setAddress] = useState([
    { address1: "", address2: "", postal_code: "", city: "" },
  ]);

  const addAddress = () => {
    let object = {
      address1: "",
      address2: "",
      postal_code: "",
      city: "",
    };

    setAddress([...address, object]);
  };

  const removeAddress = (index) => {
    const filterAddress = [...address];
    filterAddress.splice(index, 1);
    setAddress(filterAddress);
  };

  const onChange = (e, index) => {
    const { name, value } = e.target;
    const updatedAddress = [...address];
    updatedAddress[index] = {
      ...updatedAddress[index],
      [name]: value,
    };
    setAddress(updatedAddress);
  };
  const [searchText, setSearchText] = useState("");

  const handleCountryCodeChange = (event) => {
    formik.handleChange(event);
  };

  const handleSearchInputChange = (event) => {
    setSearchText(event.target.value);
  };

  // this is use for the post code search
  const handleInputChange = async (inputValue) => {
    if (!inputValue || typeof inputValue !== "string") {
      setOptions([]);
      return;
    }

    setIsLoading(true);

    const data2 = inputValue.toUpperCase();
    const data1 = data2.replace(/-/g, " ");

    try {
      const response = await axios.get(
        `${baseUrl}/master/address?search=${data1}`
      );
      const data = response.data.data.data.map((item) => ({
        value: item.Postcode,
        label: item.Postcode,
      }));
      setOptions(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateQuery = (inputValue) => {
    const data = inputValue?.toUpperCase();
    const data1 = data?.replace(/-/g, " ");
    handleInputChange(data1);
  };
  // this is the delay call for the search address

  const debounceOnChange = debounce(updateQuery, 100);

  // this is use for the set selected postal code

  const handleChangeAdd = (selectedOption) => {
    setSelectedOption(selectedOption);
    const updatedAddress = [...address];
    updatedAddress[0] = {
      ...updatedAddress[0],
      postal_code: selectedOption.value,
    };
    setAddress(updatedAddress);
  };

  // Filter the countries based on the search text
  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div>
      <div className="reg-form-header p-3"> Sign Up</div>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Row>
            <Col xs={12}>
              <Form.Group controlId="formFirmName">
                <Form.Label className="form-label">Firm Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firmName"
                  value={formik.values.firmName}
                  onChange={formik.handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.Group controlId="formEmail">
                <Form.Label className="form-label">Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email_id"
                  value={formik.values.email_id}
                  onChange={formik.handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.Group controlId="formPassword">
                <Form.Label className="form-label">Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            {/* <Col xs={3}> */}
            {/* <Form.Group controlId="formCountryCode">
                <Form.Label className="form-label">Country</Form.Label>
                <input
                  type="text"
                  name="country_search"
                  className="form-control mb-2"
                  placeholder="+44"
                /> */}
            {/* <SelectSearch
                  options={countryOptions}
                  search
                  placeholder={dialCode || 'Select'}
                  onChange={handleChange}
                /> */}
            {/* <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Search country..."
                  value={searchText}
                  onChange={handleSearchInputChange}
                /> */}

            {/* <Form.Control
                  as="select" // Use a select input for country codes
                  name="country_code"
                  value={formik.values.country_code}
                  onChange={handleCountryCodeChange}
                >
                  <option value="">Select a country</option>
                  {filteredCountries.map((country) => (
                    <option key={country.code} value={country.dial_code}>
                      {country.name} ({country.dial_code})
                    </option>
                  ))}
                </Form.Control>
                <input
                  type="hidden" // Hidden input field to store the selected dial code
                  name="country_code"
                  value={formik.values.country_code}
                  onChange={formik.handleChange}
                /> */}
            {/* </Form.Group> */}
            {/* </Col> */}
            <Col xs={12}>
              {/* Phone number */}
              <Form.Group controlId="formMobile">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="number"
                  name="mobile"
                  value={formik.values.mobile}
                  onChange={(e) => {
                    if (e.target.value.length <= 12) {
                      formik.handleChange({
                        target: {
                          name: "mobile",
                          value: e.target.value.toString(), // Convert to string
                        },
                      });
                    }
                  }}
                  maxLength={12}
                />
              </Form.Group>
            </Col>
          </Row>

          <Box className="mb-2">
            <Table sx={{ whiteSpace: "pre", minWidth: 350 }}>
              <TableBody>
                {address.map((adsresss, index) => (
                  <TableRow key={index} sx={{ position: "relative" }}>
                    <Row style={{ width: "590px" }}>
                      <Col lg={6} md={6} sm={12}>
                        <Form.Group controlId="formAddress">
                          <Form.Label className="form-label">
                            Address1
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="address1"
                            onChange={(e) => onChange(e, index)}
                            value={adsresss.address1}
                            required
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={6} md={6} sm={12}>
                        <Form.Group controlId="formAddress2">
                          <Form.Label className="form-label">
                            Address2
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="address2"
                            onChange={(e) => onChange(e, index)}
                            value={adsresss.address2}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row style={{ width: "590px" }}>
                      <Col lg={6} md={6} sm={12}>
                        <Form.Group controlId="formPostalCode">
                          <Form.Label className="form-label">
                            Postal Code
                          </Form.Label>
                          {/* <Form.Control
                            type="text"
                            name="postal_code"
                            onChange={(e) => onChange(e, index)}
                            value={adsresss.postal_code}
                          /> */}

                          <Select
                            value={selectedOption}
                            onChange={handleChangeAdd}
                            onInputChange={debounceOnChange}
                            options={options}
                            isLoading={isLoading}
                            placeholder="Type to search"
                            isSearchable={true}
                            styles={customStyles}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6} sm={12}>
                        <Form.Group controlId="formPostalCode">
                          <Form.Label className="form-label">City</Form.Label>
                          <Form.Control
                            type="text"
                            name="city"
                            onChange={(e) => onChange(e, index)}
                            value={adsresss.city}
                            required
                          />
                        </Form.Group>
                      </Col>
                      {/* <Col sm={1}>
                        <IconButton
                          size="small"
                          style={{ marginTop: "32px" }}
                          onClick={() => removeAddress(index)}
                        >
                          <ClearIcon color="error" />
                        </IconButton>
                      </Col> */}
                    </Row>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* <Button
              color="primary"
              variant="contained"
              sx={{ mt: "8px !important" }}
              onClick={addAddress}
            >
              + Add
            </Button> */}
          </Box>

          {/* <Row className="m-2">
            <Col xs={12}>
              <Form.Group controlId="formfirm_logo">
                <Form.Label className="form-label">Firm Logo</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  ref={logoRef}
                  style={{ display: "none" }}
                  onChange={handleLogoChange}
                />
                <Button
                  variant="outline-secondary mx-3"
                  onClick={() => logoRef.current.click()}
                >
                  Browse
                </Button>
                <img
                  src={
                    formik?.values?.firm_logo
                      ? URL?.createObjectURL(formik?.values?.firm_logo)
                      : ""
                  }
                  alt=""
                  style={{ maxWidth: "100px" }}
                />
                <div className="browse-file">{formik.values.firm_logoName}</div>
              </Form.Group>
            </Col>
          </Row> */}
          {/* <Row className="m-2">
            <Col xs={12}>
              <Form.Group controlId="formabout_firm">
                <Form.Label className="form-label">Firm Summary</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={1}
                  name="about_firm"
                  value={formik.values.about_firm}
                  onChange={formik.handleChange}
                  style={{ height: "15vh" }}
                />
              </Form.Group>
            </Col>
          </Row> */}
          {/* <Row className="m-2">
            <Col xs={12}>
              <Form.Group controlId="formbanner">
                <Form.Label className="form-label">Firm Banner</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  ref={bannerRef}
                  style={{ display: "none" }}
                  onChange={handleBannerChange}
                />
                <Button
                  variant="outline-secondary mx-3"
                  onClick={() => bannerRef.current.click()}
                >
                  Browse
                </Button>
                <img
                  src={
                    formik.values.banner
                      ? URL.createObjectURL(formik.values.banner)
                      : ""
                  }
                  alt=""
                  style={{ maxWidth: "100px" }}
                />
                <div className="browse-file">{formik.values.bannerName}</div>
              </Form.Group>
            </Col>
          </Row> */}
          <Row>
            <Col xs={12}>
              <Button
                variant="primary"
                type="submit"
                style={{
                  backgroundColor: "#10D382",
                  color: "#fafafa",
                  fontWeight: 700,
                  fontSize: "14px",
                  padding: "12px 20px",
                  border: "none",
                  borderRadius: "8px",
                }}
              >
                {isLoadingSubmit ? "Loading..." : "Submit"}
              </Button>
            </Col>
          </Row>
        </Row>
      </Form>
    </div>
  );
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: "47px",
    height: "47px",
    minWidth: "270px",
    width: "270px",
  }),
  menu: (provided) => ({
    ...provided,
    width: "270px",
  }),
};

export default FirmForm;
