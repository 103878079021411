import React, { useEffect, useState } from 'react';
import bgImage from '../../assets/images/Rectangle 103035.png';
import '../../assets/css/home/AboutUsStrip.css';
import { useUserStore, useCourseStore } from '../../store';
import { useTranslation } from 'react-i18next';
import { baseUrl } from '../../config';
const AboutUsStrip = () => {
  const {t} =useTranslation()
  const { courses, fetchCourses } = useCourseStore();
  const { users, fetchUsers } = useUserStore();
  const [stripData, setData] = useState([]);

  const fetchData = async () => {
    const response = await fetch(
      `${baseUrl}/user/statics`
    );
    const data = await response.json();
    setData(data.data.data);
  };

  // console.log('strip data', stripData);
  useEffect(() => {
    fetchCourses();
    fetchUsers();
    fetchData();
  }, []);
  // console.log('Loading', users);
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center bg-img"
      >
        <div className="container" style={{zIndex:"999"}}>
          <div className="row h-100">
            <div className="col-md-3 ">
              <h2 className="about-us" style={{ color: 'white' }}>
                {t("Our Numbers Tell More About Us")}
              </h2>
            </div>
            <div className="col-md-3">
              <h1
                
                style={{ color: ' rgba(16, 211, 130, 1)' }}
              >
                {stripData.course}
              </h1>
              {/* <p className='about-uss1' style={{ color: '#d5d3da' }}>{t("Courses on the platform")}</p> */}
              <p className='about-uss1' style={{ color: '#d5d3da' }}>{t("Courses on")}<br/>{t("the platform")}</p>
            </div>
            <div className="col-md-3">
              <h1
                
                style={{ color: ' rgba(16, 211, 130, 1)' }}
              >
                {stripData.firm}k+
              </h1>
              {/* <p className='about-uss1' style={{ color: '#d5d3da' }}>{t("Lead industry companies")}</p> */}
              <p className='about-uss1' style={{ color: '#d5d3da' }}>{t("Lead industry")} <br/>{t("companies")}</p>
            </div>
            <div className="col-md-3">
              <h1
                
                style={{ color: ' rgba(16, 211, 130, 1)' }}
              >
                {stripData.users}k+
              </h1>
              {/* <p className='about-uss1' style={{ color: '#d5d3da' }}>
                {t("Students who study on the platform")}
              </p> */}
              <p className='about-uss1' style={{ color: '#d5d3da' }}>
                {t("Students who study")} <br/> {t("on the platform")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsStrip;
