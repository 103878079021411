import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const StudentHome = () => {
  return (
    <>
    <Navbar></Navbar>
    <div style={{marginTop: '5%', marginLeft: '25%', marginBottom: '3%'}}>
      <h2>Student Home Page</h2>
    </div>
    <Footer></Footer>
    </>
    
  )
}

export default StudentHome
