import React ,{useContext, useState, useEffect}from "react";
import pic101 from "../../assets/images/registerd.PNG";
import { Row, Col } from "react-bootstrap";
import "../../assets/css/home/Register.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../LanguageContext";
import FirmForm from '../../auth/FirmForm';


const Register = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const languageData = useContext(LanguageContext);


  const [activeForm, setForm] = useState("user");

  const handleFormUser = () => {
    navigate("/register");
    setForm("user");
    window.scrollTo(0, 0);
  };
  const handleFormFirm = () => {
    navigate("/register");
    setForm("firm");
    window.scrollTo(0, 0);
  };

  // const handleButtonClick = () => {
  //   setForm("firm");
  // };
  
  return (
    <div>
      <Row>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={6}
          className="footer-regi-main"
        >
          <div className="footer-regi">
            <h5
              style={{
                fontFamily: "Metropolis",
                fontSize: "16px",
                // fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "5px",
                textAlign: "left",
                marginTop:"25px"
              }}
            >
              {t("LEARN AND PRACTICE")}
            </h5>
            <h3 style={{
              fontFamily: "Metropolis",
              fontSize: "36px",
              // fontWeight: "700",
              lineHeight: "48px",
              letterSpacing: "0px",
              textAlign: "left",
              marginTop:"75px"
              
            }}>{t("Become a Student")}</h3>
            <p className="mb-5 register-des">
              {t(
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna Lorem ipsum dolor sit amet, consectetur adipiscing"
              )}
            </p>
            <button
              className="reg-bttn"
              onClick={handleFormUser}
            >
              {t("REGISTER")}
            </button>
          </div>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={6}
          style={{
            backgroundColor: "gray",
            backgroundImage: `url(${pic101})`,
            backgroundSize: "cover",
            color: "white",
            position:"relative",
            display:"flex",
            flexDirection:"column",
            justifyContent:"center",
            alignItems:"center",
            padding:"0px",
            zIndex:"111"
          }}
          className="footer-reg-shdow"
        >
          <div
           style={{ width: "60%", paddingBottom: "60px",}}>
            <h5
               style={{
                fontFamily: "Metropolis",
                fontSize: "16px",
                // fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "5px",
                textAlign: "left",
                marginTop:"25px"
              }}
            >
              {t("START SELLING COURSES")}
            </h5>
            <h3 style={{
              fontFamily: "Metropolis",
              fontSize: "36px",
              // fontWeight: "700",
              lineHeight: "48px",
              letterSpacing: "0px",
              textAlign: "left",
              marginTop:"75px"
              
            }}>{t("Become a Partner")}</h3>
            <p className="mb-5 register-des">
              {t(
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  dolore magna Lorem ipsum dolor sit amet, consectetur adipiscing"
              )}
            </p>
            <button
              className="reg-bttn"
              onClick={handleFormFirm}
            >
              {t("REGISTER")}
            </button>
          </div>
        </Col>
      </Row>

    </div>
  );
};

export default Register;
