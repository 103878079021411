import React, { useState, useRef, useEffect, useContext } from "react";
import { Row, Col, Button, Card } from "react-bootstrap";
// import image from '../../assets/images/students-walk-downstairsdd-with-books-library.png';
import {
  FiCheckCircle,
  FiStar,
  FiShoppingCart,
  FiCalendar,
  FiInfo,
  FiClock,
} from "react-icons/fi";
import { FaFire } from "react-icons/fa";
// import {
//   categoryTag,
//   subCertifiedTag,
//   subNewTag,
//   titleTag,
//   subTopRatedTag,
// } from '../../assets/customcss/CustomCss';
import { useNavigate } from "react-router-dom";
import "./CourseListCard.css";
import PaymentsInfo from "../courselist/PaymentsInfo";
import useClickOutside from "../courselist/useClickOutside";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../LanguageContext";
import dataNotFoundImage from "../../assets/images/No data-cuate.png";
import shopping from "../../assets/icon/shopping-preview.png";
import newtag from "../../assets/icon/new.png";
import certified from "../../assets/icon/certified.png";
import topreated from "../../assets/icon/start (1).png";
import StartingIcon from "../../assets/icon/detailsicon1.png";
import Sdule from "../../assets/icon/schedule.png";
import AboutIcon from "../../assets/icon/aboutbtn.png";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import { useLocalStore } from "../../store";
import Payl8rCalculator from "../Payl8rForMap";
const CourseListCard = (props) => {
  const navigate = useNavigate();
  const { data } = props;
  const { t } = useTranslation();
  const languageData = useContext(LanguageContext);
  const { storeData } = useLocalStore();
  const [togglePayInfo, setTogglePayInfo] = useState([]);
  const [priceRef, setPriceRef] = useState();
  const paymentsInfoRef = useRef(null); //creating ref for the PaymentsInfo
  const calculatePriceWithTax = (price) => {
    return price ? (price * 1.06).toFixed(2) : "0.00";
  };
  const handleTogglePayInfo = (index, price) => {
    const updatedToggles = [...togglePayInfo];
    updatedToggles[index] = !updatedToggles[index];
    setTogglePayInfo(updatedToggles);
    setPriceRef(calculatePriceWithTax(price));
  };
  useClickOutside(paymentsInfoRef, () => {
    setTogglePayInfo([]);
  });
  const capitalizeFirstLetter = (text) => {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth > 388,);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth > 388);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {data?.length == 0 ? (
        <div>
          <img
            style={{ width: "50%", alignItems: "center" }}
            src={dataNotFoundImage}
            alt="Data not found"
          />
          <h4 style={{ marginLeft: "40%" }}>{t("Data not found")}</h4>
        </div>
      ) : (
        data?.map((course, index) => (
          <Card
            key={index}
            className="mt-3 cl-course-card"
            style={{ border: "none", borderRadius: "16px" }}
          >
            <Row className="g-1">
              {/* <Col xs={12} md={4}>
                <div className="position-relative ">
                  <Card.Img
                    className="cl-card-image"
                    src={course.course_image}
                    alt="Card image"
                  />
                  <div className="top-rated-box-list">
                    {course.is_new && (
                      <p
                        className="cl-subNewTag"
                        variant="danger"
                        
                      >
                       
                        <img src={newtag} alt="new" className="new-tag" />
                        {"  "}
                        {t("In-house")}
                      </p>
                    )}
                    {course.is_certified && (
                      <p
                        className="cl-subCertifiedTag"
                        variant="success"
                       
                      >
                        
                        <img src={certified} alt="new" className="new-tag" />
                        {"  "} {t("Online")}
                      </p>
                    )}
                    {course.is_top_rated && (
                      <p
                        className="cl-subTopRatedTag"
                        variant="warning"
                       
                      >
                       
                        <img src={topreated} alt="new" className="new-tag" />
                        {"  "}
                        {t("Virtual Classroom")}
                      </p>
                    )}
                  </div>
                  <div>
                    <h5
                      className="position-absolute p-3 mb-5 cl-titleTag1"
                      variant="danger"
                      
                    >
                      {course.title_en}
                    </h5>
                  </div>
                </div>
              </Col> */}
              <Col xs={12} md={12} className="my-0">
                <Card.Body className="cl-card-body">
                  <div className="card-body-grid pb-3">
                    <div
                      className="hide-scrollbar"
                      style={{
                        width: "60%",
                        overflowX: "scroll",
                        display: "flex",
                      }}
                    >
                      {course?.firm_course_categories &&
                        course?.firm_course_categories.map((item, index) => {
                          return (
                            <span
                              key={index}
                              className="courselist-categoryTag"
                            >
                              {item.course_categories[0]?.lang_en}
                            </span>
                          );
                        })}
                      {/* <span className="courselist-categoryTag">
                      {course?.course_category?.lang_en}
                    </span> */}
                      {/* <span className="category-tag cl-categoryTag" >
                    {course?.trades_category?.lang_en}
                  </span> */}
                      {course?.keyword &&
                        (course?.keyword || "")
                          .split(",")
                          .map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="courselist-categoryTag"
                              >
                                {item.trim()}
                              </div>
                            );
                          })}

                      {/* <span className="courselist-categoryTag"> */}
                      {/* {course?.keyword} */}
                      {/* <FiInfo /> */}
                      {/* </span> */}
                    </div>

                    <div className="company-card-name">
                      {course?.user_account?.firmName}
                    </div>
                  </div>
                  {/* <Payl8rCalculator price={course?.total_price} /> */}
                  <div>
                    <h4 className="cl-card-short-desc desc-short ">
                      {" "}
                      {/* {course?.title_en} */}
                      {capitalizeFirstLetter(course?.title_en)}
                    </h4>
                  </div>
                  <div className="d-flex align-items-center mt-3 cl-c-schedule row">
                    <div className="cl-course-schedule col-lg-5 col-md-6 col-xs-12">
                      <span className="me-2">
                        {/* <FiCalendar style={{ color: '#09B970' }} /> */}
                        <img
                          src={StartingIcon}
                          alt="new"
                          className="new-tag1"
                        />
                      </span>
                      {t("Starting")}:&nbsp;&nbsp;&nbsp;&nbsp;
                      <strong>{course?.course_locations && course?.course_locations[0]?.date}</strong>
                    </div>
                    <div className=" cl-course-schedule col-lg-6 col-md-6 col-xs-12">
                      <span className="me-2">
                        {/* <FiClock style={{ color: '#09B970' }} /> */}
                        <img src={Sdule} alt="new" className="new-tag1" />
                      </span>
                      {t("Schedule")}:&nbsp;&nbsp;&nbsp;&nbsp;
                      <strong>
                        {course?.course_locations && course?.course_locations?.map((es) => (es?.schedule_type)?.charAt(0)?.toUpperCase() + es?.schedule_type?.slice(1))?.join(",")}</strong>
                      {/* <strong>
                      {course?.course_locations && course?.course_locations?.map((es)=>es.schedule_type).join(",")}</strong> */}
                    </div>
                  </div>
                  <div className=" cl-course-schedule col-lg-12 col-md-12 col-xs-12">
                    <span className="me-2">
                      {/* <FiCalendar style={{ color: "#09B970" }} /> */}
                      <VideoCallIcon
                        style={{ color: "#7bdab2", width: "25px" }}
                      />
                    </span>
                    <span style={{ fontSize: "14px" }}>{t("Type") + " :"}</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>
                      {course?.course_type == "online" && "Online"}
                      {course?.course_type == "online,vClass" &&
                        "Online,Video Class"}
                      {course?.course_type == "inHouse,online,vClass" &&
                        "In-House, Online, Video Class"}
                    </strong>
                  </div>
                  <Row>
                    <Col xs={12} md={5}>
                      <div className="d-flex align-items-center mt-4 cl-course-payment">
                        <h4 style={{ color: "#09B970" }}>
                          £{calculatePriceWithTax(course?.total_price)}
                        </h4>
                        <span className="ms-3">{t("Payment info")}</span>
                        <span className="ms-2">
                          <button
                            className="btn"
                            onClick={() =>
                              handleTogglePayInfo(index, course.total_price)
                            }
                          >
                            <img
                              src={AboutIcon}
                              alt="new"
                              className="new-tag2"
                            />
                          </button>
                          {/* {togglePayInfo[index] && (
                            <div
                              className="payments-info"
                              ref={paymentsInfoRef}
                            >
                              <PaymentsInfo props={course} />
                            </div>
                          )} */}
                        </span>
                      </div>
                    </Col>
                    <Col xs={12} md={7} className="text-end ">
                      <div className="d-flex forMobileDiv align-items-center justify-content-md-end mt-4 forMobileDiv">
                        <div className="me-2 w-100">
                          <Button
                            className="w-100 btn-list1 cl-more-info"
                            variant="info"
                            onClick={() => {
                              navigate(`/course-details?course=${course.id}`, {
                                state: {
                                  coursesData: course,
                                },
                              });
                              window.scrollTo(0, 0);
                            }}
                          >
                            {/* <FiInfo className="me-2" /> */}
                            {t("More Info")}
                          </Button>
                        </div>
                        <div className="w-100">
                          <Button
                            className="w-100 btn-list cl-add-cart"
                            variant="success"
                            onClick={() => {
                              if (storeData) {
                                navigate(`/payment/`, {
                                  state: {
                                    courseDetail: course,
                                    // selectedCourse:item,
                                  },
                                });
                              } else {
                                navigate("/register-user");
                              }
                              console.log("courssss detail", course);
                            }}
                          >
                            {/* <FiShoppingCart className="me-2" /> */}
                            <img
                              src={shopping}
                              alt="shopping"
                              className="shop-logo"
                            />
                            {t("Buy Now")}
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {isMobile && (
                    togglePayInfo[index] ? (
                      <span ref={paymentsInfoRef}>
                        <Payl8rCalculator price={priceRef} />
                      </span>
                    ) : null
                  )}

                </Card.Body>

              </Col>
              {!isMobile && (
                togglePayInfo[index] ? (
                  <span style={{ marginTop: "-35px", marginLeft: "20px" }} ref={paymentsInfoRef}>
                    <Payl8rCalculator price={priceRef} />
                  </span>
                ) : null
              )}
            </Row>


          </Card>
        ))
      )}
    </div>
  );
};

export default CourseListCard;
