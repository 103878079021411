// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Style for the container div */
.e404div{
    text-align: center;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
  }
  
  /* Style for the heading */
  .eh1 {
    font-size: 4em;
    /* font-weight: 900; */
    color: #333;
  }
  
  /* Style for the subheading */
  .eh5 {
    font-size: 1.5em;
    /* font-weight: 700; */
    color: #777;
    margin-top: 10px;
  }
  
  /* Style for the button */
  .ebtn {
    padding: 10px 20px;
    font-size: 1em;
    background-color: rgb(4, 201, 119);
    color: #fff;
    border: none;
    cursor: pointer;
    margin-top: 50px;
  }
  
  /* Hover effect for the button */
  .ebtn:hover {
    background-color: rgb(0, 136, 79);
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/css/PageNotFound.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;IACI,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;EACjB;;EAEA,0BAA0B;EAC1B;IACE,cAAc;IACd,sBAAsB;IACtB,WAAW;EACb;;EAEA,6BAA6B;EAC7B;IACE,gBAAgB;IAChB,sBAAsB;IACtB,WAAW;IACX,gBAAgB;EAClB;;EAEA,yBAAyB;EACzB;IACE,kBAAkB;IAClB,cAAc;IACd,kCAAkC;IAClC,WAAW;IACX,YAAY;IACZ,eAAe;IACf,gBAAgB;EAClB;;EAEA,gCAAgC;EAChC;IACE,iCAAiC;EACnC","sourcesContent":["/* Style for the container div */\n.e404div{\n    text-align: center;\n    margin-top: 50px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    margin-top: 10%;\n  }\n  \n  /* Style for the heading */\n  .eh1 {\n    font-size: 4em;\n    /* font-weight: 900; */\n    color: #333;\n  }\n  \n  /* Style for the subheading */\n  .eh5 {\n    font-size: 1.5em;\n    /* font-weight: 700; */\n    color: #777;\n    margin-top: 10px;\n  }\n  \n  /* Style for the button */\n  .ebtn {\n    padding: 10px 20px;\n    font-size: 1em;\n    background-color: rgb(4, 201, 119);\n    color: #fff;\n    border: none;\n    cursor: pointer;\n    margin-top: 50px;\n  }\n  \n  /* Hover effect for the button */\n  .ebtn:hover {\n    background-color: rgb(0, 136, 79);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
