// src/store/userStore.js
import { create } from 'zustand';
import { fetchTestimonial } from '../api/testmonialApi';

const useTestimonialStore = create((set) => ({
  testimonials: [],
  fetchTestimonial: async () => {
    try {
      const testimonials = await fetchTestimonial();
      set({ testimonials });
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  },
}));

export default useTestimonialStore;
