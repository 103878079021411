import React from "react";
import "../../assets/css/home/FAQs.css";
import { useTranslation } from "react-i18next";
import Accordion from 'react-bootstrap/Accordion';

const FAQs = () => {
    const { t } = useTranslation();
    return (
        <>
            <div style={{ backgroundColor: "#04c977" }}>
                <div className="container " style={{ paddingTop: "20px", paddingBottom: '20px', }}>
                    <h6
                        style={{
                            color: "#39128C",
                            fontFamily: "Metropolis",
                            fontSize: "16px",
                            fontStyle: "normal",
                            lineHeight: "24px",
                            letterSpacing: "5px",
                        }}
                    >
                        FAQ
                    </h6>

                    <div className="faqs-width">
                        <Accordion defaultActiveKey="0">

                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Do I need a Bank Account to use Payl8r? </Accordion.Header>
                                <Accordion.Body>
                                    Yes. You must have a UK bank account capable of accepting Direct Debits and you must have online banking.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Will I qualify to use Payl8r ?</Accordion.Header>
                                <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2">
                                <Accordion.Header>How do I refund my goods or cancel my purchase / service?</Accordion.Header>
                                <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Why was I declined by Payl8r?</Accordion.Header>
                                <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Can I contact the training centre before attending my course?</Accordion.Header>
                                <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="5">
                                <Accordion.Header>What if my course will be cancelled by a training centre?</Accordion.Header>
                                <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                </Accordion.Body>
                            </Accordion.Item>

                        </Accordion>
                    </div>

                </div>
            </div>

        </>
    );
};

export default FAQs;
