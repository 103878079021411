import React from "react";
import { CiCreditCard1, CiTrophy } from "react-icons/ci";
import { MdSupportAgent } from "react-icons/md";
import { FaRegBuilding } from "react-icons/fa";
import "../../assets/css/home/WhyUs.css";
import { useTranslation } from "react-i18next";
import icon1 from "../../assets/icon/iconhd1-removebg-preview.png"
import icon2 from "../../assets/icon/iconhd2-removebg-preview.png"
import icon3 from "../../assets/icon/iconhd3-removebg-preview.png"
import icon4 from "../../assets/icon/iconhd4-removebg-preview.png"
const WhyUs = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="container " style={{ paddingTop: "50px", paddingBottom: '50px' }}>
        <h6
          style={{
            color: "#39128C",
            fontFamily: "Metropolis",
            fontSize: "16px",
            fontStyle: "normal",
            lineHeight: "24px",
            // fontWeight: 700,
            letterSpacing: "5px",
          }}
        >
          {t("Why Us")}
        </h6>
        <div>
          <h3
            style={{
              color: "black",
              fontFamily: "Metropolis",
              fontStyle: "normal",
              fontSize: "32px",
              // fontWeight: "700",
              lineHeight: "48px",
              letterSpacing: "0px",
              textAlign: "left",
              color: " #1B1720",

            }}
            className="mt-4 mb-2"
          >
            {t("One platform for all Constructing Courses")}
          </h3>
        </div>

        <div class="row">
          <div class="col-md-3 ">
            <div className="why-us-content">
              <div className="why-number">
                <p>{t("01")}</p>
              </div>
              <div className="d-flex why-us-icons">
                {/* <CiCreditCard1 style={{ color: "#0b9f61" }} size={40} /> */}
                <img src={icon4} alt="Description of the image" style={{ width: "80px", height: "80px", borderRadius: "20px" }} />
              </div>
              <p className="why-title">{t("Payment flexibility")} </p>
              <p className="sub-text1">{t("Lorem ipsum dolor sit amet, consectetur adipiscing elit,")}</p>
            </div>
          </div>
          <div class="col-md-3">
            <div className="why-us-content">
              <div className="why-number">
                <p>{t("02")}</p>
              </div>
              <div className="d-flex why-us-icons">
                {/* <MdSupportAgent style={{ color: "#0b9f61" }} size={40} /> */}
                <img src={icon3} alt="Description of the image" style={{ width: "80px", height: "80px", borderRadius: "20px" }} />
              </div>
              <p className="why-title">{t("24/7 support")}</p>
              <p className="sub-text1">{t("Lorem ipsum dolor sit amet, consectetur adipiscing elit,")}</p>
            </div>
          </div>
          <div class="col-md-3">
            <div className="why-us-content">
              <div className="why-number">
                <p>{t("03")}</p>
              </div>
              <div className="d-flex why-us-icons">
                {/* <FaRegBuilding style={{ color: "#0b9f61" }} size={35} /> */}
                <img src={icon2} alt="Description of the image" style={{ width: "80px", height: "80px", borderRadius: "20px" }} />
              </div>
              <p className="why-title">{t("Employment Guarantees")}</p>
              <p className="sub-text1">{t("Lorem ipsum dolor sit amet, consectetur adipiscing elit,")}</p>
            </div>
          </div>

          <div class="col-md-3">
            <div className="why-us-content">
              <div className="why-number">
                <p>{t("04")}</p>
              </div>
              <div className="d-flex why-us-icons">
                {/* <CiTrophy
                  style={{ color: "#0b9f61", fontWeight: "700" }}
                  size={40}
                /> */}
                <img src={icon1} alt="Description of the image" style={{ width: "80px", height: "80px", borderRadius: "20px" }} />
              </div>
              <p className="why-title">{t("Top companies speakers")}</p>
              <p className="sub-text1">{t("Lorem ipsum dolor sit amet, consectetur adipiscing elit,")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyUs;
