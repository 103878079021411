import { create } from "zustand";
import { fetchTokenStorage } from "../api/gittokenApi";

const useTokenStore = create((set) => ({
  tokenData: "",
  fetchToken: async () => {
    try {
      const storeData = await fetchTokenStorage();
      set({ storeData });
    } catch (error) {
      console.error("Error fetching local storage: ", error);
    }
  },
}));

export default useTokenStore;
