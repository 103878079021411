import React, { useEffect, useRef } from 'react';

const Payl8rCalculator = ({ price }) => {
  const calculatorRef = useRef(null);
  const priceRef = useRef(null);

  useEffect(() => {
    const scriptId = 'pl-calculator-light-app-script';

    const initializeCalculator = () => {
      const priceElement = priceRef.current;
      const calculatorElement = calculatorRef.current;

      if (!priceElement || !calculatorElement) {
        console.error('Price or calculator elements are not available');
        return;
      }

      document.plCalcPrice = {
        Price: price,
        rerender: (newPrice) => {
          console.log('Rerender with new price:', newPrice);
        },
      };
      document.plCalcSelector = '.price';

      const loadScript = () => {
        if (!document.getElementById(scriptId)) {
          const script = document.createElement('script');
          script.src = 'https://assets.payl8r.com/js/pl-calculator-light-app.js';
          script.id = scriptId;
          script.dataset.theme = 'spread';
          script.onload = payl8rCalcPrice;
          script.onerror = (e) => {
            console.error("Error loading Payl8r script:", e);
          };
          document.body.appendChild(script);
        } else {
          payl8rCalcPrice();
        }
      };

      const payl8rCalcPrice = () => {
        try {
          const get_price_span = () => document.querySelector(document.plCalcSelector);

          const get_price = () => {
            const priceSpan = get_price_span();
            if (!priceSpan) {
              console.error('Price span element not found.');
              return;
            }
            return parseFloat(priceSpan.innerHTML.replace(/[^0-9.]/g, ''));
          };

          const newPrice = get_price();
          if (newPrice !== undefined) {
            document.plCalcPrice.Price = newPrice;
            document.plCalcPrice.rerender(newPrice);
          }

          const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

          const docPrice = document.querySelector(document.plCalcSelector);
          if (!docPrice) {
            console.error('Price span element not found for mutation observer.');
            return;
          }

          const observer = new MutationObserver((mutations) => {
            mutations.forEach(() => {
              payl8rCalcPrice();
            });
          });

          observer.observe(docPrice, {
            attributes: true,
            childList: true,
            characterData: true,
            subtree: true,
          });
        } catch (error) {
          console.error('Error in payl8rCalcPrice:', error);
        }
      };

      const payl8rTimed = () => {
        if (document.readyState === 'complete') {
          payl8rCalcPrice();
        } else {
          setTimeout(payl8rTimed, 10);
        }
      };

      setTimeout(payl8rTimed, 10);

      loadScript();
    };

    if (priceRef.current && calculatorRef.current) {
      initializeCalculator();
    }

    return () => {
      const scriptElement = document.getElementById(scriptId);
      if (scriptElement) {
        scriptElement.remove();
      }
    };
  }, [price]);

  return (
    <>
      <div ref={priceRef} className="price">£{price}</div>
      <div ref={calculatorRef} id="pl-calculator-light-app"></div>
    </>
  );
};

export default Payl8rCalculator;
