import React from "react";
import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Formik, Field, ErrorMessage, useFormik } from "formik";
import {
  Container,
  Row,
  Form,
  Col,
  Button,
  Modal,
  Image,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import validationSchema from "../components/regForm/validationSchema.js";
import "../assets/css/Register.css";
import logo from "../assets/images/Layer 1 (1).png";
import avatar from "../assets/images/avatar.png";
import firmIcon from "../assets/images/firm-icon.png";
import leftArrow from "../assets/images/arrow-to-left.svg";
import rightArrow from "../assets/images/arrow-to-right.svg";
import { RiAlignJustify } from "react-icons/ri";
// import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../store";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { countries } from "../contry";
import FirmForm from "./FirmForm.js";
import SelectSearch from "react-select-search";
import "../style.css";
/* import { pointer } from "@testing-library/user-event/dist/types/pointer/index.js"; */
const RegisterModal = () => {
  const navigate = useNavigate();
  const { users, fetchUsers, addUser, loginUser } = useUserStore();
  // Initial form values
  const initialValues = {
    email_id: "",
    first_name: "User",
    last_name: null,
    password: "",
    // confirm_password: '',
    // post_code: "",
    pesel_number: "",
    age: null,
    account_type: "user",
    country_code: "+44",
    mobile: null,
  };

  const [dialCode, setDialCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loginState, setLoginState] = useState(false);

  const countryOptions = countries.map((country) => ({
    name: `${country.name} ${country.dial_code} ${country.emoji}`, // You can include emoji if desired
    value: country.code, // Use a unique identifier as the value
  }));
  const handleChange = (selectedValue) => {
    function findDialCodeByCode(codeToFind) {
      const foundObject = countries.find((item) => item.code === codeToFind);
      if (foundObject) {
        return foundObject.dial_code;
      } else {
        return null; // Return null if no matching object is found
      }
    }
    setDialCode(findDialCodeByCode(selectedValue));
    // You can update your state or perform any other action here
  };

  const secret_key = "Fzgd6ctch=PW=*=n(u]);@-(NfW@fZ";
  const encryptData = (data) => {
    try {
      return CryptoJS.AES.encrypt(data, secret_key).toString();
    } catch (e) {
      return "";
    }
  };

  // Handle form submission
  const onSubmit = async (values) => {
    setIsLoading(true);
    const formData = {
      address: [],
      email_id: values.email_id,
      first_name: values.first_name,
      last_name: values.last_name,
      password: values.password,
      // postal_code: values.post_code,
      // pesel_number: values.pesel_number,
      // age: values.age,
      account_type: "user",
      country_code: "+44",
      // mobile: values.mobile.toString(),
      status: true,
    };

    const formDataLogin = {
      email_id: values.email_id,
      password: values.password,
    };
    if (!loginState) {
      const data = await addUser(formData);
      try {
        if (data.httpCode == 200) {
          Cookies.set("UUID", data.data.token, {
            domain: ".skillmo.co.uk",
            // domain: ".localhost",
          });
          Cookies.set("TOKEN", data.data.id, {
            domain: ".skillmo.co.uk",
            // domain: ".localhost",
          });
          Cookies.set("NAME", data.data.name, {
            domain: ".skillmo.co.uk",
            // domain: ".localhost",
          });
          Cookies.set("ACCOUNT", data.data.acc_type, {
            domain: ".skillmo.co.uk",
            // domain: ".localhost",
          });

          if (data.data.acc_type == "user") {
            toast.success("Registered Successfully!", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setTimeout(() => {
              navigate(-1);
              setIsLoading(false);
            }, 2000);
          }
        } else {
          toast.error("Email Already Registered !", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
        }
      } catch (error) {
        console.error("Error during login:", error);
        toast.error("An unexpected error occurred!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      const data = await loginUser(formDataLogin);
      try {
        if (data.httpCode == 200) {
          Cookies.set("UUID", data.data.token, {
            domain: ".skillmo.co.uk",
            // domain: ".localhost",
          });
          Cookies.set("TOKEN", data.data.id, {
            domain: ".skillmo.co.uk",
            // domain: ".localhost",
          });
          Cookies.set("NAME", data.data.name, {
            domain: ".skillmo.co.uk",
            // domain: ".localhost",
          });
          Cookies.set("ACCOUNT", data.data.acc_type, {
            domain: ".skillmo.co.uk",
            // domain: ".localhost",
          });

          if (data.data.acc_type === "user") {
            toast.success("Logged In Successfully!", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setTimeout(() => {
              navigate(-1);
              setIsLoading(false);
            }, 2000);
          } else if (data.data.acc_type === "firm") {
            toast.success("Logged In Successfully!", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setTimeout(() => {
              window.location.href = "https://test-admin.skillmo.co.uk/";
              setIsLoading(false);
            }, 2000);
          } else {
            toast.success("Logged In Successfully!", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setTimeout(() => {
              window.location.href = "https://test-admin.skillmo.co.uk/";
              setIsLoading(false);
            }, 2000);
          }
        } else {
          toast.error(
            "Email and password do not match or You are not approved.",
            {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
        }
      } catch (error) {
        console.error("Error during login:", error);
      }
    }
  };

  // Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const reviewsPerPage = 1;

  const customerReviews = [
    {
      name: "Customer Name 1",
      review:
        "Customer Review 1. Customer Review 1. Customer Review 1. Customer Review 1.",
    },
    {
      name: "Customer Name 2",
      review:
        "Customer Review 2. Customer Review 2. Customer Review 2. Customer Review 2.",
    },
    {
      name: "Customer Name 2",
      review:
        "Customer Review 2. Customer Review 2. Customer Review 2. Customer Review 2.",
    },
    {
      name: "Customer Name 2",
      review:
        "Customer Review 2. Customer Review 2. Customer Review 2. Customer Review 2.",
    },
    // Add more reviews if you have them
  ];

  const getCurrentReviews = () => {
    const startIndex = currentPage * reviewsPerPage;
    return customerReviews.slice(startIndex, startIndex + reviewsPerPage);
  };

  const validatePassword = (values) => {
    const errors = {};

    if (values.password !== values.confirm_password) {
      errors.confirm_password = "Passwords do not match";
    }

    return errors;
  };

  const [activeForm, setForm] = useState("user");
  const handleFormUser = () => {
    setForm("user");
  };
  const handleFormFirm = () => {
    setForm("firm");
  };

  const [searchTerm, setSearchTerm] = useState("");
  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <ToastContainer
        position="top-center"
        theme="colored"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* <Container className="icons-container">
        <Row>
          <Col className="user-icon-col">
            <div
              className={`user-icon ${
                activeForm === "user" ? "active-form" : ""
              }`}
            >
              <img
                src={avatar}
                alt="User"
                className={`user-icon-img cursor-pointer ${
                  activeForm === "user" ? "active-form" : ""
                }`}
                onClick={handleFormUser}
              />
              <div
                className="icon-heading cursor-pointer"
                onClick={handleFormUser}
              >
                User
              </div>
            </div>
          </Col>
          <Col className="firm-icon-col">
            <div
              className={`firm-icon cursor-pointer ${
                activeForm === "user" ? "" : "active-form"
              }`}
            >
              <img
                src={firmIcon}
                alt="User"
                className={`firm-icon-img ${
                  activeForm === "user" ? "" : "active-form"
                }`}
                onClick={handleFormFirm}
              />
              <div
                className="icon-heading cursor-pointer"
                onClick={handleFormFirm}
              >
                Firm
              </div>
            </div>
          </Col>
        </Row>
      </Container> */}
      <Container className="reg-form-container">
        <Row
          className={`p-3 ${
            activeForm === "user" ? "design-right" : " design-left"
          }`}
        >
          {activeForm === "user" ? (
            <Col
              md={8}
              className="p-4 design-element reg-form-left"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              {!loginState ? (
                <div className="reg-form-header" style={{ marginLeft: "0px" }}>
                  Sign Up
                </div>
              ) : (
                <div className="reg-form-header" style={{ marginLeft: "0px" }}>
                  Login
                </div>
              )}

              <div className="">
                <Formik
                  initialValues={initialValues}
                  // validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  // validate={validatePassword}
                >
                  {({ isSubmitting, handleSubmit }) => (
                    <Form
                      onSubmit={handleSubmit}
                      className="d-flex flex-column form-struct"
                    >
                      <Row>
                        {!loginState && (
                          <>
                            {/* <Col lg={6} md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label htmlFor="first_name">
                                  First Name
                                </Form.Label>
                                <Field
                                  type="text"
                                  name="first_name"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="first_name"
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label htmlFor="last_name">
                                  Last Name
                                </Form.Label>
                                <Field
                                  type="text"
                                  name="last_name"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="last_name"
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </Col> */}
                          </>
                        )}
                        <Col md={12}>
                          <Form.Group className="mb-3 ">
                            <Form.Label htmlFor="email_id">Email</Form.Label>
                            <Field
                              type="email"
                              name="email_id"
                              className="form-control"
                              required
                            />
                            <ErrorMessage
                              name="email_id"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>

                        <Col md={12}>
                          <Form.Group className="mb-3 ">
                            <Form.Label htmlFor="password">Password</Form.Label>
                            <Field
                              type="password"
                              name="password"
                              className="form-control"
                              required
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>

                        <div onClick={() => setLoginState(!loginState)}>
                          {loginState
                            ? "Not have an account?"
                            : "Already have an account?"}{" "}
                          <span style={{ color: "blue", cursor: "pointer" }}>
                            {loginState ? "Register" : "Login"}
                          </span>
                        </div>

                        <Col md={12}>
                          {isLoading ? (
                            <Button
                              className="reset-link-btn mt-2"
                              /* disabled={isSubmitting} */
                              // disabled={isSubmitting}

                              style={{
                                backgroundColor: "#10D382",
                                color: "#fafafa",
                                // fontWeight: 700,
                                fontSize: "16px",
                                padding: "15px 25px",
                                border: "none",
                                borderRadius: "8px",
                              }}
                            >
                              {"Loading..."}
                              {/* Register */}
                            </Button>
                          ) : (
                            <Button
                              className="reset-link-btn mt-2"
                              type="submit" /* disabled={isSubmitting} */
                              // disabled={isSubmitting}
                              onClick={() => handleSubmit}
                              style={{
                                backgroundColor: "#10D382",
                                color: "#fafafa",
                                // fontWeight: 700,
                                fontSize: "16px",
                                padding: "15px 25px",
                                border: "none",
                                borderRadius: "8px",
                              }}
                            >
                              {loginState ? "Login" : "Register"}
                              {/* Register */}
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
          ) : (
            <Col
              md={8}
              className="p-4 design-element  reg-form-right"
              style={{ opacity: activeForm === "user" ? 0 : 1 }}
            >
              <FirmForm></FirmForm>
            </Col>
          )}
          <Col md={4} className="p-2 design-element  reg-form-overlay">
            <Row>
              <Col md={12}>
                <div
                  className={`reg-right-logo ${
                    activeForm === "user" ? "reg-design-logo" : ""
                  }`}
                >
                  <img src={logo} alt="logo" />
                </div>
              </Col>
              <Col md={12}>
                <div className="reg-right-text">
                  <h3>Start developing your skills with us.</h3>
                  <p>
                    Learn from the top trainer and give yourself More Text Here
                    More Text Here More Text Here More Text Here More Text Here
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <div
            className="reset-text"
            style={{
              marginTop: "-15px",
              marginBottom: "50px",
              cursor: "pointer",
            }}
            onClick={() => navigate(-1)}
          >
            <svg
              fill="#000000"
              width="25px"
              height="15px"
              viewBox="0 0 200 250"
              data-name="Layer 1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title />
              <path d="M160,89.75H56l53-53a9.67,9.67,0,0,0,0-14,9.67,9.67,0,0,0-14,0l-56,56a30.18,30.18,0,0,0-8.5,18.5c0,1-.5,1.5-.5,2.5a6.34,6.34,0,0,0,.5,3,31.47,31.47,0,0,0,8.5,18.5l56,56a9.9,9.9,0,0,0,14-14l-52.5-53.5H160a10,10,0,0,0,0-20Z" />
            </svg>
            Back to page
          </div>
        </Row>
      </Container>
    </>
  );
};

export default RegisterModal;
