import { useState, useEffect } from 'react';

function useLocalStorage(key, initialValue) {
  // Get the initial value from local storage or use the provided initialValue
  const storedValue = localStorage.getItem(key) || initialValue;

  // Create a state variable to hold the current value
  const [value, setValue] = useState(storedValue);

  // Update local storage whenever the value changes
  useEffect(() => {
    localStorage.setItem(key, value);
  }, [key, value]);

  return [value, setValue];
}

export default useLocalStorage;
