import { create } from "zustand";
import { addToCartAPI, getToCartAPI, deleteToCartAPI } from "../api/cart"; // Import your API functions

const useCartStoreApi = create((set) => ({
  cart: [],
  addToCart: async (token, data) => {
    try {
      const responseData = await addToCartAPI(token, data);
      set({ cart: responseData });
    } catch (error) {
      console.error("Error adding to cart", error);
    }
  },
  getToCart: async (token) => {
    try {
      const data = await getToCartAPI(token);
      set({ cart: data });
    } catch (error) {
      console.error("Error getting cart", error);
    }
  },
  deleteToCart: async (token, id) => {
    try {
      const data = await deleteToCartAPI(token, id);
      set({ cart: data });
    } catch (error) {
      console.error("Error deleting from cart", error);
    }
  },
}));

export default useCartStoreApi;
