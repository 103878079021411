import React,{useContext} from "react";
import { Card, Row, Col } from "react-bootstrap";
import './PaymentsInfo.css'
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../LanguageContext";
const PaymentsInfo = (props) =>{
    const { t } = useTranslation();

    /* const cardRef = useRef(null);

    useEffect(() =>{
        const handleClicksOutside=(event) => {
            if(cardRef.current && !cardRef.current.contains(event.target)){
                props.onClose();
            }
        }

        document.addEventListener("mousedown", handleClicksOutside); //Eventlistener to listen for clicks
        return ()=>{
            document.removeEventListener("mousedown", handleClicksOutside);
        };
    }, [props]); */

    // console.log("infoprops", props)
    return(
        <div className="payments-info-div">
            <Card className="payments-info-card " /* ref={cardRef} */>
                <div className='payments-info-card-div1'>
                  <h4 className="payments-info-heading">
                      {t("Payment Options")}
                  </h4>
                  <p className="payments-info-text">
                      {t("You can choose a convenient method of payment.")}
                  </p>
                  <Row>
                      <Col>
                        <Row>
                            <Col className="payments-info-time">x3</Col>
                            <Col className="payments-info-price">£{Math.round((props.props.total_price * 1.06).toFixed(2)/3)}</Col>
                        </Row> 
                        <hr className='payments-info-hr'/>
                        <Row>
                            <Col className="payments-info-time">x6</Col>
                            <Col className="payments-info-price">£{Math.round((props.props.total_price * 1.06).toFixed(2)/6)}</Col>
                        </Row>
                        <hr className='payments-info-hr'/>
                        <Row>
                            <Col className="payments-info-time">x9</Col>
                            <Col className="payments-info-price">£{Math.round((props.props.total_price * 1.06).toFixed(2)/9)}</Col>
                        </Row>
                        <hr className='payments-info-hr'/>
                        <Row>
                            <Col className="payments-info-time">x12</Col>
                            <Col className="payments-info-price">£{Math.round((props.props.total_price * 1.06).toFixed(2)/12)}</Col>
                        </Row>
                      </Col>
                  </Row>
                </div>
                <div className='payments-info-fpsection'>
                  <Row /* className='payments-info-fpsection' */>
                      <Col className="payments-info-fptext">{t("Full Price")}</Col>
                      <Col className="payments-info-fp">${(props.props.total_price * 1.06).toFixed(2)}</Col>
                  </Row>
                </div>
            </Card>
        </div>
    );
}

export default PaymentsInfo;