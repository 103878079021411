import React from "react";
import { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import {
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
import "../assets/css/Footer.css";
import logo from "../assets/images/Layer 1 (1).png";
import payltr from "../assets/images/logo-white.svg";
import { useSubscriptionStore } from "../store/subscriptionStore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const userSubscription = useSubscriptionStore();
  const { t } = useTranslation();
  //Subscription handling
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const handleEmailChange = (emailid) => {
    setEmail(emailid.target.value.trim());
  };

  const handleSubscribe = async (email) => {
    try {
      if (!validateEmail(email)) {
        setIsValidEmail(false);
        return;
      } else {
        // Simulate a successful subscription
        await userSubscription.userSubscription({ email }); //calling the userSubscription Fucntion from the userSubscription
        // alert("Subscription successful!");
        // console.log("Submitted email:", email);
        toast.success("Subscription Successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setEmail(""); //reset email field
      }
    } catch (error) {
      console.error("Error Subscribing user", error);
    }
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        theme="colored"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <footer
        className="footer-main"
        style={{ backgroundColor: "#1B1720", color: "white" }}
      >
        <Container>
          <div>
            <Row>
              <Col xs={12} md={4} sm={12}>
                <ul style={listStyle}>
                  <li>
                    <img src={logo} alt="Logo" style={logoStyle} />
                  </li>
                  <li>
                    <p className="footer-des">
                      {t("Subscribe and get the latest news and offers.")}
                      {" No spam, just useful information."}
                    </p>
                  </li>
                  {/* <li>
                    <div className="mail-main">
                      <input
                        type="email"
                        className={`footer-mail ${isValidEmail ? "" : "is-invalid"
                          }`}
                        placeholder={t("Write your email")}
                        style={{ background: "transparent" }}
                        value={email}
                        onChange={handleEmailChange}
                      />
                      <button
                        className="footer-sub-btn"
                        type="button"
                        onClick={() => handleSubscribe(email)}
                        style={{
                        }}
                      >
                        {t("SUBSCRIBE")}
                      </button>

                    </div>
                  </li> */}
                  <Link to="/payltr">
                    <li style={{marginTop: "10px"}}>
                      <div style={{ display: "flex", alignItems: "end"}}>
                        <img src={payltr} width={80} alt="payltr" />
                        <span style={{ marginLeft: "3px", marginBottom: "3px" }}>EMI Calculator</span>
                      </div>
                    </li>
                  </Link>
                </ul>
              </Col>
              <Col xs={12} md={5} sm={12}>
                <div className="quick">
                  <ul>
                    <li>{t("Quick Links")}</li>
                    <Link to="/about">
                      <li className="green-bullet footer-text">
                        {t("About Us")}
                      </li>
                    </Link>
                    <Link to="/course-list">
                      <li className="green-bullet footer-text">
                        {t("Courses")}
                      </li>
                    </Link>
                    <Link to="/partners">
                      <li className="green-bullet footer-text">
                        {t("Partners")}
                      </li>
                    </Link>
                    <li className="green-bullet footer-text">
                      <a href="mailto:info@skillmo.co.uk">info@skillmo.co.uk</a>
                    </li>
                    {/* <li className="green-bullet footer-text">{t("info@skillmo.co.uk")}</li> */}
                  </ul>

                  <ul>
                    <li>{t("Other Links")}</li>
                    {/* <li className="green-bullet footer-text">{t("Our Mission")}</li> */}
                    <li className="green-bullet footer-text">
                      {" "}
                      <a href="https://blog.skillmo.co.uk/" target="blank">
                        {" "}
                        {t("Blog")}
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={12} md={3} sm={12}>
                <ul className="listStyle">
                  <li className="mb-3">{t("Follow Us")}</li>
                  <li style={socialIconsStyle}>
                    {/* <FaTwitter style={iconStyle} /> */}
                    <Link to="https://in.linkedin.com" target="_blank">
                      <FaLinkedinIn style={iconStyle} />
                    </Link>
                    <Link to="https://www.facebook.com" target="_blank">
                      <FaFacebookF style={iconStyle} />
                    </Link>
                    {/* <FaYoutube style={iconStyle} /> */}
                  </li>
                  <li style={partnerButtonStyle}>
                    <Link to="/register">
                      <button className="buttonStyle">
                        {t("BECAME A PARTNER")}
                      </button>
                    </Link>
                  </li>
                  {/* <a href="https://chatcloudserver.co.in/login"  target="_blank" style={{textDecoration: 'none'}}>
                <li className="green-bullet" style={{cursor: 'pointer'}}>
                    Firm Login
                </li>
              </a> */}
                </ul>
              </Col>
            </Row>

            <div className="footer-last">
              <div className="footer-space"></div>
              <div className="footer-last-content">
                <div className="copyright">
                  <p>Copyright 2023 | Skillmo - All rights reserved</p>
                </div>

                <div className="last-menu">
                  <p>Terms of use</p>
                  <p>Privacy policy</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </footer>
    </>
  );
};
const listStyle = {};

const logoStyle = {
  width: "100px",
  height: "30px",
  marginBottom: "10px",
};

const inputStyle = {
  padding: "8px",
  marginRight: "10px",
  borderRadius: "4px",
  border: "none",
};

const buttonStyle = {
  borderRadius: "10px",
  width: "210px",
  height: "52px",
  background: "#04C977",
  marginTop: "20px",
  color: "#FFFFFF",
  fontSize: "15px",
  // fontWeight: "700",
  border: "none",
};
const socialIconsStyle = {
  display: "flex",
  alignItems: "center",
};

const iconStyle = {
  color: "#04C977",
  marginRight: "10px",
  padding: "8px",
  backgroundColor: "#192929",
  borderRadius: "5px",
  height: "32px",
  width: "32px",
  // boxShadow: '0 0 5px lightgreen',
};

const partnerButtonStyle = {
  marginTop: "20px",
};

export default Footer;
