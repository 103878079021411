import React from "react";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Formik, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Form,
  Col,
  Button,
  Modal,
  Image,
} from "react-bootstrap";
import validationSchema from "../components/regForm/validationSchema";
import "../assets/css/login/ForgotPassword.css";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const initialValues = {
    email_id: "",
  };

  // Handle Login
  const onSubmit = (values) => {
    // console.log(':', values);
  };

  const homepage = () => {
    navigate("/login");
    // window.location.reload();
  };
  return (
    <Container className="forgot-password-container">
      <Row>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12}>
                  <h3 className="mb-4">Lost your Password?</h3>
                  <div className="reset-text">
                    Enter your email below and click on the reset button to
                    receive a password reset link
                  </div>
                </Col>
                <Col xs={12}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="email_id">Email</Form.Label>
                    <Field
                      type="email"
                      name="email_id"
                      className="form-control"
                      required
                    />
                    <ErrorMessage
                      name="email_id"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Button
                    className="reset-link-btn mt-2"
                    type="submit" /* disabled={isSubmitting} */
                    style={{
                      backgroundColor: "#10D382",
                      color: "#fafafa",
                      fontWeight: 700,
                      fontSize: "16px",
                      padding: "15px 25px",
                      border: "none",
                      borderRadius: "8px",
                    }}
                  >
                    Send Reset Link
                  </Button>
                  <div
                    className="reset-text"
                    style={{
                      marginTop: "25px",
                      cursor: "pointer",
                    }}
                    onClick={() => homepage()}
                  >
                    <svg
                      fill="#000000"
                      width="25px"
                      height="15px"
                      viewBox="0 0 200 250"
                      data-name="Layer 1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title />
                      <path d="M160,89.75H56l53-53a9.67,9.67,0,0,0,0-14,9.67,9.67,0,0,0-14,0l-56,56a30.18,30.18,0,0,0-8.5,18.5c0,1-.5,1.5-.5,2.5a6.34,6.34,0,0,0,.5,3,31.47,31.47,0,0,0,8.5,18.5l56,56a9.9,9.9,0,0,0,14-14l-52.5-53.5H160a10,10,0,0,0,0-20Z" />
                    </svg>
                    Back to login page
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
