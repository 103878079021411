import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en.json';
import po from './locales/po.json';
import ro from './locales/ro.json';
import { LanguageProvider } from './components/LanguageContext';
import { CookieBanner } from '@keepist/react-gdpr-cookie-banner';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: { translation: en },
      pl: { translation: po },
      ro: { translation: ro }
    },
    fallbackLng: 'en',
    detection: {
      order: ['navigator']
    },
    interpolation: {
      escapeValue: false
    }
  });


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LanguageProvider>
      <App />
      <CookieBanner
        message="This website uses cookies. By continuing to use this site, you accept our use of cookies."
        styles={{
          dialog: { 
            position: "fixed", 
            bottom: "0px", 
            left: "0px", 
            right: "0px", 
            zIndex: "10000000", 
            backgroundColor: "#d0e6fe", 
            padding :"20px"
          },

          optionLabel:{
            color: "black",
            marginLeft :"20px"
          }
          
        }}
        onAcceptPreferences={() => {
          // Load preferences trackers here
        }}
        onAcceptStatistics={() => {
          // Load statistics trackers here
        }}
        onAcceptMarketing={() => {
          // Load marketing trackers here
        }}
      />
    </LanguageProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
