import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/PageNotFound.css";

const PageNotFound = () => {
  const navigate = useNavigate();
  const homePage = () => {
    navigate("/");
  };
  // Add logic to handle redirection
  useEffect(() => {
    navigate("/"); // Redirect to the previous page
    // Redirect after 3 seconds (adjust as needed)
  }, [navigate]);
  return (
    <div className="e404div">
      <h1 className="eh1">Oops..!</h1>
      <h5 className="eh5"> error(404) - Page not found.</h5>

      <button className="ebtn" onClick={homePage}>
        Back to home
      </button>
    </div>
  );
};

export default PageNotFound;
