import React, { useEffect} from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../assets/css/about.css";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import about1 from "../assets/images/about-11.webp";
import about2 from "../assets/images/about-12.webp";
import about3 from "../assets/images/shape-13.png";
import about4 from "../assets/images/shape-21.png";
import about5 from "../assets/images/shape-40.png";
import mission from "../assets/images/about-14.webp";
import vision from "../assets/images/blog-02.jpg";
import whyicon1 from "../assets/images/online-learning.png";
import whyicon2 from "../assets/images/web-security.png";
import whyicon3 from "../assets/images/instructor.png";
import { Link } from "react-router-dom";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  const { t } = useTranslation();
  return (
    <>
      <Navbar></Navbar>
      <div className="about-top">
        <Container style={{ height: "100%" }}>
          <div className="about-top-text">
            <h1>{t("Explore a Wide Range of Learning Paths")}</h1>
          </div>
        </Container>
      </div>

      <div className="about-body">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={6}>
              <div className="about-us-text">
                <p style={{ fontSize: "20px", fontWeight: "600" }}>
                  {t("About Us")}
                </p>
                <h2>{t("We Provide The")}</h2>
                <h2 style={{ color: "#04c977", lineHeight: "27px" }}>
                  {t("Best Quality Online")}
                </h2>
                <h2>{t("Courses.")}</h2>
                <p className="about-p">
                  {t(
                    "Welcome to Skillmo, your premier destination for online learning and skill development! At Skillmo, we believe that education is the key to unlocking endless opportunities and reaching your full potential. Our mission is to empower individuals like you to acquire new skills, gain knowledge, and enhance your expertise in a wide range of subjects."
                  )}
                </p>
                {/* <ul>
                  <li>Flexible Classes</li>
                  <li>Offline Classe Mode</li>
                  <li>Educator Support</li>
                </ul> */}
              </div>
            </Col>

            <Col xs={12} sm={12} md={6}>
              <div className="about-img">
                <img className="img1" src={about1} alt="pic" style={{}} />
                <img className="img2" src={about3} alt="pic" style={{}} />
                <img className="img3" src={about2} alt="pic" style={{}} />
                <img className="img4" src={about4} alt="pic" style={{}} />
                <img className="img5" src={about5} alt="pic" style={{}} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="about-why-choose">
        <Container>
          <div className="why-tittle">
            <p style={{ fontSize: "20px", fontWeight: "600" }}>
              {t("Our Advantages")}
            </p>
            <h2>{t("The Best Beneficial Side")}</h2>
            <h2 style={{ color: "#04c977", lineHeight: "27px" }}>
              {t("of Skillmo")}
            </h2>
          </div>

          <div className="why-section">
            <Row>
              <Col xs={12} sm={12} md={4}>
                <div className="why-box">
                  <div className="why-icon">
                    <img
                      className="why-icon-img"
                      src={whyicon1}
                      alt="pic"
                      style={{}}
                    />
                  </div>
                  <h3>{t("High Quality Courses")}</h3>
                  <p>
                    {t(
                      "Lorem ipsum dolor sit amet conset ur elit sed eiusmod ex tempor inc labore dolore magna."
                    )}
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="why-box">
                  <div className="why-icon">
                    <img
                      className="why-icon-img"
                      src={whyicon2}
                      alt="pic"
                      style={{}}
                    />
                  </div>
                  <h3>{t("Life Time Access")}</h3>
                  <p>
                    {t(
                      "Lorem ipsum dolor sit amet conset ur elit sed eiusmod ex tempor inc labore dolore magna."
                    )}
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="why-box">
                  <div className="why-icon">
                    <img
                      className="why-icon-img"
                      src={whyicon3}
                      alt="pic"
                      style={{}}
                    />
                  </div>
                  <h3>{t("Expert Instructors")}</h3>
                  <p>
                    {t(
                      "Lorem ipsum dolor sit amet conset ur elit sed eiusmod ex tempor inc labore dolore magna."
                    )}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <div className="mission">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={6}>
              <div className="mission-text">
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    marginTop: "65px",
                  }}
                >
                  {t("Mission")}
                </p>
                <h2>{t("Mission To")}</h2>
                <h2 style={{ color: "#04c977", lineHeight: "27px" }}>
                  {t("Empowering Lifelong")}
                </h2>
                <h2>{t("Learning")}</h2>
                <p className="mission-p">
                  {t(
                    "At Skillmo, our mission is to democratize education and empower individuals worldwide with the knowledge and skills they need to thrive in an ever-changing world. We are dedicated to providing accessible, high-quality online courses that inspire lifelong learning, foster personal growth, and drive professional success."
                  )}
                </p>
              </div>
            </Col>

            <Col xs={12} sm={12} md={6}>
              <div className="mission-img">
                <img className="m-img" src={mission} alt="pic" style={{}} />
              </div>
            </Col>
          </Row>

          <Row style={{ paddingTop: "65px", paddingBottom: "30px" }}>
            <Col xs={12} sm={12} md={6}>
              <div className="mission-img">
                <img className="m-img" src={vision} alt="pic" style={{}} />
              </div>
            </Col>

            <Col xs={12} sm={12} md={6}>
              <div className="mission-text">
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    marginTop: "25px",
                  }}
                >
                  {t("Vision")}
                </p>
                <h2>{t("Vision for")}</h2>
                <h2 style={{ color: "#04c977", lineHeight: "27px" }}>
                  {t("Inspiring a Brighter")}
                </h2>
                <h2>{t("Tomorrow")}</h2>
                <p className="mission-p">
                  {t(
                    "Our vision at Skillmo is to create a global community of lifelong learners who are passionate about continuous self-improvement. We aspire to be the leading platform that connects individuals with the best educators and resources, making learning a seamless and enriching experience for people of all backgrounds. We envision a future where everyone has the opportunity to unlock their full potential and shape a brighter, more promising tomorrow through education."
                  )}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="get-started-botton">
        <Container>
          <div className="get-main">
            <h2>{t("Get Your Quality Skills Certificate")}</h2>
            <h2 style={{ color: "#04c977", lineHeight: "27px" }}>
              {t("Through skillmo")}
            </h2>
          </div>
          <Link to="/register">
            <button
              style={{ marginTop: "30px" }}
              className="btn rounded reg-bttn"
            >
              {t("Get started now ")}
            </button>
          </Link>
        </Container>
      </div>

      <Footer></Footer>
    </>
  );
};

export default About;
