import React, { useEffect, useState, useContext } from "react";
import { RiHomeLine } from "react-icons/ri";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import "../../assets/css/home/Header.css";
import { useLocationStore } from "../../store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../LanguageContext";
import { Search, GpsFixed, AddReaction } from "@mui/icons-material";
import debounce from "lodash.debounce";
import { baseUrl } from "../../config";
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from "react-places-autocomplete";
// import AddressAutocomplete from "../AddressAutocomplete";
const normalizePostCode = (value) => {
  return (
    value
      .replace(/\s/g, "")
      .match(/.{1,3}/g)
      ?.join(" ")
      .substr(0, 19) || ""
  );
};
const Header = () => {
  const navigate = useNavigate();
  const { location, fetchLocation } = useLocationStore();
  const { t } = useTranslation();
  const languageData = useContext(LanguageContext);
  const [language, setLanguage] = useState("en");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedLatLng, setSelectedLatLng] = useState(null);
  const [loading, setLoading] = useState(false);
  const [keywordData, setKeyword] = useState("");
  const [keywordError, setKeywordError] = useState(false);
  const [selectedLocationData, setSelectedLocationData] = useState(null); // Initialize with null or an appropriate initial value
  const [query, setQuery] = useState("");
  const [result, setResult] = useState("");
  const [chooseLocation, setChooseLocation] = useState("");
  const [opne, setOpen] = useState(false);
  const [apiCall, setApicall] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [inputData, setInputData] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [address, setAddress] = useState(null);
  const [error, setError] = useState(null);

  const [lat, setLat] = useState(null);
  const [lon, setLon] = useState(null);
  const [postalCode, setPostCode] = useState(null);

  const handleSearch = (text) => {
    setLoading(true);

    let apiUrl = `${baseUrl}/master/address?search=${text}`;
    if (text) {
      fetch(apiUrl, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.data.data && data.data.data.length > 0) {
            setChooseLocation(data.data.data[0].address);
            setAddress(data.data.data[0].address);
            setLat(data.data.data[0].Latitude);
            setLon(data.data.data[0].Longitude);
            setResult(data?.data?.data[0]?.address);
            setKeywordError(false);
            // setKeywordErrorDelayed(false);
            setLoading(false);
          } else {
            setResult("");
            // setKeywordErrorDelayed(true);
            setKeywordError(true);
            setApicall(apiCall + 1);
            console.log("resolve");
            setLoading(false);
          }
        })
        .catch((error) => {
          setKeywordError(false);
          console.error("Error fetching location data:", error);
          setLoading(false);
        });
    } else {
      setResult("");
      // setKeywordErrorDelayed(false);
      setKeywordError(false);
      setQuery("");
      setLoading(false);
    }
  };

  const getCurrentLocation = (e) => {
    e.preventDefault();
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            const { latitude, longitude } = position.coords;
            const response = await fetch(
              `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
            );
            // `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
            if (!response.ok) {
              throw new Error("Error fetching address");
            }

            const data = await response.json();
            const postalCode = data.address.postcode;
            setPostCode(postalCode);
            setLat(latitude);
            setLon(longitude);
            setChooseLocation(data.display_name);
            setQuery(data.display_name);
            setKeywordError(false);
            setAddress(data.display_name);
          } catch (error) {
            setError(`Error getting geolocation data: ${error.message}`);
          }
        },
        (error) => {
          setError(`Error getting geolocation data: ${error.message}`);
        }
      );
    } else {
      setError("Geolocation is not available in this browser.");
    }
  };

  useEffect(() => {
    async function fetchLocationData() {
      await fetchLocation();
    }
    fetchLocationData();
  }, []);

  const onSubmit = async (values) => {
    if (chooseLocation === "Choose Location") {
      setKeywordError(true);
    } else {
      navigate(`/course-list/`, {
        state: {
          location_id:postalCode?postalCode: inputData,
          lat: lat,
          lon: lon,
          keyword: keywordData,
        },
      });
      window.scrollTo(0, 0);
    }
  };

  const updateQuery = (e) => {
    const data = inputValue?.toUpperCase();
    const data1 = data?.replace(/-/g, " ");
    handleSearch(data1);
    setKeywordError(false);
    setQuery(data);
    setInputData(data1);
    // const url = new URL(window.location.href);
    // url.searchParams.set("address", data);
    // window.history.pushState({}, "", url.toString());
  };

  const debounceOnChange = debounce(updateQuery, 2000);

  useEffect(() => {
    debounceOnChange();
    return () => {
      debounceOnChange.cancel();
    };
  }, [inputValue]);

  // const setKeywordErrorDelayed = debounce((errorState) => {
  //   setKeywordError(errorState);
  // }, 1000);

  useEffect(() => {
    if (query.length === 0 || chooseLocation || address) {
      setKeywordError(false);
      // setKeywordErrorDelayed(false);
    }
  }, [!query, query, chooseLocation, address]);

  return (
    <>
      <div className="header-container-fluid">
        <Container>
          <Row>
            <Col xs={12} md={12} lg={12} style={{ marginTop: "1%" }}>
              <div className="header-text">
                {/* <h6 className="header-h6">
                <RiHomeLine className="home mx-1" />
                
                <b>{t("HOME")}</b>
              </h6>
              <h1 className="header-h1">
                {t("Find the Best Courses")} <br />
                {t("and")} <span> {t("Improve your Skills")}</span>
              </h1>
              <p className="header-h4">
                {t("Description of your platform here description of")}f
              </p>
              <p className="header-h4">
                {t("your platform here escription of your platform here")}
              </p> */}

                <h1 className="header-maine-text">Find the course you want</h1>

                <form
                  className="form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit();
                  }}
                >
                  <div className="search col-lg-5 m-2">
                    <div className="search">
                      <span>
                        <Search />
                      </span>
                      {!address ? (
                        <input
                          type="text"
                          
                          name="city"
                          className="form-control"
                          placeholder="Post Code:"
                          // onChange={(e) => { // Call debounceOnChange to handle input change with debounce
                          //   // setKeywordErrorDelayed(true); // Call setKeywordErrorDelayed to set error state with a delay
                          // }}
                          value={inputValue}
                          onChange={(e) =>
                            setInputValue(e.target.value.toUpperCase())
                          }
                        ></input>
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          value={address}
                          onChange={() => {
                            setAddress(null);
                          }}
                        />
                      )}
                      {!query && (
                        <button
                          onClick={(e) => {
                            getCurrentLocation(e);
                            setQuery("");
                          }}
                        >
                          <p className="current">CURRENT</p> <GpsFixed />
                        </button>
                      )}
                      {loading && (
                        <button>
                          <Spinner
                            style={{
                              marginRight: "20px",
                              height: "20px",
                              width: "20px",
                            }}
                            animation="border"
                            variant="primary"
                          />
                        </button>
                      )}
                    </div>
                    {keywordError && (
                      <p style={{ color: "red" }}>
                        Please enter a valid UK postcode
                      </p>
                    )}
                  </div>
                  <div class="text-form col-lg-5 m-2">
                    <input
                      type="text"
                      class="form-control search-placeholder"
                      placeholder={t("Write Key-words or Course name")}
                      value={keywordData}
                      onChange={(e) => {
                        setKeyword(e.target.value);
                        setKeywordError(false);
                      }}
                    />
                  </div>

                  <div className="buttn">
                    {loading ? (
                      <p style={{ align: "center" }} class="search-bttn">
                        {/* <Spinner animation="border" variant="primary" /> */}
                      </p>
                    ) : (
                      <button type="submit" class="search-bttn">
                        {t("SEARCH")}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div className="wrapper">
        <div className="dark-strip">
          <h4 className="title">{t("Find the right course for you")}</h4>
        </div>
      </div> */}
    </>
  );
};

export default Header;
