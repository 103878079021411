import React, { useEffect, useState, useCallback } from "react";
import CourseListCard from "../components/courselist/CourseListCard";
import { FaAngleDown, FaPlus } from "react-icons/fa";
import { RiHomeLine } from "react-icons/ri";
import { IoIosTarget } from "react-icons/io";
import {
  Form,
  Row,
  Col,
  Dropdown,
  FormControl,
  Button,
  Container,
  Card,
  Spinner,
} from "react-bootstrap";
import { Search, GpsFixed } from "@mui/icons-material";
import "../assets/css/CourseList.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {
  FaFire,
  FaChevronLeft,
  FaChevronRight,
  FaMinus,
  FaMapMarkerAlt,
  FaBullseye,
} from "react-icons/fa";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useLocationStore } from "../store";
import { useCategoryStore } from "../store";
import { useTradesStore } from "../store";
import { useLocation } from "react-router-dom";
import { useCompanyStore } from "../store";
import { useAccreditationsStore } from "../store";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import debounce from "lodash.debounce";
import { baseUrl } from "../config";
const CourseList = () => {
  const params = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);
  const [isToggled1, setIsToggled1] = useState(false);
  const [isToggled2, setIsToggled2] = useState(false);
  const [isToggled3, setIsToggled3] = useState(false);
  const [isToggled4, setIsToggled4] = useState(false);
  const [isToggled5, setIsToggled5] = useState(false);
  const [filterCourse, setCources] = useState([]);
  const [openIndex, setOpnenIndex] = useState(null);

  const [query, setQuery] = useState(
    params?.state?.location_id?.slice(-8) || ""
  );

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [lat, setLat] = useState(params?.state?.lat ? params?.state?.lat : "");
  const [lon, setLon] = useState(params?.state?.lon ? params?.state?.lon : "");
  const [inputValue, setInputValue] = useState("");
  const [name, setName] = useState(
    params?.state?.location_id ? params?.state?.location_id : ""
  );
  const [keywordError, setKeywordError] = useState(false);
  const [apiCall, setApicall] = useState(0);

  let paramName = useParams();
  // console.log("params ", paramName);
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };
  const handleToggle1 = () => {
    setIsToggled1(!isToggled1);
  };
  const handleToggle2 = () => {
    setIsToggled2(!isToggled2);
  };
  const handleToggle3 = () => {
    setIsToggled3(!isToggled3);
  };
  const handleToggle4 = () => {
    setIsToggled4(!isToggled4);
    if (!isToggled4) {
      setQuery("");
      setName("");
    } else {
      setChooseLocation("");
      setQuery("");
      setName("");
    }
  };
  const handleToggle5 = () => {
    setIsToggled5(!isToggled5);
  };
  /* filter Section Area */
  const toggleFilter = () => {
    setFilterVisible(!isFilterVisible);
  };
  const closeFilterDropdown = () => {
    setFilterVisible(false);
  };
  const handleScreenResize = () => {
    if (window.innerWidth <= 991) {
      setFilterBtnVisible(true);
    } else {
      setFilterBtnVisible(false);
      setFilterVisible(true);
    }
  };
  const { location, fetchLocation } = useLocationStore();
  const { category, fetchCategory } = useCategoryStore();
  const { trades, fetchTrades } = useTradesStore();
  const { company, fetchCompany } = useCompanyStore();
  const { accredits, fetchAccreditations } = useAccreditationsStore();

  useEffect(() => {
    async function fetchLocationData() {
      await fetchLocation();
      await fetchCategory();
      await fetchTrades();
      await fetchCompany();
      await fetchAccreditations();
    }
    fetchLocationData();
    handleScreenResize();
    window.addEventListener("resize", handleScreenResize);
    return () => {
      window.removeEventListener("resize", handleScreenResize);
    };
  }, []);

  useEffect(() => {
    const handlePopState = (event) => {
      navigate(-2);
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const radioLabelStyle = {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  };
  const [isFilterVisible, setFilterVisible] = useState(false);
  const [isFilterBtnVisible, setFilterBtnVisible] = useState(true);
  const [locationData, setLocationData] = useState(
    params?.state?.location_id ? params?.state?.location_id : {}
  );
  const [ChooseLocation, setChooseLocation] = useState(
    params?.state?.location_id ? params?.state?.location_id : ""
  );

  const [keywordData, setKeyword] = useState(
    params?.state?.keyword ? params?.state?.keyword : ""
  );
  // console.log("state of the params", params?.state)
  const [selectedLocationData, setSelectedLocationData] = useState(
    params?.state?.location_id ? params?.state?.location_id : " "
  ); // Initialize with null or an appropriate initial value
  const [distanceRange, setDistance] = useState([10]);
  const [priceRange, setPrice] = useState(["0", "5000"]);
  const [apiTrigar, setApiTrigar] = useState(0);
  const [sortBy, setSortBy] = useState(null);
  const handlePriceRangeChangeStart = (priceRangeS, priceRangeE) => {
    setPrice([priceRangeS, priceRangeE]);
    setApiTrigar(apiTrigar + 1);
    setKeyword("");
    setLocationData("");
  };
  const handlePriceRange = (value) => {
    setSortBy(value);
    setApiTrigar(apiTrigar + 1);
  };
  const handlePriceChange = (newRange) => {
    setPrice(newRange);
    const url = new URL(window.location.href);
    url.searchParams.set("minprice", newRange[0]); // Remove the parameter
    url.searchParams.set("maxprice", newRange[1]); // Remove the parameter
    window.history.pushState({}, "", url.toString());
  };

  const updateDistanceUrl = useCallback(
    debounce((newRange) => {
      const url = new URL(window.location.href);
      url.searchParams.set("distance", newRange);
      window.history.pushState({}, "", url.toString());
    }, 500),
    []
  );

  const handleDistannceChange = (newRange) => {
    setDistance(newRange);
    setLocationData("");
    updateDistanceUrl(newRange);
  };

  const handleLocationChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const otherValue = selectedOption.getAttribute("value");
    setSelectedLocationData(otherValue);
    setKeyword("");
    setLocationData("");
  };

  const trackStyle = { backgroundColor: "rgba(9, 185, 112, 1)" };
  const handleStyle = {
    backgroundColor: "rgba(9, 185, 112, 1)", // Custom handle color
    borderColor: " rgba(9, 185, 112, 1)", // Custom handle border color
  };

  const [selectedSechudale, setSelectedSechudale] = useState("");
  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedSechudale === selectedValue) {
      setSelectedSechudale(null);
    } else {
      setSelectedSechudale(event.target.value);
    }
    setKeyword("");
    setLocationData("");
    // Update the URL with the selected trade ID
    const url = new URL(window.location.href);

    if (selectedSechudale === selectedValue) {
      url.searchParams.delete("schedule");
    } else {
      url.searchParams.set("schedule", selectedValue); // Remove the parameter
    }

    window.history.pushState({}, "", url.toString());
  };

  const [selectedCategory, setSelectedCategory] = useState(
    params?.state?.category_id ? params?.state?.category_id : ""
  );

  const handleCategoryChange = (categoryId) => {
    const updatedSelectedAccredian = [...selectedCategory];
    if (updatedSelectedAccredian.includes(categoryId)) {
      updatedSelectedAccredian.splice(
        updatedSelectedAccredian.indexOf(categoryId),
        1
      );
    } else {
      updatedSelectedAccredian.push(categoryId);
    }
    setSelectedCategory(updatedSelectedAccredian);
    updateUrl(updatedSelectedAccredian);
    setKeyword("");
    setLocationData("");
  };

  const updateUrl = (selectedCategory) => {
    const categoryString = selectedCategory.join(",");
    const url = new URL(window.location.href);
    if (selectedCategory?.length === 0) {
      url.searchParams.delete(`categories`);
    } else {
      url.searchParams.set(`categories`, categoryString);
    }
    window.history.pushState({}, "", url.toString());
  };

  const [selectedCompany, setSelectedCompany] = useState("");

  const [selectedAccredian, setSelectedAccredian] = useState(
    params?.state?.accredits_id ? params?.state?.accredits_id : ""
  );

  const handleCompanyChange = (index) => {
    const updatedSelectedAccredian = [...selectedAccredian];
    if (updatedSelectedAccredian.includes(index)) {
      updatedSelectedAccredian.splice(
        updatedSelectedAccredian.indexOf(index),
        1
      );
    } else {
      updatedSelectedAccredian.push(index);
    }
    setSelectedAccredian(updatedSelectedAccredian);
    updateUrl2(updatedSelectedAccredian);
    setKeyword("");
    setLocationData("");
  };

  const updateUrl2 = (selectedCategory) => {
    const accreditionString = selectedCategory.join(",");
    const url = new URL(window.location.href);
    if (selectedCategory?.length === 0) {
      url.searchParams.delete(`accredition`);
    } else {
      url.searchParams.set("accredition", accreditionString);
    }
    window.history.pushState({}, "", url.toString());
  };

  const [selectedChildTrade, setSelectedChildTrade] = useState(
    params?.state?.subTrads_id ? params?.state?.subTrads_id : ""
  );

  let minPrice = priceRange[0]?.toString();
  let maxPrice = priceRange[1]?.toString();
  let distance = distanceRange[0];

  const queryParams = new URLSearchParams(window.location.search);
  let accValue = queryParams.get("accredition");
  let catValue = queryParams.get("categories");
  let scheduleValue = queryParams.get("schedule");
  minPrice = queryParams.get("minprice");
  maxPrice = queryParams.get("maxprice");
  distance = queryParams.get("distance");

  const handleSearch = (text) => {
    let apiUrl = `${baseUrl}/master/address?search=${text}`;
    setLoading(true);
    if (text) {
      fetch(apiUrl, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.data.data && data.data.data.length > 0) {
            const url = new URL(window.location.href);
            setChooseLocation(data.data.data[0].address);
            setName(data.data.data[0].address);
            setLat(data.data.data[0].Latitude);
            setLon(data.data.data[0].Longitude);
            url.searchParams.set("lat", data.data.data[0].Latitude);
            url.searchParams.set("lon", data.data.data[0].Longitude);
            window.history.pushState({}, "", url.toString());
            setKeywordError(false);
            setLoading(false);
            setApicall(apiCall + 1);
          } else {
            setKeywordError(true);
            setApicall(apiCall + 1);
            setLoading(false);
          }
          // if (query.length === 0) {
          //   setKeywordError(false);
          // }
        })
        .catch((error) => {
          setKeywordError(true);
          console.error("Error fetching location data:", error);
          setLoading(false);
        });
    } else {
      setLoading(false);
      // setKeywordError(false);
    }
  };

  const getCurrentLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            const { latitude, longitude } = position.coords;
            // Get address using reverse geocoding (Nominatim API example)
            const response = await fetch(
              `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
            );
            if (!response.ok) {
              throw new Error("Error fetching address");
            }
            const data = await response.json();
            // Extract the address from the response
            const address = data.display_name;
            // Update state or perform any other action with the address
            setLat(latitude);
            setLon(longitude);
            setChooseLocation(address);
            setQuery(address);
            setKeywordError(false);
          } catch (error) {
            setError(`Error getting geolocation data: ${error.message}`);
          }
        },
        (error) => {
          setError(`Error getting geolocation data: ${error.message}`);
        }
      );
    } else {
      setError("Geolocation is not available in this browser.");
    }
  };
  const setKeywordErrorDelayed = debounce((errorState) => {
    setKeywordError(errorState);
  }, 2000);

  const updateQuery = (e) => {
    const data = inputValue?.toUpperCase();
    const data1 = data?.replace(/-/g, " ");
    handleSearch(data1);
    setQuery(data);
    if (data1) {
      const url = new URL(window.location.href);
      url.searchParams.set("postal", data1);
      window.history.pushState({}, "", url.toString());
    }
  };
  const debounceOnChange = debounce(updateQuery, 2000);

  
  useEffect(() => {
    debounceOnChange();
    return () => {
      debounceOnChange.cancel();
    };
  }, [inputValue]);

  useEffect(() => {
    if (params?.state) {
      const url = new URL(window.location.href);
      if (params.state.location_id) {
        url.searchParams.set("postal", params.state.location_id);
        url.searchParams.set("lat", params.state.lat);
        url.searchParams.set("lon", params.state.lon);
      } else {
        console.log("params data not found");
      }
      if (params.state.keyword) {
        url.searchParams.set("keyword", params.state.keyword);
      } else {
        console.log("params data not found");
      }
      window.history.pushState({}, "", url.toString());
      handleSearch(params.state.location_id);
    } else if (params?.search) {
      const searchParams = new URLSearchParams(params.search);
      const address1 = searchParams.get("postal");
      const lat1 = searchParams.get("lat");
      const lon1 = searchParams.get("lon");
      const keyword = searchParams.get("keyword");
      accValue = queryParams.get("accredition");
      catValue = queryParams.get("categories");
      scheduleValue = queryParams.get("schedule");
      distance = queryParams.get("distance");
      minPrice = queryParams.get("minprice");
      maxPrice = queryParams.get("maxprice");

      if (address1) {
        setInputValue(address1);
        setName(address1);
      } else {
        console.log("params data not found");
      }
      if (lat1 && lon1) {
        setLat(lat1);
        setLon(lon1);
      } else {
        console.log("params data not found");
      }
      if (keyword) {
        setQuery(keyword);
        setKeyword(keyword);
      } else {
        console.log("params data not found");
      }
      if (minPrice || maxPrice) {
        setPrice([minPrice, maxPrice]);
      } else {
        console.log("params data not found");
      }
      if (catValue) {
        const categoryArray = decodeURIComponent(catValue).split(",");
        setSelectedCategory(categoryArray);
      } else {
        console.log("params data not found");
      }
      if (accValue) {
        const accordianArray = decodeURIComponent(accValue).split(",");
        setSelectedAccredian(accordianArray);
      } else {
        console.log("params data not found");
      }
      if (distance) {
        setDistance([parseInt(distance)]);
      } else {
        console.log("params data not found");
      }

      setTimeout(() => {
        setApiTrigar(apiTrigar + 1);
      }, 2000);
    }
  }, [params]);
  useEffect(() => {
    if (query?.length === 0 || ChooseLocation) {
      setKeywordError(false);
      // setChooseLocation(query);
    }
  }, [!query, apiCall, ChooseLocation]);

  useEffect(() => {
    setSelectedAccredian((prevSelectedAccredian) => {
      if (params?.state?.accredits_id) {
        return params.state.accredits_id.split(",");
      } else {
        return prevSelectedAccredian || [];
      }
    });

    setSelectedCategory((prevSelectedCategory) => {
      if (params?.state?.category_id) {
        return params.state.category_id.split(",");
      } else {
        return prevSelectedCategory || [];
      }
    });
    setChooseLocation(
      params?.state?.location_id ? params?.state?.location_id : null
    );
  }, [params]);

  const dist_miles = distance ? distance : distanceRange[0];

  useEffect(() => {
    const apiUrl =
      `${baseUrl}/course/filter/data?` +
      `${catValue ? `category=${catValue}&` : ""}` +
      `${selectedCompany ? `created_by=${selectedCompany}&` : ""}` +
      `${selectedChildTrade ? `trades_sub=${selectedChildTrade}&` : ""}` +
      `${scheduleValue ? `schedule=${scheduleValue}&` : ""}` +
      `${keywordData ? `keyword=${keywordData}&` : ""}` +
      `${accValue ? `accredition=${accValue}&` : ""}` +
      `${name ? `location=${name}&` : ""}` +
      `${lat ? `lat=${lat}&` : ""}` +
      `${lon ? `lon=${lon}&` : ""}` +
      `${dist_miles ? `miles_dist=${dist_miles}&` : ""}` +
      `${minPrice ? `min_price=${minPrice}&` : `min_price=${0}&`}` +
      `${maxPrice ? `max_price=${maxPrice}&` : `max_price=${1000}&`}` +
      `${sortBy ? `sort=${sortBy}` : ""}`;
    setLoading2(true);
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setCources(data.data.data);
        setLoading2(false);
      })
      .catch((error) => {
        console.error("API call error:", error);
        setLoading2(false);
      });
  }, [
    params?.key,
    selectedSechudale,
    selectedCategory,
    apiTrigar,
    selectedCompany,
    selectedChildTrade,
    selectedAccredian,
  ]);

  const capitalizeFirstLetter = (text) => {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  return (
    <div style={{ overflow: "hidden" }}>
      {isFilterVisible && <div className="background-div"></div>}
      <Navbar></Navbar>
      <div className="banner-course-list">
        <Container fluid>
          <Row className="banner-row-list">
            <h6 className="h6 mt-4 mb-3 breadcrum-list" style={{ zIndex: "1" }}>
              <Link className="navbar-brand" to="/">
                <RiHomeLine className="home1 mx-1" />
                <b className="course-tittle1">{t("HOME")}</b>
                {/* <img src={Image} alt="logo" /> */}
              </Link>
              &nbsp;&nbsp;<b className="course-tittle2">/</b>&nbsp;&nbsp;
              <b className="course-tittle3">{t("Course List")}</b>
            </h6>
            <div style={{ zIndex: "1" }}>
              <h2 className="header-h6 mt-4 mb-0 titte-head">
                {t("Find the right course for you")}
              </h2>
            </div>
          </Row>
          <Row className="banner-options-course-list banner-row-list2">
            <Col xs={12} lg={5} md={4} sm={12} className="custom-column ">
              <div className="search">
                <span>
                  <Search />
                </span>
                {!ChooseLocation ? (
                  <input
                    type="text"
                    name="city"
                    style={{ fontWeight: "500" }}
                    className="form-control"
                    placeholder="Post Code"
                    value={inputValue}
                    onChange={(e) => {
                      setInputValue(e.target.value.toUpperCase());
                    }}
                    // style={{ textTransform: "uppercase" }}
                    // value={query}
                    // onChange={(e) => {
                    //   const { value } = e.target;
                    //   // const normalizedValue = normalizePostCode(value);
                    //   setQuery(value)
                    //   // setQuery(value, () => {
                    //   //   handleSearch();
                    //   // });
                    //   if (value.length >= 5) {
                    //     handleSearch();
                    //   }
                    // }}
                    // onKeyDown={handleSearch}
                    // onKeyUp={handleSearch}
                    // onBlur={() => handleSearch()}
                  />
                ) : (
                  <input
                    type="text"
                    fontWeight="500"
                    className="form-control"
                    value={ChooseLocation}
                    onChange={() => {
                      setChooseLocation(null);
                    }}
                  />
                )}

                {!query && !loading && (
                  <button
                    onClick={() => {
                      getCurrentLocation();
                      setQuery("");
                    }}
                  >
                    <p className="current">CURRENT</p> <GpsFixed />
                  </button>
                )}
                {loading && (
                  <button>
                    <Spinner
                      style={{
                        marginRight: "20px",
                        height: "20px",
                        width: "20px",
                      }}
                      animation="border"
                      variant="primary"
                    />
                  </button>
                )}
              </div>
              {keywordError && !ChooseLocation && (
                <p style={{ color: "red" }}>Please enter a valid UK postcode</p>
              )}
              {/* {(name && !keywordError && query) && (
                <p style={{ color: "White" }}>{ChooseLocation}</p>
              )} */}
            </Col>

            <Col xs={12} lg={5} md={4} sm={12} className="custom-column ">
              <div className="text-form">
                <input
                  type="text"
                  class="form-control search-placeholder"
                  placeholder={
                    keywordData === ""
                      ? t("Write Key-words or Course name")
                      : keywordData
                  }
                  // defaultValue={"Key-words: Write Key-words or Course name"}
                  value={keywordData}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                    const url = new URL(window.location.href);
                    url.searchParams.set("keyword", e.target.value);
                    window.history.pushState({}, "", url.toString());
                  }}
                />
              </div>
            </Col>
            <Col className="custom-column">
              <button
                onClick={() => setApiTrigar(apiTrigar + 1)}
                type="button"
                class="search-bttnn btn-success custom-btn"
              >
                {t("SEARCH")}
              </button>
            </Col>
          </Row>
        </Container>
      </div>

      <div style={{ backgroundColor: "#f6f5fb" }}>
        <Container>
          <Row>
            <div className="filter-btn-div">
              {isFilterBtnVisible && (
                <button className="filter-btn" onClick={toggleFilter}>
                  {t("Filter Options")}
                </button>
              )}
            </div>
            {isFilterVisible && (
              <Col
                xs={12}
                md={12}
                lg={3}
                className="p-4 mt-5 filter-dropdown-overlay"
                style={{
                  backgroundColor: "white",
                  borderRadius: "20px",
                  padding: "2.5%",
                  boxShadow: "0px 18px 32px 0px #0000000D",
                }}
              >
                <div className="filter-dropdown-content">
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <button
                      className="close-filter-button"
                      onClick={closeFilterDropdown}
                    >
                      X
                    </button>
                  </div>
                  <div className="filter-dropdown-body">
                    <p
                      style={{
                        padding: "5px",
                        fontSize: "12px",
                        color: "grey",
                      }}
                    >
                      {t("Filter by")}:
                    </p>
                    {/* distance section */}
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h5 className="box-tag">{t("Distance")}</h5>
                      <div style={{ cursor: "pointer" }}>
                        {isToggled4 ? (
                          <FaMinus onClick={handleToggle4}></FaMinus>
                        ) : (
                          <FaPlus onClick={handleToggle4}></FaPlus>
                        )}
                      </div>
                    </div>
                    {isToggled4 && (
                      <div
                        className="d-flex my-3 justify-content-between"
                        style={{ paddingRight: "20px" }}
                      >
                        <span
                          style={{
                            color: "#777779",
                            letterSpacing: "1px",
                            fontSize: "12px",
                            fontWeight: 700,
                            fontFamily: "Metropolis",
                          }}
                        >
                          {t("Current Location")}:
                        </span>
                        <div className="d-flex">
                          <h6 className="box-child-tag">
                            {ChooseLocation?.substring(0, 12)}
                          </h6>
                          <div
                            onClick={() => {
                              getCurrentLocation();
                              setQuery("");
                            }}
                          >
                            <FaBullseye
                              style={{
                                color: "#09B970",
                                marginLeft: "80%",
                                marginTop: "-48%",
                                cursor: "pointer",
                              }}
                            ></FaBullseye>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <div style={{ borderTop: "1px dotted #000" }}></div> */}
                    {isToggled4 && (
                      <div className="d-flex my-4 justify-content-between">
                        <div className="me-2 w-75">
                          <Button
                            className="w-100 btn-lg px-4 "
                            variant="info"
                            style={{
                              background: "#fafafafa",
                              fontSize: "18px",
                              borderColor: "#1B17201A",
                              boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                              padding: "13px 0px 20px 25px",
                              height: "54px",
                              textAlign: "left",
                            }}
                          >
                            <strong className="box-child-tag">
                              {distanceRange[0]} {t("miles")}
                            </strong>
                          </Button>
                        </div>
                        <div className="me-2 w-35 ">
                          <Button
                            className="w-100 btn-lg "
                            variant="info"
                            style={{
                              background: "#09B970",
                              fontSize: "18px",
                              borderColor: "#09B9",
                              borderWidth: "2px",
                              height: "54px",
                              //padding:  '15px 45px 15px 45px',
                            }}
                            onClick={() => {
                              setApiTrigar(apiTrigar + 1);
                              setOpnenIndex(4);
                            }}
                          >
                            {loading2 && openIndex == 4 ? (
                              <Spinner animation="border" variant="primary" />
                            ) : (
                              <strong
                                style={{ color: "#fafafafa" }}
                                className="box-child-tag"
                              >
                                {t("OK")}
                              </strong>
                            )}
                          </Button>
                        </div>
                      </div>
                    )}
                    {isToggled4 && (
                      <div className="container">
                        <Slider
                          range
                          min={0}
                          // max={1100}
                          value={distanceRange}
                          onChange={handleDistannceChange}
                          trackStyle={[trackStyle, trackStyle]} // Pass an array for both tracks in the range slider
                          handleStyle={[handleStyle, handleStyle]}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        borderTop: "1px solid #1B1720",
                        opacity: "0.1",
                        marginTop: "20px",
                      }}
                    ></div>
                    {/* price section */}
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h5 className="mt-3 mb-1 box-tag">{t("Price")}</h5>
                      <div style={{ cursor: "pointer" }}>
                        {isToggled5 ? (
                          <FaMinus
                            onClick={handleToggle5}
                            className="mt-4 mb-1"
                          ></FaMinus>
                        ) : (
                          <FaPlus
                            onClick={handleToggle5}
                            className="mt-4 mb-1"
                          ></FaPlus>
                        )}
                      </div>
                    </div>
                    {isToggled5 && (
                      <div className="d-flex my-4 justify-content-between">
                        <div className=" w-30">
                          <Button
                            className="w-100 btn-lg px-3 mx-2"
                            variant="info"
                            style={{
                              background: "#fafafafa",
                              fontSize: "18px",
                              borderColor: "#1B17201A",
                              boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                              height: "54px",
                            }}
                          >
                            <strong>£{priceRange[0]}</strong>{" "}
                            {/* Set default start range as desired */}
                          </Button>
                        </div>
                        &nbsp;
                        <div className="py-2">
                          <FaMinus></FaMinus>
                        </div>
                        <div className="me-2 w-30">
                          <Button
                            className="w-100 btn-lg px-3 mx-2"
                            variant="info"
                            style={{
                              background: "#fafafafa",
                              fontSize: "18px",
                              borderColor: "#1B17201A",
                              boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                              height: "54px",
                            }}
                          >
                            {/* <strong>{selectedPriceRangeEnd?`${selectedPriceRangeEnd}`:'$1000'}</strong> */}{" "}
                            {/* Set default end range as desired */}
                            <strong>£{priceRange[1]}</strong>
                          </Button>
                        </div>
                        <Button
                          className="btn-lg mx-2"
                          variant="info"
                          style={{
                            background: "#09B970",
                            fontSize: "18px",
                            borderColor: "#09B9",
                            borderWidth: "2px",
                            height: "54px",
                            width: "54px",
                          }}
                          onClick={() => {
                            setApiTrigar(apiTrigar + 1);
                            setOpnenIndex(5);
                          }}
                        >
                          {loading2 && openIndex == 5 ? (
                            <Spinner animation="border" variant="primary" />
                          ) : (
                            <strong
                              style={{ color: "#fafafafa" }}
                              className=".box-child-tag"
                            >
                              {t("OK")}
                            </strong>
                          )}
                        </Button>
                      </div>
                    )}
                    {isToggled5 && (
                      <div className="container">
                        <Slider
                          range
                          min={0}
                          max={5000}
                          value={priceRange}
                          onChange={handlePriceChange}
                          trackStyle={[trackStyle, trackStyle]} // Pass an array for both tracks in the range slider
                          handleStyle={[handleStyle, handleStyle]}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        borderTop: "1px solid #1B1720",
                        opacity: "0.1",
                        marginTop: "20px",
                      }}
                    ></div>
                    {/* categories section start here */}
                    <div
                      className="d-flex my-4"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h5 className="box-tag">{t("categories")}</h5>
                      <div style={{ cursor: "pointer" }}>
                        {isToggled ? (
                          <FaMinus onClick={handleToggle}></FaMinus>
                        ) : (
                          <FaPlus onClick={handleToggle}></FaPlus>
                        )}
                      </div>
                    </div>
                    <div>
                      {isToggled && (
                        <div>
                          <Form>
                            <div
                              className="row"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "left",
                              }}
                            >
                              {category?.map((categorys, index) => (
                                <div className="col-md-12" key={index}>
                                  <Form.Check
                                    className="pointer-cursor-checkbox cat-form"
                                    type="checkbox"
                                    label={`${capitalizeFirstLetter(
                                      categorys.lang_en
                                    )}`}
                                    checked={selectedCategory?.includes(categorys.id)}
                                    onChange={() =>
                                      handleCategoryChange(categorys.id)
                                    }
                                    style={{
                                      color: selectedCategory?.includes(categorys.id)
                                        ? "#09B970"
                                        : "inherit",
                                      marginLeft: "13px",
                                      paddingBottom: "10px",
                                      transform: "scale(1.1)",
                                      borderColor: "#fff",
                                      borderWidth: "10px",
                                      cursor: "pointer !important",
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          </Form>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        borderTop: "1px solid #1B1720",
                        opacity: "0.1",
                        marginTop: "20px",
                      }}
                    ></div>
                    {/* company section start here */}
                    <div
                      className="d-flex my-4"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h5 className="box-tag">{t("accreditations")}</h5>
                      <div style={{ cursor: "pointer" }}>
                        {isToggled1 ? (
                          <FaMinus onClick={handleToggle1}></FaMinus>
                        ) : (
                          <FaPlus onClick={handleToggle1}></FaPlus>
                        )}
                      </div>
                    </div>
                    {isToggled1 && (
                      <div>
                        <Form>
                          <div
                            className="row"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "left",
                            }}
                          >
                            {accredits?.map((companyItem, index) => (
                              <div className="col-md-12">
                                <Form.Check
                                  className="cat-form"
                                  key={index}
                                  type="checkbox"
                                  label={capitalizeFirstLetter(
                                    companyItem?.lang_en
                                  )}
                                  checked={selectedAccredian?.includes(companyItem?.id)}
                                  onChange={() =>
                                    handleCompanyChange(companyItem?.id)
                                  }
                                  style={{
                                    color: selectedAccredian?.includes(companyItem?.id)
                                      ? "#09B970"
                                      : "inherit",
                                    marginLeft: "13px",
                                    paddingBottom: "30px",
                                    transform: "scale(1.1)", // Increase checkbox size
                                    borderColor: "#fff",
                                    borderWidth: "10px",
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </Form>
                      </div>
                    )}
                    <div
                      style={{
                        borderTop: "1px solid #1B1720",
                        opacity: "0.1",
                        marginTop: "20px",
                      }}
                    ></div>
                    {/* Trades section start from here */}
                    {/* <div
                    className="d-flex my-4"
                    style={{ justifyContent: "space-between" }}
                  >
                    <h5>{t("Trades")}</h5>
                    <div style={{ cursor: "pointer" }}>
                      {isToggled2 ? (
                        <FaMinus onClick={handleToggle2}></FaMinus>
                      ) : (
                        <FaPlus onClick={handleToggle2}></FaPlus>
                      )}
                    </div>
                  </div>
                  {isToggled2 && (
                    <div className="px-4">
                      <Form
                        className="row"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "left",
                        }}
                      >
                        {trades?.map((category, index) => (
                          <div key={index}>
                            <Form.Check
                              type="checkbox"
                              name="parentTrade"
                              value={category.id}
                              checked={selectedParentTrade === category.id}
                              onChange={() =>
                                handleParentTradeChange(category.id)
                              }
                              label={category.lang_en}
                              style={{
                                color:
                                  selectedParentTrade === category.id
                                    ? "#09B970"
                                    : "inherit",
                                marginLeft: "-10px",
                                paddingBottom: "10px",
                                transform: "scale(1.1)", // Increase checkbox size
                                borderColor: "#fff",
                                borderWidth: "10px",
                                cursor: "pointer",
                              }}
                            />
                            {selectedParentTrade === category.id && (
                              <div style={{ marginLeft: "10px" }}>
                                {category.trades_sub_categories?.map(
                                  (subCategory, subIndex) => (
                                    <div
                                      key={subIndex}
                                      style={{ marginLeft: "30px" }}
                                    >
                                      <Form.Check
                                        type="checkbox"
                                        name="childTrade"
                                        value={subCategory.id}
                                        checked={
                                          selectedChildTrade === subCategory.id
                                        }
                                        onChange={() =>
                                          handleChildTradeChange(subCategory.id)
                                        }
                                        label={subCategory.lang_en}
                                        style={{
                                          color:
                                            selectedChildTrade ===
                                            subCategory.id
                                              ? "#09B970"
                                              : "inherit",
                                          marginLeft: "-10px",
                                          paddingBottom: "10px",
                                          transform: "scale(1.1)", // Increase checkbox size
                                          borderColor: "#fff",
                                          borderWidth: "10px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                      </Form>
                    </div>
                  )}
                  <div
                    style={{ borderTop: "1px dotted #000", marginTop: "20px" }}
                  ></div> */}
                    {/* sechudele section */}
                    <div
                      className="d-flex my-4"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h5 className="box-tag">{t("Schedule")}</h5>
                      <div style={{ cursor: "pointer" }}>
                        {isToggled3 ? (
                          <FaMinus onClick={handleToggle3}></FaMinus>
                        ) : (
                          <FaPlus onClick={handleToggle3}></FaPlus>
                        )}
                      </div>
                    </div>
                    {isToggled3 && (
                      <div>
                        <div>
                          <div style={radioLabelStyle}>
                            <input
                              type="checkbox"
                              value="weekday"
                              checked={scheduleValue === "weekday"}
                              onChange={handleOptionChange}
                              style={{
                                color: "#09B970",
                                margin: "10px",
                                padding: "5px",
                                transform: "scale(1.5)", // Increase checkbox size
                                cursor: "pointer",
                              }}
                            />
                            <label className="cat-form">{t("Weekday")}</label>
                          </div>
                        </div>
                        <div style={radioLabelStyle}>
                          <input
                            type="checkbox"
                            value="weekend"
                            checked={scheduleValue === "weekend"}
                            onChange={handleOptionChange}
                            style={{
                              color: "#09B970",
                              margin: "10px",
                              padding: "5px",
                              transform: "scale(1.5)", // Increase checkbox size
                              cursor: "pointer",
                            }}
                          />
                          <label className="cat-form">{t("Weekend")}</label>
                        </div>
                        <div style={radioLabelStyle}>
                          <input
                            type="checkbox"
                            value="Evenings"
                            checked={scheduleValue === "Evenings"}
                            onChange={handleOptionChange}
                            style={{
                              color: "#09B970",
                              margin: "10px",
                              padding: "5px",
                              transform: "scale(1.5)", // Increase checkbox size
                              cursor: "pointer",
                            }}
                          />
                          <label className="cat-form">{t("Evenings")}</label>
                        </div>
                        <div
                          style={{
                            borderTop: "1px dotted #000",
                            marginTop: "20px",
                            marginBottom: "30px",
                          }}
                        ></div>
                      </div>
                    )}
                  </div>

                  <button
                    className="applybtn"
                    onClick={() => {
                      setApiTrigar(apiTrigar + 1);
                      closeFilterDropdown();
                    }}
                  >
                    Apply Now
                  </button>
                </div>
              </Col>
            )}

            {/* Right section */}
            <Col
              xs={12}
              lg={9}
              className="right-section"
              style={{ marginBottom: "30px" }}
            >
              <div className="mt-5 ">
                <Row>
                  <div className="col-md-6">
                    <h4 className="list-tittle">
                      {t("Suitable Constructing Courses")}
                    </h4>
                    <p
                      style={{
                        fontSize: "12px",
                        color: "grey",
                      }}
                    >
                      {/* {t("Showing results for")} {t("“Architecture Courses”")} */}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex justify-content-end">
                      <div
                        style={{
                          color: "grey",
                          fontSize: "12px",
                          paddingRight: "15px",
                          paddingTop: "15px",
                          alignContent: "center",
                        }}
                      >
                        {t("Sort By Price")}:
                      </div>
                      <Dropdown
                        className="custom-dropdown"
                        style={{
                          backgroundColor: "none",
                          width: "auto",
                          border: "none",
                          boxShadow: "0px 0px ",
                        }}
                      >
                        <Dropdown.Toggle
                          /* className="dropdown-toggle" */
                          /* id="dropdown-button-dark-example1" */
                          variant="secondary"
                          style={{
                            fontWeight: 700,
                            padding: "10px 70px 10px 25px",
                            borderRadius: "10px",
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px ",
                            border: ".5px solid ",
                            color: "black",
                          }}
                          /* onClick={()=>handlePriceChange('$ 0-1000')} */
                        >
                          {/* {priceRange[0] && priceRange[1]
                            ? `£${priceRange[0]} - ${priceRange[1]}`
                            : "Relevant"} */}
                          {sortBy === "total_price"
                            ? "Low to High"
                            : sortBy === "-total_price"
                            ? "High to Low"
                            : "Relevant"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {/* <Dropdown.Item
                            className="dropdown-item"
                            style={{ color: "black" }}
                            onClick={() => {
                              handlePriceRangeChangeStart("0", "500");
                            }}
                          >
                            £ 0-500
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="dropdown-item"
                            onClick={() => {
                              handlePriceRangeChangeStart("500", "1000");
                            }}
                          >
                            £ 500-1000
                          </Dropdown.Item>
                          <div className="dropdown-divider" />
                          <Dropdown.Item
                            className="dropdown-item"
                            onClick={() => {
                              handlePriceRangeChangeStart("1000", "2000");
                            }}
                          >
                            £ 1000-2000
                          </Dropdown.Item> */}

                          <Dropdown.Item
                            onClick={() => {
                              handlePriceRange("total_price");
                            }}
                          >
                            Low to High
                          </Dropdown.Item>
                          <div className="dropdown-divider" />
                          <Dropdown.Item
                            onClick={() => {
                              handlePriceRange("-total_price");
                            }}
                          >
                            High to Low
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </Row>
              </div>
              <div>
                {/* You can find card code at the bottom */}
                <CourseListCard data={filterCourse}></CourseListCard>
              </div>
              <div></div>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default CourseList;
