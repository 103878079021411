import React, { useEffect, useState, useRef, useContext } from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import image from "../../assets/images/students-walk-downstairsdd-with-books-library 1 1.png";
import {
  FiCheckCircle,
  FiStar,
  FiShoppingCart,
  FiCalendar,
  FiInfo,
  FiClock,
} from "react-icons/fi";
import { FaFire } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";

import {
  categoryTag,
  subCertifiedTag,
  subNewTag,
  titleTag,
  subTopRatedTag,
} from "../../assets/customcss/CustomCss";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../assets/css/home/Platform.css";
import {
  useCourseStore,
  useUserStore,
  useLocalStore,
  useCartStore,
} from "../../store";
import PaymentsInfo from "../courselist/PaymentsInfo";
import useClickOutside from "../courselist/useClickOutside";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../LanguageContext";
import newtag from '../../assets/icon/new.png'
import certified from '../../assets/icon/certified.png'
import topreated from '../../assets/icon/start (1).png'
import shopping from '../../assets/icon/shopping-preview.png'
import StartingIcon from '../../assets/icon/detailsicon1.png'
import Sdule from '../../assets/icon/schedule.png'
import Online from '../../assets/icon/onlineIcon.png'
import VideoCallIcon from '@mui/icons-material/VideoCall';

function Platform() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const languageData = useContext(LanguageContext);
  const handleButtonClick = () => {
    navigate(`/course-list/`);
    // window.location.reload();
    window.scrollTo(0, 0);
  };

  const { courses, fetchCourses } = useCourseStore();
  const { storeData } = useLocalStore();
  const { items, addToCart, removeFromCart } = useCartStore();
  const userStore = useUserStore();

  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const handleEmailChange = (emailid) => {
    setEmail(emailid.target.value.trim());
  };

  const handleSubscribe = async (email) => {
    try {
      if (!validateEmail(email)) {
        setIsValidEmail(false);
        return;
        alert("Please enter valid email.");
      } else {
        // Simulate a successful subscription
        await userStore.userSubscription({ email }); //calling the userSubscription Fucntion from the userStore
        alert("Subscription successful!");
        setEmail(""); //reset email field
      }
    } catch (error) {
      console.error("Error Subscribing user", error);
    }
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  useEffect(() => {
    async function fetchData() {
      await fetchCourses();
    }
    fetchData();
  }, []);

  const filterNew = courses.filter((item) => (item.is_new = true));
  const [togglePayInfo, setTogglePayInfo] = useState([]);
  const paymentsInfoRef = useRef(null); //creating ref for the PaymentsInfo
  const handleTogglePayInfo = (index) => {
    const updatedToggles = [...togglePayInfo];
    updatedToggles[index] = !updatedToggles[index];
    setTogglePayInfo(updatedToggles);
  };
  useClickOutside(paymentsInfoRef, () => {
    setTogglePayInfo([]);
  });

  const addToCartCourse = async (course) => {
    if (storeData) {
      if (items.some((obj) => obj.id === course.id)) {
        await removeFromCart(course.id);
      } else {
        addToCart(course);
      }
    } else {
      navigate("/login");
    }
  };



  return (
    <div style={{ backgroundColor: "#f5f5fb" }}>
      <div className="container " style={{ paddingTop: "50px" }}>
        <h6
          style={{
            color: '#39128C',
            fontFamily: 'Metropolis',
            fontSize: '16px',
            fontWeight: 700,
            letterSpacing: '5px',
            paddingBottom: '10px',
            lineHeight: "24px",
            textAlign: "left"

          }}
        >
          {t("New on the platform")}
        </h6>
        <div>
          <h3 className=" platform-tittle">
            {t(
              "Choose from more then 100 000 online courses about construction from well-known industry leaders"
            )}
          </h3>
        </div>
        <div className="row my-4">
          {filterNew?.map((course, index) => (
            <div className="col-lg-3 col-md-4 my-2">
              <Card className="platform-card" key={index}>
                <div className="position-relative">
                  <Card.Img
                    className="platform-card-img"
                    src={course?.course_image}
                    alt="Card image"
                    style={{
                      width: "100%",
                      height: "250px",
                      borderRadius: "16px 16px 0px 0px",
                      border: "none"
                    }}
                    cursor="pointer"
                    role="button"
                    onClick={() => {
                      navigate(`/course-details/`, {
                        state: {
                          coursesData: course,
                        },
                      });
                      const url = new URL(window.location.href);
                      url.searchParams.set('course', course.id);
                      window.history.pushState({}, '', url.toString());
                      window.scrollTo(0, 0);
                     
                    }}
                  />
                  <div className="top-rated-box" style={{marginLeft:"5px"}}>
                    {course.is_new == true ? (
                      <p
                        className="platform-subNewTag"
                        variant="danger"
                      /* style={subNewTag} */
                      >
                        {/* <FaFire className="me-1" /> */}
                        <img src={newtag} alt="new" className="new-tag" />
                        {"  "}
                        {t("In-house")}
                      </p>
                    ) : (
                      ""
                    )}

                    {course.is_certified && (
                      <p
                        className="platform-subCertifiedTag"
                        variant="success"
                      /* style={subCertifiedTag} */
                      >
                        {/* <FiCheckCircle className="me-1" /> */}
                        <img src={certified} alt="new" className="new-tag" />
                        {"  "} {t("Online")}
                      </p>
                    )}
                    {course.is_top_rated && (
                      <p
                        className="platform-subTopRatedTag"
                        variant="warning"
                      /* style={subTopRatedTag} */
                      >
                        {/* <FiStar className="me-1" /> */}
                        <img src={topreated} alt="new" className="new-tag" />
                        {"  "}
                        {t("Virtual Classroom")}
                      </p>
                    )}
                  </div>
                  <div>
                    <h5
                      className="position-absolute mb-n3 platform-title-tag"
                      variant="danger"
                      cursor="pointer"
                      role="button"
                      onClick={() => {
                        navigate(`/course-details/`, {
                          state: {
                            coursesData: course,
                          },
                        });
                        const url = new URL(window.location.href);
                        url.searchParams.set('course', course.id);
                        window.history.pushState({}, '', url.toString());
                        window.scrollTo(0, 0);
                      }}
                    /* style={titleTag} */
                    >
                      {/* {course.title_en} */}
                      {languageData?.currentLanguage === "en" && course?.title_en}
                      {languageData?.currentLanguage === "pl" && course?.title_pl}
                      {languageData?.currentLanguage === "ro" &&
                        course?.title_roa}
                    </h5>
                  </div>
                </div>
                <Card.Body>
                  <div className="main-card-keywords d-flex ">
                    {/* <span className="platform-categoryTag">
                      {languageData?.currentLanguage === "en" &&
                        course?.course_category?.lang_en}
                      {languageData?.currentLanguage === "pl" &&
                        course?.course_category?.lang_pl}
                      {languageData?.currentLanguage === "ro" &&
                        course?.course_category?.lang_roa}
                    </span> */}

                    {
                    course?.firm_course_categories && course?.firm_course_categories.map((item, index)=>{
                      return(
                        <span key={index} className="platform-categoryTag">
                        {/* {course.course_category.lang_en} */}
                        {languageData?.currentLanguage === "en" &&
                         item.course_categories[0]?.lang_en}
                        {languageData?.currentLanguage === "pl" &&
                           item.course_categories[0]?.lang_pl}
                        {languageData?.currentLanguage === "ro" &&
                           item.course_categories[0]?.lang_roa}
                      </span>
                      )
                    })
                  }
                    {/* <span className="platform-categoryTag"> */}
                    {/* {course.trades_category.lang_en} */}
                    {/* {languageData?.currentLanguage === "en" &&
                        course?.trades_category?.lang_en}
                      {languageData?.currentLanguage === "pl" &&
                        course?.trades_category?.lang_pl}
                      {languageData?.currentLanguage === "ro" &&
                        course?.trades_category?.lang_roa}
                    </span> */}
                  </div>
                  <div className="platform-card-short-desc">
                    <h4
                      className="mb-2"
                      style={{ fontSize: "18px", marginTop:'10px' }}
                    >
                      {/* Explore our more popular courses... */}
                      {/* {course.short_desc_en.slice(0, 53) + "..."} */}
                      {languageData?.currentLanguage === "en" &&
                        course?.short_desc_en.slice(0, 53) + "..."}
                      {languageData?.currentLanguage === "pl" &&
                        course?.short_desc_pl.slice(0, 53) + "..."}
                      {languageData?.currentLanguage === "ro" &&
                        course?.short_desc_roa.slice(0, 53) + "..."}
                    </h4>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="platform-course-schedule">
                      <span className="me-2">
                        {/* <FiCalendar style={{ color: "#09B970" }} /> */}
                        <img src={StartingIcon} alt="new" className="new-tag1" />
                      </span>
                      <span style={{ fontSize: "14px" }}>{t("Starting") + " :"}</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <strong>{course?.courseStartsAt}</strong>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="platform-course-schedule">
                      <span className="me-2">
                        {/* <FiClock style={{ color: "#09B970" }} /> */}
                        <img src={Sdule} alt="new" className="new-tag1" />
                      </span>
                      <span style={{ fontSize: "14px" }}>{t("Schedule") + " :"}</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <strong>
                        {course?.schedule?.charAt(0).toUpperCase() +
                          course?.schedule?.slice(1)}
                      </strong>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="type-text">
                      <span className="me-2">
                        {/* <FiCalendar style={{ color: "#09B970" }} /> */}
                        {/* <img src={StartingIcon} alt="new" className="new-tag1" /> */}
                        <VideoCallIcon style={{color:"#7bdab2", width:"25px"}} />
                      </span>
                      <span style={{ fontSize: "14px" }}>
                        {t("Type") + " :"}
                      </span>
                      &nbsp;
                      <strong>
                        {course?.course_type == "online" && "Online"}
                        {course?.course_type == "online,vClass" && "Online,Video Class"}
                        {course?.course_type == "inHouse,online,vClass" && "In-House, Online, Video Class"}
                      </strong>
                    </div>
                  </div>

                  <div className="space-line"></div>

                  <div className="d-flex align-items-center platform-course-payment">
                    <h4
                      className="mt-3 mb-3 price-tag"
                      style={{ color: "#09B970" }}
                    >
                     £{course?.total_price}
                    </h4>
                    <span className="ms-3 payment">
                      × {course?.payment_options} {t("Payments")}
                    </span>
                    {/* <span className="ms-2">
                      <button
                        className="btn"
                        onClick={() => handleTogglePayInfo(index)}
                      >
                        <FiInfo />
                      </button>
                      {togglePayInfo[index] && (
                        <div className="payments-info" ref={paymentsInfoRef}>
                          <PaymentsInfo props={course} />
                        </div>
                      )}
                    </span> */}
                  </div>

                  <Row>
                    <Col>
                      <Button
                        className={
                          storeData && items.some((obj) => obj.id === course.id)
                            ? "platform-remove-cart"
                            : "platform-add-cart"
                        }

                        onClick={() => addToCartCourse(course)}
                      >
                        {/* <FiShoppingCart className="add-icon" /> */}
                        <img src={shopping} alt="shopping" className="shop-logo" />
                        {storeData && items.some((obj) => obj.id === course.id)
                          ? t("Remove")
                          : t("CART")}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        className="platform-more-info"
                        variant="info"
                        onClick={() => {
                          navigate(`/course-details/`, {
                            state: {
                              coursesData: course,
                            },
                          });
                          const url = new URL(window.location.href);
                          url.searchParams.set('course', course.id);
                          window.history.pushState({}, '', url.toString());
                          window.scrollTo(0, 0);
                        }}
                      >
                        {/* <FiInfo className="me-1" /> */}
                        {t("More Info")}
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>

        <div className="d-flex justify-content-between align-items-center mt-3 mb-5">
          <button
            className="cat-btn "
            style={{
              border: "1px solid #04C977",
              borderColor: "#04C977",
              borderRadius: "12px",
              backgroundColor: "transparent",
              padding: "15px 20px",
              fontWeight: "700",
              width: "223px",
              height: "64px",

            }}
            onClick={handleButtonClick}
          >
            {t("SHOW ALL COURSES")}
          </button>
        </div>
        {/* Repeat the above code for the remaining cards */}
        {/* <div className='row'> */}


        <div className="dark_strip2 row">
          <div className="new-info col-md-5 text-center text-sm-start">
            <h4 className="content">
              {t("Do You Want To Get More Info")}
              <br></br>
              {t("About New Constructing Courses?")}
            </h4>
            <p style={{ color: "#c4b8dc" }} className="demo_text">
              {t("Subscribe to stay up to date with Skillmo")}
            </p>
          </div>

          <div class="text-info col-md-4">
            <svg
              className="emial-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clip-path="url(#clip0_415_2354)">
                <path
                  d="M13.8619 2H1.9308C0.865759 2 0 2.82247 0 3.83426V11.581C0 12.5928 0.865759 13.4153 1.9308 13.4153H13.8587C14.9237 13.4153 15.7895 12.5928 15.7895 11.581V3.83737C15.7927 2.82557 14.927 2 13.8619 2ZM14.9106 11.581C14.9106 12.1304 14.4402 12.5773 13.8619 12.5773H1.9308C1.35254 12.5773 0.882094 12.1304 0.882094 11.581V3.83737C0.882094 3.28802 1.35254 2.84109 1.9308 2.84109H13.8587C14.4369 2.84109 14.9074 3.28802 14.9074 3.83737V11.581H14.9106Z"
                  fill="#777779"
                />
                <path
                  d="M9.95869 7.61147L13.8203 4.32159C14 4.16641 14.0163 3.90259 13.853 3.72879C13.6896 3.55809 13.4119 3.54257 13.229 3.69775L7.90373 8.23841L6.86482 7.35697C6.86156 7.35387 6.85829 7.35076 6.85829 7.34766C6.83542 7.32593 6.81255 7.30731 6.78642 7.28869L2.5589 3.69465C2.37595 3.53947 2.09825 3.55498 1.9349 3.72879C1.77155 3.90259 1.78788 4.16641 1.97084 4.32159L5.87819 7.6394L1.98717 11.1C1.81075 11.2583 1.80095 11.5221 1.96757 11.6928C2.05578 11.7797 2.17339 11.8262 2.291 11.8262C2.39882 11.8262 2.50663 11.789 2.59157 11.7145L6.54139 8.20427L7.61297 9.11364C7.69791 9.18503 7.80246 9.21917 7.907 9.21917C8.01155 9.21917 8.11936 9.18192 8.20103 9.11054L9.30202 8.17323L13.229 11.7176C13.3139 11.7952 13.425 11.8325 13.5328 11.8325C13.6504 11.8325 13.7648 11.789 13.853 11.7021C14.0196 11.5345 14.0131 11.2676 13.8366 11.1093L9.95869 7.61147Z"
                  fill="#777779"
                />
              </g>
              <defs>
                <clipPath id="clip0_415_2354">
                  <rect
                    width="15.7895"
                    height="15"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            <input
              type="email"
              class={`platform-form-control py-2 ${isValidEmail ? "" : "is-invalid"
                }`}
              placeholder={t("Enter your email")}
              value={email}
              onChange={handleEmailChange}
            />
            {/* {!isValidEmail && (
          <div className="invalid-feedback">Invalid email format</div>
        )} */}
          </div>
          <div className="buttn">
            <button
              type="button"
              class="platform-btn"
              onClick={() => handleSubscribe(email)}
            >
              {t("SUBSCRIBE")}
            </button>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>

  );
}

export default Platform;
