import { baseUrl } from "../config";
export const fetchFilterData = async (keywordData) => {
  // console.log("api params", keywordData)
    const response = await fetch(
      `${baseUrl}/course/filter/data?keyword=${keywordData}`
      /* `https://test-api.skillmo.co.uk/api/v1/course/filter/data?category=""&created_by=""&trades_sub=""&schedule=""&min_price=""&max_price=""&location=""&keyword=mern` */
    );
    const data = await response.json();
    return data.data.data;
  };
  
  // distance pairameter missing 