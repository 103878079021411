import {create} from 'zustand';
import { fetchLocalStorage } from '../api/getLocalStorage';

const useLocalStore = create((set)=>({
    storeData: '',
    fetchLocalStorage: async () => {
        try{
            const storeData = await fetchLocalStorage();
            set({ storeData });
        } catch (error){
            console.error('Error fetching local storage: ', error);
        }
    }

}))

export default useLocalStore;