// src/store/tradesStore.js
import { create } from 'zustand';
import {  fetchTrades } from '../api/tradesApi';

const useTradesStore = create((set) => ({
  category:[],
  fetchTrades: async () => {
    try {
      const trades = await fetchTrades();
      set({ trades });
    } catch (error) {
      console.error('Error fetching trades:', error);
    }
  },
}));

export default useTradesStore;
