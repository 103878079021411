import { baseUrl } from "../config";
export const createUserSubscription = async (postData) => {
    const response = await fetch(
      `${baseUrl}/user/subscribers`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      }
    );
    const data = await response.json();
    return data;
  };

  