import { baseUrl } from "../config";
export const addToCartAPI = async (token, data) => {
    try {
      const response = await fetch(`${baseUrl}/user/cart`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Error adding to cart', error);
      throw error;
    }
  };
  
  export const getToCartAPI = async (token) => {
    try {
      const response = await fetch(`${baseUrl}/user/cart`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error getting cart', error);
      throw error;
    }
  };
  
  export const deleteToCartAPI = async (token, id) => {
    try {
      const response = await fetch(`${baseUrl}/user/cart/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error deleting from cart', error);
      throw error;
    }
  };
  