import React, { useEffect, useState, useRef, useContext } from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import image from "../../assets/images/students-walk-downstairsdd-with-books-library 1 1.png";
import {
  FiCheckCircle,
  FiStar,
  FiShoppingCart,
  FiCalendar,
  FiInfo,
  FiClock,
} from "react-icons/fi";
import { FaFire, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import {
  categoryTag,
  subCertifiedTag,
  subNewTag,
  titleTag,
  subTopRatedTag,
} from "../../assets/customcss/CustomCss";
import "../../assets/css/home/Courses.css";
import { useNavigate } from "react-router-dom";
import { useCourseStore, useCartStore, useLocalStore } from "../../store";
import PaymentsInfo from "../courselist/PaymentsInfo";
import useClickOutside from "../courselist/useClickOutside";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { LanguageContext } from "../LanguageContext";
import useLocalStorage from "../../hooks/LocalStotrage"; // Import the custom hook
import { useParams } from "react-router-dom";
import { useCartStoreApi } from "../../store";
import shopping from '../../assets/icon/shopping-preview.png'
import newtag from '../../assets/icon/new.png'
import certified from '../../assets/icon/certified.png'
import topreated from '../../assets/icon/start (1).png'
import StartingIcon from '../../assets/icon/detailsicon1.png'
import Sdule from '../../assets/icon/schedule.png'
import AboutIcon from '../../assets/icon/aboutbtn.png'
import Scroll from "../../assets/icon/scrollbtn.png";
import VideoCallIcon from '@mui/icons-material/VideoCall';

const Courses = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const languageData = useContext(LanguageContext);
  const { courses, fetchCourses } = useCourseStore();
  const { storeData } = useLocalStore();
  const { items, addToCart, removeFromCart } = useCartStore();
  // const { cart, addToCart, deleteToCart } = useCartStoreApi();
  const [newLanguage] = useLocalStorage("language", "en");
  useEffect(() => {
    async function fetchData() {
      await fetchCourses();
    }
    fetchData();
  }, []);


  //Pagination
  const itemsPerPage = 3;
  const totalItems = courses.length; // Replace with your actual total number of courses
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [currentPage, setCurrentPage] = React.useState(1);

  // Generate dummy course data
  const course = Array.from({ length: totalItems }, (_, index) => ({
    id: index + 1,
    title: `Course ${index + 1}`,
    image: `https://example.com/course${index + 1}.jpg`, // Replace with your course image URL
  }));

  // Pagination helper functions
  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const getNextPage = (currentPage, totalPages) => {
    return currentPage === totalPages ? 1 : currentPage + 1;
  };

  const getPrevPage = (currentPage, totalPages) => {
    return currentPage === 1 ? totalPages : currentPage - 1;
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => getNextPage(prevPage, totalPages));
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => getPrevPage(prevPage, totalPages));
  };

  const getCurrentPageItems = () => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    if (end > totalItems) {
      // Wrap around if reaching the end
      return [...courses.slice(start), ...courses.slice(0, end - totalItems)];
    }
    return courses.slice(start, end);
  };
  /* 
  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  }; */

  const handleButtonClick = () => {
    navigate(`/course-list/`);
    // window.location.reload();
    window.scrollTo(0, 0);
  };
  const filterTopRated = courses.filter((item) => item.is_top_rated == true);
  // Calculate current page items
  // const categorys = courses?.map((item) => item.courses);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = getCurrentPageItems();
  // console.log('PopularCourse',currentItems)

  const [togglePayInfo, setTogglePayInfo] = useState([]);
  const paymentsInfoRef = useRef(null); //creating ref for the PaymentsInfo
  const handleTogglePayInfo = (index) => {
    const updatedToggles = [...togglePayInfo];
    updatedToggles[index] = !updatedToggles[index];
    setTogglePayInfo(updatedToggles);
  };
  useClickOutside(paymentsInfoRef, () => {
    setTogglePayInfo([]);
  });

  const addToCartCourse = async (course) => {
    if (storeData) {
      if (items.some((obj) => obj.id === course.id)) {
        await removeFromCart(course.id);
      } else {
        addToCart(course);
      }
    } else {
      navigate("/login");
    }
  };

  // console.log("Alll courses", courses);

  return (
    <div
      className="container "
      style={{ paddingTop: "50px", paddingBottom: "85px" }}
    >
      <h6
        style={{
          color: "#39128C",
          fontFamily: "Metropolis",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: 700,
          fontStyle: "normal",
          letterSpacing: "5px",
          marginTop: "30px",
          textAlign: "left"
        }}
      >
        {t("Popular Courses")}
      </h6>
      <div>
        <h3
          style={{
            fontSize: "32px",
            fontFamily: "Metropolis",
            fontWeight: 700,
            fontStyle: "normal",
            lineHeight: "48px",
            color:'#1B1720'
          }}
          className="mt-4 mb-5"
        >
          {t("Explore our more popular courses")}
        </h3>
      </div>

      <div className="row">
        {currentItems?.map((course, index) => (
          <div className="col-md-4 mb-2" key={index}>
            <Card className="courses-card card-main">
              <div className="position-relative">
                <Card.Img
                  className="courses-card-img"
                  src={course?.course_image}
                  alt="Card image"
                  // style={{
                  //   width: "100%",
                  //   height: "270px",
                  // }}
                  cursor="pointer"
                  role="button"
                  onClick={() => {
                    navigate(
                      `/course-details/`,
                      {
                        state: {
                          coursesData: course,
                        },
                      }
                    );
                    const url = new URL(window.location.href);
                    url.searchParams.set('course', course.id);
                    window.history.pushState({}, '', url.toString());
                    window.scrollTo(0, 0);
                  }}
                />
                <div className="top-rated-box">
                  {course.is_new && (
                    <p
                      className="subNewTag"
                      variant="danger"
                    /* style={subNewTag} */
                    >
                      {/* <FaFire className="me-1" /> */}
                      <img src={newtag} alt="new" className="new-tag" />
                      {"  "}
                      {t("In-house")}
                    </p>
                  )}

                  {course.is_certified && (
                    <p
                      className="subCertifiedTag"
                      variant="success"
                    /* style={subCertifiedTag} */
                    >
                      {/* <FiCheckCircle className="me-1" /> */}
                      <img src={certified} alt="new" className="new-tag" />
                      {"  "} {t("Online")}
                    </p>
                  )}
                  {course.is_top_rated && (
                    <p className="subTopRatedTag" variant="warning">
                      {/* <FiStar className="me-1" /> */}
                      <img src={topreated} alt="new" className="new-tag" />
                      {"  "}
                      {t("Virtual Classroom")}
                    </p>
                  )}
                </div>
                <div>
                  <h5
                    className="position-absolute mb-3 title-tag"
                    variant="danger"
                    /* style={titleTag} */
                    cursor="pointer"
                    role="button"
                    onClick={() => {
                      navigate(
                        `/course-details/`,
                        {
                          state: {
                            coursesData: course,
                          },
                        }
                      );
                      const url = new URL(window.location.href);
                      url.searchParams.set('course', course.id);
                      window.history.pushState({}, '', url.toString());
                      window.scrollTo(0, 0);
                    }}
                  >
                    {/* {course.title_en} */}
                    {languageData?.currentLanguage === "en" && course?.title_en}
                    {languageData?.currentLanguage === "pl" && course?.title_pl}
                    {languageData?.currentLanguage === "ro" &&
                      course?.title_roa}
                  </h5>
                </div>
              </div>
              <Card.Body>
                <div className="main-card-keywords d-flex ">
                  {
                    course?.firm_course_categories && course?.firm_course_categories.map((item, index)=>{
                      return(
                        <span key={index} className="categoryTag">
                        {/* {course.course_category.lang_en} */}
                        {languageData?.currentLanguage === "en" &&
                         item.course_categories[0]?.lang_en}
                        {languageData?.currentLanguage === "pl" &&
                           item.course_categories[0]?.lang_pl}
                        {languageData?.currentLanguage === "ro" &&
                           item.course_categories[0]?.lang_roa}
                      </span>
                      )
                    })
                  }

                  {/* <span className="categoryTag">
                    {languageData?.currentLanguage === "en" &&
                      course?.trades_category?.lang_en}
                    {languageData?.currentLanguage === "pl" &&
                      course?.trades_category?.lang_pl}
                    {languageData?.currentLanguage === "ro" &&
                      course?.trades_category?.lang_roa}
                  </span> */}
                </div>
                <div className="card-short-desc">
                  <h4
                    className="mb-2 "
                  /* style={{ fontSize: '20px' }} */
                  >
                    {/* Explore our more popular courses... */}
                    {/* {course.short_desc_en.slice(0, 48) + '...'} */}
                    {languageData?.currentLanguage === "en" &&
                      course?.short_desc_en?.slice(0, 48) + "..."}
                    {languageData?.currentLanguage === "pl" &&
                      course?.short_desc_pl?.slice(0, 48) + "..."}
                    {languageData?.currentLanguage === "ro" &&
                      course?.short_desc_roa?.slice(0, 48) + "..."}
                  </h4>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="course-schedule">
                    <span className="me-2">
                      {/* <FiCalendar style={{ color: "#09B970" }} /> */}
                      <img src={StartingIcon} alt="new" className="new-tag1" />
                    </span>
                    <span className="starting-tag">
                      {t("Starting" + " :")}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>{course?.courseStartsAt}</strong>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="course-schedule">
                    <span className="me-2">
                      {/* <FiClock style={{ color: "#09B970" }} /> */}
                      <img src={Sdule} alt="new" className="new-tag1" />
                    </span>
                    <span className="starting-tag">
                      {t("Schedule" + " :")}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>
                      {course?.schedule?.charAt(0).toUpperCase() +
                        course?.schedule?.slice(1)}
                    </strong>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div>
                    <span className="me-2">
                      {/* <FiCalendar style={{ color: "#09B970" }} /> */}
                      {/* <img src={StartingIcon} alt="new" className="new-tag1" /> */}
                      <VideoCallIcon style={{color:"#7bdab2", width:"25px"}} />
                    </span>
                    <span style={{ fontSize: "14px" }}>
                      {t("Type") + " :"}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>
                      {course?.course_type == "online" && "Online"}
                      {course?.course_type == "online,vClass" && "Online,Video Class"}
                      {course?.course_type == "inHouse,online,vClass" && "In-House, Online, Video Class"}
                    </strong>
                  </div>
                </div>
                <div className="space-line1"></div>
                <div className="d-flex align-items-center course-payment">
                  <h4
                    className="mb-3 mt-3 price-tag"
                    style={{ color: "#09B970" }}
                  >
                   £{course?.total_price}
                  </h4>
                  <span className="ms-3 payment">
                    × {course?.payment_options} {t("Payments")}
                  </span>
                  <span className="ms-2">
                    <button
                      className="btn"
                      onClick={() => handleTogglePayInfo(index)}
                    >
                      {/* <FiInfo className="icon-info" /> */}
                      <img src={AboutIcon} alt="new" className="new-tag2" />
                    </button>
                    {togglePayInfo[index] && (
                      <div className="payments-info" ref={paymentsInfoRef}>
                        <PaymentsInfo props={course} />
                      </div>
                    )}
                  </span>
                </div>

                <Row>
                  <Col>
                    <button
                      className={
                        storeData && items.some((obj) => obj.id === course.id)
                          ? "remove-cart"
                          : "add-cart"
                      }
                      /* style ={{fontSize: '100px'}} */ onClick={() =>
                        addToCartCourse(course)
                      }
                    >
                      {/* <FiShoppingCart className="add-icon" /> */}
                      <img src={shopping} alt="shopping" className="shop-logo" />
                      {storeData && items.some((obj) => obj.id === course.id)
                        ? t("Remove")
                        : t("ADD TO CART")}
                    </button>
                  </Col>
                  <Col>
                    <button
                      className="more-info"
                      variant="info"
                      onClick={() => {
                        navigate(
                          `/course-details/`,
                          {
                            state: {
                              coursesData: course,
                            },
                          }
                        );
                        const url = new URL(window.location.href);
                        url.searchParams.set('course', course.id);
                        window.history.pushState({}, '', url.toString());
                        window.scrollTo(0, 0);
                      }}
                    >
                      {/* <FiInfo className="me-1" /> */}
                      {t("More Info")}
                    </button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        ))}
        <div className="d-flex justify-content-between align-items-center mt-3 mb-4">
          <button
            className="cat-btn "
            style={{
              border: "1px solid #04C977",
              borderColor: "#04C977",
              borderRadius: "12px",
              backgroundColor: "transparent",
              padding: "15px 20px",
              fontWeight: "700",
              width: "223px",
              height: "64px",
              lineHeight:"14px"
            }}
            onClick={handleButtonClick}
          >
            {t("SHOW ALL COURSES")}
          </button>

          <nav className="d-flex justify-content-center">
            <ul className="pagination">
              <li className="page-item">
                <button
                  className="next-btn"
                  onClick={goToPrevPage}
                  style={{ backgroundColor: "#09B970", marginRight: "10px" }}
                >
                  <FaChevronLeft />
                </button>
              </li>

              <li className="page-item">
                <button
                  className="next-btn"
                  onClick={goToNextPage}
                  style={{ backgroundColor: "#09B970", marginLeft: "10px" }}
                >
                  <FaChevronRight />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Courses;
