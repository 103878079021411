import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../assets/css/about.css";
import { useTranslation } from "react-i18next";

const EMICalculator = () => {

useEffect(() => {
    window.scrollTo(0, 0)
})

    const { t } = useTranslation();
    return (
        <>
            <Navbar></Navbar>
            <iframe src="https://payl8r.com/retailers/payment-detail?retailer=financeco1544cn678s5" width="100%" height="800"></iframe>

            <Footer></Footer>
        </>
    );
};

export default EMICalculator;
