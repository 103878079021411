import { create } from "zustand";
import { createUserSubscription } from "../api/subscriptionApi";

export const useSubscriptionStore = create((set) => ({
    users: [], // Initialize users as an empty array
    userSubscription: async (userData) => {
      try {
        const newSubscription = await createUserSubscription(userData);
        set((state) => ({ users: [...state.users, newSubscription] }));
      } catch (error) {
        console.error("Error subscribing user:", error);
      }
    },
  }));
  

// export default useSubscriptionStore;
