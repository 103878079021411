import React, { useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
// import validationSchema from '../components/regForm/validationSchema';
import logo from "../assets/images/Layer 1 (1).png";
import "./login.css";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
const Login = () => {
  const navigate = useNavigate();
  const initialValues = {
    email_id: "",
    password: "",
  };
  const { users, loginUser } = useUserStore();
  const [isLoading, setIsLoading] = useState(false);
  const secret_key = "Fzgd6ctch=PW=*=n(u]);@-(NfW@fZ";
  const encryptData = (data) => {
    try {
      return CryptoJS.AES.encrypt(data, secret_key).toString();
    } catch (e) {
      return "";
    }
  };
  // Handle Login
  const onSubmit = async (values) => {
    setIsLoading(true);
    const data = await loginUser(values);
    // console.log("dataaa", data);
    try {
      if (data.httpCode == 200) {
        Cookies.set("UUID", data.data.token, {
          domain: ".skillmo.co.uk",
          // domain: ".localhost",
        });
        Cookies.set("TOKEN", data.data.id, {
          domain: ".skillmo.co.uk",
          // domain: ".localhost",
        });
        if (data.data.acc_type === "user") {
          Cookies.set("NAME", data.data?.name, {
            domain: ".skillmo.co.uk",
            // domain: ".localhost",
          });
        } else {
          Cookies.set("NAME", data.data?.firmName, {
            domain: ".skillmo.co.uk",
            // domain: ".localhost",
          });
        }

        Cookies.set("ACCOUNT", data.data.acc_type, {
          domain: ".skillmo.co.uk",
          // domain: ".localhost",
        });

        if (data.data.acc_type === "user") {
          toast.success("Logged In Successfully!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            navigate(-1);
            setIsLoading(false);
          }, 2000);
        } else if (data.data.acc_type === "firm") {
          toast.success("Logged In Successfully!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            window.location.href =
              "https://test-admin.skillmo.co.uk/home/course-users";
            setIsLoading(false);
          }, 2000);
        } else {
          toast.success("Logged In Successfully!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            window.location.href = "https://test-admin.skillmo.co.uk/";
            setIsLoading(false);
          }, 2000);
        }
      } else {
        toast.error(
          "Email and password do not match or You are not approved.",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleForgotPasswordClick = () => {
    navigate("/forgot-password");
    // window.location.reload();
  };

  const handleSignUpClick = () => {
    navigate("/register");
    // window.location.reload();
  };
  const homepage = () => {
    navigate("/");
    // window.location.reload();
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        theme="colored"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Container className="reg-form-container" style={{ marginTop: "100px" }}>
        <Row>
          <Col md={8} className="p-4 design-element  reg-form-right">
            <div className="login-page-disign">
              <div className="reg-form-header" style={{ marginLeft: "0px" }}>
                Login
              </div>
              <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({ handleSubmit }) => (
                  <Form onSubmit={handleSubmit} className="loginCard">
                    <Row>
                      <Col md={12}>
                        <Form.Group className="mb-3">
                          <Form.Label htmlFor="email_id">Email</Form.Label>
                          <Field
                            type="email"
                            name="email_id"
                            className="form-control"
                            required
                          />
                          <ErrorMessage
                            name="email_id"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group className="mb-3">
                          <Form.Label htmlFor="password">Password</Form.Label>
                          <Field
                            type="password"
                            name="password"
                            className="form-control"
                            required
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>

                      <Col
                        md={12}
                        className="d-flex"
                        style={{ marginTop: "5px" }}
                      >
                        {/* <div
                          className="forgot-pass "
                          onClick={handleForgotPasswordClick}
                        >
                          Forgot Password?
                        </div> */}
                        <div
                          style={{ marginLeft: "20px" }}
                          className="forgot-pass"
                          onClick={handleSignUpClick}
                        >
                          Signup
                        </div>
                      </Col>

                      <Col md={12} xs={12}>
                        <Button
                          className="reset-link-btn mt-2"
                          type="submit" /* disabled={isSubmitting} */
                          style={{
                            backgroundColor: "#10D382",
                            color: "#fafafa",
                            // fontWeight: 700,
                            fontSize: "16px",
                            padding: "15px 65px",
                            border: "none",
                            borderRadius: "8px",
                            marginLeft: "0px",
                          }}
                        >
                          {isLoading ? "Loading..." : "Login"}
                        </Button>

                        <div
                          style={{
                            marginTop: "25px",
                            cursor: "pointer",
                          }}
                          onClick={() => homepage()}
                        >
                          <svg
                            fill="#000000"
                            width="25px"
                            height="15px"
                            viewBox="0 0 200 250"
                            data-name="Layer 1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <title />
                            <path d="M160,89.75H56l53-53a9.67,9.67,0,0,0,0-14,9.67,9.67,0,0,0-14,0l-56,56a30.18,30.18,0,0,0-8.5,18.5c0,1-.5,1.5-.5,2.5a6.34,6.34,0,0,0,.5,3,31.47,31.47,0,0,0,8.5,18.5l56,56a9.9,9.9,0,0,0,14-14l-52.5-53.5H160a10,10,0,0,0,0-20Z" />
                          </svg>
                          Back to home page
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>

          <Col md={4} className="p-2 design-element  reg-form-overlay">
            <Row>
              <Col md={12}>
                <div className="reg-right-logo">
                  <img src={logo} alt="logo" />
                </div>
              </Col>
              <Col md={12}>
                <div className="reg-right-text">
                  <h3>Start developing your skills with us.</h3>
                  <p>
                    Learn from the top trainer and give yourself More Text Here
                    More Text Here More Text Here More Text Here More Text Here
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
