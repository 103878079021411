

import {create} from 'zustand';

const useCartStore = create((set) => ({
  items: [],

  addToCart: (product) => {
    set((state) => ({ items: [...state.items, product] }));
  },

  removeFromCart: (productId) => {
    set((state) => ({
      items: state.items.filter((item) => item.id !== productId),
    }));
  },

  clearCart: () => {
    set({ items: [] });
  },
}));

export default useCartStore;