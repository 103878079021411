import React from "react";
import "../../assets/css/home/FlexiblePayment.css";
import { useTranslation } from "react-i18next";
import payltr1 from "../../assets/images/payltr1.png"
import payltr2 from "../../assets/images/payltr2.png"
import review from "../../assets/images/reviews.png"

const FlexiblePayments = () => {
  const { t } = useTranslation();
  return (
    <>
      <div style={{ backgroundColor: "#ffffff" }}>
        <div className="container " style={{ paddingTop: "50px", paddingBottom: '50px', }}>
          <h6
            style={{
              color: "#39128C",
              fontFamily: "Metropolis",
              fontSize: "16px",
              fontStyle: "normal",
              lineHeight: "24px",
              letterSpacing: "5px",
            }}
          >
            Flexible Payments
          </h6>


          <div class="row">
            <div class="col-md-7 ">
              <div>
                <h3
                  style={{
                    color: "black",
                    fontFamily: "Metropolis",
                    fontStyle: "normal",
                    fontSize: "32px",
                    // fontWeight: "700",
                    lineHeight: "48px",
                    letterSpacing: "0px",
                    textAlign: "left",
                    color: "#1B1720",

                  }}
                  className="mt-4 mb-2"
                >
                  How Payl8r works
                </h3>

                <div className="pyment">
                  <p className="sub-py-heading">Choose Payl8r at checkout</p>
                  <p className="text-pyment">Simply enter your details and, in most cases, you’ll have an acceptance decision instantly. Available on purchases from £50 – £2,000.</p>
                  <p className="sub-py-heading">Quick application</p>
                  <p className="text-pyment">Our online application takes just 60 seconds to complete and, in most cases, we’ll be able to give you an instant decision.</p>
                  <p className="sub-py-heading">Affordability first</p>
                  <p className="text-pyment">We believe in fairer ways to assess affordability. Using Open Banking and your Credit File, we base decisions on what you can afford, not just your credit history.</p>
                  <p className="text-pyment mt-4">Subject to affordability assessments. SKILLMO LTD is an Introducer Appointed Representative of Social Money LTD t/a Payl8r.  Missed payments may affect your credit file, future borrowing and incur fees. Representative APR 127.34%</p>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <img src={payltr1} alt="Description of the image" style={{ width: "40%", height: "auto" }} />
              <img src={payltr2} alt="Description of the image" style={{ width: "100%", height: "auto" }} />
            </div>
          </div>

          <div>
          <img src={review} alt="Description of the image" style={{ width: "100%", height: "auto" }} />
          </div>
        </div>
      </div>

    </>
  );
};

export default FlexiblePayments;
