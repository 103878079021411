// src/store/userStore.js
import { create } from 'zustand';
import { fetchFilterData } from '../api/filterDataApi';

const useFilterDataStore = create((set) => ({
  filterData: [],
  fetchFilterData: async () => {
    try {
      const filterData = await fetchFilterData();
      set({ filterData });
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  },
}));

export default useFilterDataStore;
